<template>
  <v-hover v-slot="{ hover }">
    <v-row
      justify="center"
      :class="[{ 'on-hover': hover }, 'py-6 px-4']"
      @click="$emit('click')"
    >
      <v-row justify="center" align="center">
        <v-icon color="black" class="mr-1">{{ icon }}</v-icon>
        <h2>{{ title }}</h2>
      </v-row>
      <p
        class="grey--text text-darken-1 text-center mt-2 pa-0 mb-0"
        style="width: 330px"
      >
        {{ description }}
      </p>
    </v-row>
  </v-hover>
</template>

<script>
export default {
  props: {
    icon: {
      type: String,
      required: true,
    },
    title: {
      type: String,
      required: true,
    },
    description: {
      type: String,
      required: true,
    },
  },
};
</script>

<style>
.on-hover {
  background-color: rgb(228, 228, 228);
  cursor: pointer;
}
</style>

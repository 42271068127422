<template>
  <form-cadastro
    :title="`Atividade de Projeto ${model.codigo ? model.codigo : ''}`"
    @stateChanged="onStateChanged"
    @aposGravar="aposGravar"
    @aposFechar="aposFechar"
    @aposExcluir="aposExcluir"
    :controller="controller"
    :model="model"
    ref="form"
    extended
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Visita </v-tab>
        <v-tab :disabled="Number(model.cliente.codigo) === 0">
          Atividades
        </v-tab>
        <v-tab> Observações gerais </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
      <v-tab-item disabled>
        <aba-visita :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
      <v-tab-item>
        <aba-atividade :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
      <v-tab-item>
        <aba-observacoes-gerais
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { AtividadesProjeto } from '@/proxy-api/src/services/classes/Projeto/AtividadesProjeto';
import { AtividadesProjetoController } from '@/proxy-api/src/services/classes/Projeto/AtividadesProjetoController';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaVisita from './components/AbaVisita';
import AbaAtividade from './components/AbaAtividade';
import AbaObservacoesGerais from './components/AbaObservacoesGerais';
import ExibicaoRelatorio from '@/components/ExibicaoRelatorio';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
    AbaIdentificacao,
    AbaVisita,
    AbaAtividade,
    AbaObservacoesGerais,
  },

  mounted() {
    this.verificarSePermiteEdicao();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new AtividadesProjeto(true),
      controller: new AtividadesProjetoController(),
      abaSelecionada: 0,
      itensDeAcao: [
        {
          icon: 'mdi-printer',
          text: 'Imprimir relátorio de atividade do projeto',
          available: true,
          callback: this.imprimir,
        },
      ],
    };
  },

  methods: {
    onStateChanged(emEdicao) {
      this.modoVisualizacao = !emEdicao;
    },

    incluir() {
      this.$refs.form.incluir();
    },

    aposGravar(registro) {
      this.abaSelecionada = 0;
      this.$emit('aposGravar', registro);
      this.model.atividadeExcluidas = [];
      this.verificarSePermiteEdicao();
    },

    aposExcluir(registro) {
      this.$emit('aposExcluir', registro);
    },

    verificarSePermiteEdicao() {
      if (this.model.horarioDeSaida === '') {
        return;
      }

      this.$refs.form.actionItems.forEach(action => {
        if (action.text === 'Editar') {
          action.available = false;
        }
      });

      this.$refs.form.actionItems = [
        ...this.itensDeAcao,
        ...this.$refs.form.actionItems,
      ];
    },

    async imprimir() {
      this.$refs.form.processamento = 'Carrendo relatório...';

      try {
        const fileURL = await this.controller.relatorio(this.model.codigo);

        const instancia = TFormUtil.instanciar(ExibicaoRelatorio);

        instancia.url = fileURL;

        TFormUtil.abrir(instancia);
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.$refs.form.processamento = '';
      }
    },

    aposFechar() {
      this.$emit('aposFechar');
    },
  },
};
</script>

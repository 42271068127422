import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Contato } from '../Contato/Contato';
import { Atendente } from '../Atendente/Atendente';
import { Solicitacao } from './Solicitacao';
import { Sessao } from '../Autenticacao/Sessao';

import LocalAtendimento from '@/proxy-api/src/enumerations/LocalAtendimento';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class AtendimentoPresencial extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Contato();
    this.atendente = new Atendente();

    this.dataDaVisita = new Date().toLocaleDateString();

    this.horarioDeEntrada = '';
    this.horarioDeSaida = '';
    this.horarioDeAlmocoEntrada = '';
    this.horarioDeAlmocoSaida = '';

    this.localDoAtendimento = LocalAtendimento.CLIENTE;

    this.solicitacoes = [];
    this.solicitacoesExcluidas = [];

    this.objetivoDaVisita = '';
    this.descricaoDoAtendimento = '';

    if (Sessao.usuario) {
      this.atendente.codigo = Sessao.usuario.codigo;
      this.atendente.descricao = Sessao.usuario.descricao;
    }
  }

  set json(dados) {
    this.codigo = dados.autoincSai;

    this.cliente.codigo = dados.clienteSai;
    this.cliente.descricao = dados.razaoCli;

    this.contato.codigo = dados.contatoSai;
    this.contato.descricao = dados.nomeClc;

    this.dataDaVisita = DataUtils.formatarDataParaDiaMesAno(dados.datadigSai);
    this.horarioDeEntrada = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horaentSai,
    );
    this.horarioDeSaida = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horasaiSai,
    );
    this.horarioDeAlmocoEntrada = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horainicioalmocoSai,
    );
    this.horarioDeAlmocoSaida = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horaterminoalmocoSai,
    );

    this.tempoDeslocamento = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.tempodeslocamento_sai,
    );

    this.solicitacoes = [];
    if (dados.solAtdexterno.length > 0) {
      this.solicitacoes = dados.solAtdexterno.map(registro => {
        const solicitacao = new Solicitacao();
        solicitacao.json = registro;
        return solicitacao;
      });
    }

    this.localDoAtendimento =
      Number(dados.localexecucaoSai) === 0
        ? LocalAtendimento.CLIENTE
        : LocalAtendimento.TEKSYSTEM;

    this.objetivoDaVisita = dados.solicitacaoSai;
    this.descricaoDoAtendimento = dados.descricaobaixaSai;
  }

  get json() {
    const solicitacoes = this.solicitacoes.map(solicitacao => solicitacao.json);
    const solicitacoesExcluidas = this.solicitacoesExcluidas.map(
      solicitacao => {
        return {
          ...solicitacao.json,
          objectState: 'DELETED',
        };
      },
    );

    const dados = {
      autoincSai: this.codigo,
      clienteSai: this.cliente.codigo,
      razaoCli: this.cliente.descricao,
      contatoSai: this.contato.codigo,
      nomeClc: this.contato.descricao,
      datadigSai: DataUtils.formatarDataParaAnoMesDia(this.dataDaVisita),
      horaentSai: this.horarioDeEntrada,
      horasaiSai: this.horarioDeSaida,
      horainicioalmocoSai: this.horarioDeAlmocoEntrada,
      horaterminoalmocoSai: this.horarioDeAlmocoSaida,
      localexecucaoSai: this.localDoAtendimento,
      solAtdexterno: [...solicitacoes, ...solicitacoesExcluidas],
      solicitacaoSai: this.objetivoDaVisita,
      descricaobaixaSai: this.descricaoDoAtendimento,
      tempodeslocamento_sai: this.tempoDeslocamento,
    };
    if (this.horarioDeSaida) {
      dados.atendentebaixaSai = this.atendente.codigo;
      dados.nomeAtnBaixa = this.atendente.descricao;
    }

    return dados;
  }
}

export { AtendimentoPresencial };

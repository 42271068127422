<template>
  <v-row dense>
    <v-col cols="12" class="d-flex">
      <v-row dense>
        <v-col cols="5" sm="2">
          <t-integer-edit
            :decimalPlaces="0"
            v-model="atividade.codigo"
            label="Codigo"
            disabled
          />
        </v-col>

        <v-col cols="5" sm="2">
          <t-integer-edit
            :decimalPlaces="0"
            v-model="atividade.projeto.codigo"
            label="Projeto"
            disabled
          />
        </v-col>
      </v-row>
      <v-btn
        @click="excluir"
        :disabled="modoVisualizacao"
        icon
        color="error"
        class="aboslute"
      >
        <v-icon>mdi-close</v-icon>
      </v-btn>
    </v-col>

    <v-col cols="12">
      <t-edit v-model="atividade.descricao" label="Atividade" disabled />
    </v-col>

    <v-col cols="4">
      <t-number-edit
        v-model="atividade.percentualRealizado"
        disabled
        class="mt-n4"
        label="% Realizado"
      />
    </v-col>

    <v-col cols="4">
      <t-number-edit
        v-model="atividade.percentualAtual"
        :disabled="modoVisualizacao"
        :min="0"
        :max="100"
        class="mt-n4"
        label="% Realização atual"
      />
    </v-col>

    <v-col cols="4">
      <t-time-edit
        v-model="atividade.duracao"
        :disabled="modoVisualizacao"
        class="mt-n4"
        label="Duração (HH:MM)"
      />
    </v-col>

    <t-memo-edit
      v-model="atividade.observacao"
      label="Descrição"
      class="mt-n4"
      :readonly="modoVisualizacao"
    />
  </v-row>
</template>

<script>
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TNumberEdit from 'tek-components-vuejs/src/edit/TNumberEdit';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TNumberEdit';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';
import TTimeEdit from 'tek-components-vuejs/src/edit/TTimeEdit';

export default {
  components: { TEdit, TNumberEdit, TMemoEdit, TTimeEdit, TIntegerEdit },

  props: {
    atividade: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    excluir() {
      this.$emit('excluir');
    },
  },
};
</script>

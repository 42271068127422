<template>
  <t-container>
    <t-card>
      <search-edit
        :controller="consultaSistemasCliente"
        :disabled="modoVisualizacao"
        :model="model.sistema"
        label="SISTEMA"
      />

      <v-combobox
        v-model="model.moduloSistema"
        :items="model.sistema.modulos"
        :disabled="modoVisualizacao || !model.sistema.codigo"
        item-text="descricao"
        item-value="codigo"
        label="MÓDULO"
        outlined
        :rules="[value => !!value || 'Escolha um módulo']"
      >
        <template slot="item" slot-scope="{ item }">
          {{ item.codigo + ' - ' + item.descricao }}
        </template>
        <template slot="selection" slot-scope="{ item }">
          {{ item.codigo + ' - ' + item.descricao }}
        </template>
      </v-combobox>

      <search-edit
        :controller="consultaArea"
        :disabled="modoVisualizacao"
        :model="model.area"
        label="ÁREA"
      />
    </t-card>
  </t-container>
</template>

<script>
import { ConsultaSistemasCliente } from '@/proxy-api/src/services/classes/Sistema/ConsultaSistemasCliente';
import { ConsultaSistemasClienteModulo } from '@/proxy-api/src/services/classes/Sistema/ConsultaSistemasClienteModulo';
import { ModuloSistema } from '@/proxy-api/src/services/classes/Sistema/ModuloSistema';
import { ConsultaArea } from '@/proxy-api/src/services/classes/Area/ConsultaArea';
import { Area } from '@/proxy-api/src/services/classes/Area/Area';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';

export default {
  components: { TCard, TContainer, SearchEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      consultaSistemasCliente: new ConsultaSistemasCliente(
        this.model.cliente.codigo,
      ),
      consultaSistemasClienteModulo: new ConsultaSistemasClienteModulo(
        this.model.cliente.codigo,
      ),
      consultaArea: new ConsultaArea(this.model.sistema.codigo),
    };
  },

  methods: {
    async carregarModulos() {
      if (this.model.sistema.codigo) {
        try {
          await this.consultaSistemasClienteModulo.buscarRegistro(
            this.model.sistema.codigo,
          );

          const modulos = this.consultaSistemasClienteModulo.registros.map(
            modulo => {
              return {
                moduloSmod: modulo.moduloSmod,
                descricaoMod: modulo.descricaoMod,
              };
            },
          );

          this.model.sistema.setModulos(modulos);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },

  created: async function () {
    await this.carregarModulos();
  },

  watch: {
    'model.cliente.codigo'() {
      this.consultaSistemasCliente = new ConsultaSistemasCliente(
        this.model.cliente.codigo,
      );
      this.consultaSistemasClienteModulo = new ConsultaSistemasClienteModulo(
        this.model.cliente.codigo,
      );
    },

    'model.sistema.codigo'() {
      this.carregarModulos();
      this.model.moduloSistema = new ModuloSistema();
      this.model.area = new Area();
      this.consultaArea = new ConsultaArea(this.model.sistema.codigo);
    },
  },
};
</script>

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Contato } from '../Contato/Contato';
import { Motivo } from '../Motivo/Motivo';
import { Area } from '../Area/Area';
import { OrigemAtendimento } from '../OrigemAtendimento/OrigemAtendimento';
import { ModuloSistema } from '../Sistema/ModuloSistema';
import { Sistema } from '../Sistema/Sistema';
import { TipoAtendimento } from '../TipoAtendimento/TipoAtendimento';
import { Situacao } from '../Atendimento/Situacao';
import { Classificacao } from '../Classificacao/Classificacao';
import { Arquivo } from '../Arquivo/Arquivo';
import { descricaoAtendimentoParaSigla } from '../../../utils/siglaAtendimento';
import { DataUtils } from '../../../utils/DataUtils';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';

Sessao.json = Storage.getSessao();

class Atendimento extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Contato();

    this.sistema = new Sistema();
    this.moduloSistema = new ModuloSistema();
    this.area = new Area();

    this.situacao = new Situacao();

    this.origemAtendimento = new OrigemAtendimento();
    this.motivo = new Motivo();
    this.tipoAtendimento = new TipoAtendimento();
    this.classificacao = new Classificacao();
    this.relatadoPeloCliente = '';
    this.solucaoDoAtendimento = '';
    this.dataInicioAtendimento = '';
    this.dataFinalAtendimento = '';

    if (Sessao.usuario && Sessao.usuario.cliente.codigo > 0) {
      const { usuario, padrao } = Sessao;

      this.cliente.codigo = usuario.cliente.codigo;
      this.cliente.descricao = usuario.descricao;

      this.origemAtendimento.codigo = padrao.origemAtendimento;
      this.tipoAtendimento.codigo = padrao.tipoAtendimento;
    }
  }

  get json() {
    return {
      codigoAtd: Number(this.codigo),
      clienteAtd: Number(this.cliente.codigo),
      razaoCli: this.cliente.descricao,
      contatoAtd: Number(this.contato.codigo),
      nomeClc: this.contato.descricao,

      sistemaAtd: Number(this.sistema.codigo),
      nomeSis: this.sistema.descricao,

      moduloAtd: Number(this.moduloSistema.codigo),
      descricaoMod: this.moduloSistema.descricao,

      descricaoSare: this.area.descricao,
      areaAtd: this.area.codigo,

      origematdAtd: Number(this.origemAtendimento.codigo),
      descricaoOtdOa: this.origemAtendimento.descricao,

      problemaAtd: Number(this.motivo.codigo),
      nomePro: this.motivo.descricao,

      tipoatendAtd: Number(this.tipoAtendimento.codigo),
      descricaoTat: this.tipoAtendimento.descricao,

      executadoAtd: this.relatadoPeloCliente,
      solucaoErroAtd: this.solucaoDoAtendimento,

      classificacaoAtd: this.classificacao.codigo,
      descricaoClassificacao: this.classificacao.descricao,
    };
  }

  set json(dados) {
    this.codigo = dados.codigoAtd;

    this.cliente.codigo = dados.clienteAtd;
    this.cliente.descricao = dados.razaoCli;

    this.contato.codigo = Number(dados.contatoAtd);
    this.contato.descricao = dados.nomeClc;

    this.sistema.codigo = dados.sistemaAtd;
    this.sistema.descricao = dados.nomeSis;

    this.moduloSistema.codigo = dados.moduloAtd;
    this.moduloSistema.descricao = dados.descricaoMod;

    this.area.descricao = dados.descricaoSare;
    this.area.codigo = dados.areaAtd;

    this.origemAtendimento.codigo = dados.origematdAtd;
    this.origemAtendimento.descricao = dados.descricaoOtdOa;

    this.motivo.codigo = dados.problemaAtd;
    this.motivo.descricao = dados.nomePro;

    this.tipoAtendimento.codigo = dados.tipoatendAtd;
    this.tipoAtendimento.descricao = dados.descricaoTat;

    this.situacao.codigo = Number(dados.situacaoAtd);
    this.situacao.descricao = dados.descricaoSit;

    this.classificacao.codigo = dados.classificacaoAtd;
    this.classificacao.descricao = dados.descricaoClassificacao;

    this.relatadoPeloCliente = dados.executadoAtd;

    this.solucaoDoAtendimento = dados.solucaoErroAtd;
    this.dataInicioAtendimento = dados.datainicioAtd
      .split('-')
      .reverse()
      .join('/');
    this.dataFinalAtendimento = dados.datafinalAtd
      ? dados.datafinalAtd.split('-').reverse().join('/')
      : '';
  }

  abrirTicket(dados) {
    try {
      this.sistema.codigo = dados.sistema;
      this.cliente.codigo = dados.cliente;
      this.expiracao = DataUtils.formatarDataParaDiaMesAno(dados.expiracao);
      this.moduloSistema.codigo = dados.modulo ? dados.modulo : 0;
      this.moduloSistema.descricao = dados.descModulo
        ? dados.descModulo
        : 'INDEFINIDO';
      this.contato.email = dados.email;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  credenciaisParaAvaliar() {
    const credenciais = {
      codigo: this.codigo,
      expiracao: DataUtils.formatarDataParaTimeZone(
        DataUtils.somaDias(this.dataFinalAtendimento, 30),
      ),
      tipo: descricaoAtendimentoParaSigla('atendimento'),
      descricao: `ATENDIMENTO ${this.codigo}`,
    };

    // CONVERTE PARA BASE64
    return Buffer.from(JSON.stringify(credenciais)).toString('base64');
  }
}

export { Atendimento };

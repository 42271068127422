import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Projeto } from './Projeto';

import { HoraUtils } from '@/proxy-api/src/utils/HoraUtils';

class AtividadeProjeto extends Cadastro {
  constructor(persistente) {
    super();

    this.percentualAtual = 0;

    this.projeto = new Projeto();
  }

  set json(dados) {
    this.codigo = dados.codigoPrt;
    this.descricao = dados.descricaoPrt;
    this.nivel = dados.nivelPrt;
    this.cabecalho = dados.cabecalhoPrt;
    this.duracao = HoraUtils.formatarNumeroParaHora(dados.duracaoPrt);
    this.realizacao = dados.realizacaoPtr;
    this.percentualRealizado = Number(dados.percrealizacaoPrt);
    this.observacao = dados.observacoesPrt;
    this.status = dados.statusPrt;

    this.descricaoStatus = this.preencheDescricaoStatus(dados.statusPrt);
    this.projeto.codigo = dados.codigoProj;
    this.projeto.descricao = dados.descricaoProj;
  }

  get json() {}

  statusComDescricao() {
    return this.status + ' - ' + this.descricaoStatus;
  }
  preencheDescricaoStatus(codigo) {
    switch (codigo) {
      case 0:
        return '0 - Indefinido';
      case 1:
        return '1 - Planejamento';
      case 2:
        return '2 - Em Execução';
      case 3:
        return '3 - Finalizado';
      case 4:
        return '4 - Cancelado';
      default:
        return '5 - Indefinido';
    }
  }
}

export { AtividadeProjeto };

<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <v-textarea
        v-model="model.descricao"
        :readonly="modoVisualizacao"
        label="Descrição"
        persistent-placeholder
        outlined
        :rows="15"
        no-resize
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';

export default {
  components: { TCard, TContainer },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

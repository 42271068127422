<template>
  <div>
    <t-container class="pa-2">
      <div v-if="!model.codigo" class="text-center text--gray pt-6">
        <v-icon size="50" class="mb-2"> mdi-alert-outline </v-icon>
        <p>Grave o ticket antes de anexar um arquivo</p>
      </div>
      <t-card v-else>
        <input
          ref="uploader"
          type="file"
          class="d-none"
          :disabled="loadingTabela"
          @change="enviarArquivo"
        />

        <v-row justify="end" class="mb-4 px-4">
          <v-btn
            color="success"
            @click="abrirEscolhaArquivo"
            :disabled="loadingTabela"
          >
            <v-icon small class="mr-2">mdi-file</v-icon>
            Incluir Arquivo
          </v-btn>
        </v-row>

        <tabela-arquivos
          :items="this.model.arquivos"
          @removerArquivo="removerArquivo"
          @downloadArquivo="downloadArquivo"
          :loading="loadingTabela"
          :disabled="loadingTabela"
        />
      </t-card>
    </t-container>
  </div>
</template>

<script>
import { ArquivoController } from '../../../../../proxy-api/src/services/classes/Arquivo/ArquivoController';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';
import TFile from 'tek-components-vuejs/src/file/TFile.vue';
import TabelaArquivos from './components/TabelaArquivos.vue';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: {
    TCard,
    TContainer,
    TEmptyState,
    TFile,
    TabelaArquivos,
  },

  props: {
    model: {
      type: Object,
    },
    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      controller: new ArquivoController(this.model.codigo, 'ATENDIMENTO'),
      loadingTabela: true,
    };
  },

  methods: {
    abrirEscolhaArquivo() {
      this.$refs.uploader.click();
    },

    async enviarArquivo(evento) {
      const arquivo = evento.target.files[0];

      this.loadingTabela = true;
      try {
        const response = await this.controller.enviarArquivo(arquivo);
        this.model.arquivos.push(response);
      } catch (error) {
        this.mensagemErro(error.message);
      } finally {
        this.loadingTabela = false;
      }
    },

    async removerArquivo(arquivo) {
      this.loadingTabela = true;
      try {
        await this.controller.removerArquivo(arquivo.codigo);
        this.model.removerArquivo(arquivo);
      } catch (error) {
        this.mensagemErro(error.message);
      } finally {
        this.loadingTabela = false;
      }
    },

    downloadArquivo(arquivo) {
      this.controller.downloadArquivo(arquivo);
    },

    mensagemErro(mensagem) {
      TMessageDialog.error().message(mensagem).show();
    },

    mensagemSucesso(mensagem) {
      TMessageDialog.success().message(mensagem).show();
    },
  },

  computed: {
    arquivosVazios() {
      return this.model.arquivos.length === 0;
    },
  },

  async mounted() {
    this.loadingTabela = true;
    try {
      await this.controller.buscarRegistros([]);
      this.model.importarArquivos(this.controller.registros);
    } catch (error) {
      this.mensagemErro(error.message);
    } finally {
      this.loadingTabela = false;
    }
  },
};
</script>

<template>
  <t-form
    :processamento="processamento"
    :navigationIcon="navigationIcon !== null ? navigationIcon : iconeFechar"
    :actionItems="[...itensDeAcao, ...actionItems]"
    v-on="$listeners"
    v-bind="$attrs"
  >
    <slot></slot>
    <template v-slot:extension>
      <slot name="extension"></slot>
    </template>
  </t-form>
</template>

<script>
import TForm from 'tek-components-vuejs/src/form/TForm';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

const EDITAR = 1;
const GRAVAR = 2;
const EXCLUIR = 3;

export default {
  components: { TForm },

  props: {
    model: {
      type: Object,
      default: null,
    },

    controller: {
      type: Object,
      default: null,
    },

    itensDeAcao: {
      type: Array,
      default: () => [],
    },

    navigationIcon: {
      type: String,
    },
  },

  watch: {
    emEdicao: {
      immediate: true,
      handler: function (val) {
        this.actionItems[EDITAR].available = !val;
        this.actionItems[GRAVAR].available = val;
        this.actionItems[EXCLUIR].available = !val;
        this.$emit('stateChanged', val);
      },
    },
  },

  computed: {
    iconeFechar() {
      return this.emEdicao ? 'mdi-close' : 'mdi-arrow-left';
    },
  },

  data: function () {
    return {
      processamento: '',
      emEdicao: false,
      actionItems: [
        {
          icon: 'mdi-account-plus',
          text: 'Incluir',
          available: false,
          callback: () => this.incluir(),
        },
        {
          icon: 'mdi-pencil',
          text: 'Editar',
          available: true,
          callback: () => this.editar(),
        },
        {
          icon: 'mdi-check',
          text: 'Gravar',
          available: false,
          callback: () => this.gravar(),
        },
        {
          icon: 'mdi-delete',
          text: 'Excluir',
          available: true,
          callback: () => this.antesExcluir(),
        },
      ],
      permissoes: {
        incluir: false,
        editar: false,
        excluir: false,
        visualizar: true,
      },
    };
  },

  methods: {
    fechar() {
      this.$emit('aposFechar');
    },

    incluir() {
      this.emEdicao = true;
    },

    async gravar() {
      this.processamento = 'Gravando registro...';
      try {
        this.validarPermissao('gravar');

        const { data: registro } = await this.controller.gravar(this.model);

        this.aposGravar(registro);
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },

    aposGravar(registro) {
      this.model.json = registro;
      this.emEdicao = false;

      TMessageDialog.success()
        .message('Registro gravado com sucesso!')
        .okCallback(() => {
          this.$emit('aposGravar', registro);
        })
        .show();
    },

    editar() {
      try {
        this.validarPermissao('editar');
        this.emEdicao = true;
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      }
    },

    antesExcluir() {
      TMessageDialog.question()
        .message('Você quer mesmo excluir este registro?')
        .okCallback(() => this.excluir())
        .okLabel('Sim')
        .cancelLabel('Não')
        .show();
    },

    async excluir() {
      this.processamento = 'Excluindo registro ...';

      try {
        this.validarPermissao('excluir');
        await this.controller.excluir(this.model.codigo);

        this.aposExcluir(this.model.json);
      } catch (error) {
        TMessageDialog.error().message(error.message).show();
      } finally {
        this.processamento = '';
      }
    },

    aposExcluir(registro) {
      TMessageDialog.success()
        .message('Registro excluído com sucesso!')
        .okCallback(() => {
          this.$emit('aposExcluir', registro);
          this.fechar();
        })
        .show();
    },

    setPermissoes(permissoes) {
      this.permissoes.incluir = permissoes.incluir;
      this.permissoes.editar = permissoes.alterar;
      this.permissoes.excluir = permissoes.excluir;
      this.permissoes.visualizar = permissoes.visualizar;
    },

    validarPermissao(permissao) {
      if (permissao === 'excluir' && !this.permissoes.excluir) {
        throw new Error('Sem permissão para <b>exclusão</b>');
      }

      if (permissao === 'editar' && !this.permissoes.editar) {
        throw new Error('Sem permissão para <b>edição</b>');
      }

      if (permissao === 'gravar') {
        if (Number(this.model.codigo) === 0 && !this.permissoes.incluir) {
          throw new Error('Sem permissão para <b>gravação</b>');
        }

        if (Number(this.model.codigo) !== 0 && !this.permissoes.editar) {
          throw new Error('Sem permissão para <b>edição</b>');
        }
      }
    },
  },

  async mounted() {
    const { data } = await this.controller.buscarPermissoes();

    this.setPermissoes(data);
  },
};
</script>

<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <search-edit
        :controller="consultaSistemasCliente"
        :disabled="modoVisualizacao"
        :model="model.sistema"
        label="SISTEMA"
      />

      <v-combobox
        v-model="model.moduloSistema"
        :items="model.sistema.modulos"
        :disabled="modoVisualizacao || !model.sistema.codigo"
        item-text="descricao"
        item-value="codigo"
        label="MÓDULO"
        outlined
        :rules="[value => !!value || 'Escolha um módulo']"
      >
        <template slot="item" slot-scope="{ item }">
          {{ item.codigo + ' - ' + item.descricao }}
        </template>
        <template slot="selection" slot-scope="{ item }">
          {{ item.codigo + ' - ' + item.descricao }}
        </template>
      </v-combobox>

      <search-edit
        :controller="consultaContato"
        :disabled="modoVisualizacao"
        :form-cadastro="formContato"
        :model="model.contato"
        label="CONTATO"
      />

      <search-edit
        :controller="consultaMotivo"
        :disabled="modoVisualizacao"
        :model="model.motivo"
        :formConsultaAlternativo="formConsultaMotivos"
        label="MOTIVO"
      />

      <v-textarea
        v-model="model.relatadoPeloCliente"
        label="Ocorrido"
        outlined
        persistent-placeholder
        :readonly="modoVisualizacao"
      />
    </t-card>
  </t-container>
</template>

<script>
import { Contato } from '@/proxy-api/src/services/classes/Contato/Contato';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';

import { ConsultaSistemasCliente } from '@/proxy-api/src/services/classes/Sistema/ConsultaSistemasCliente';
import { ConsultaSistemasClienteModulo } from '@/proxy-api/src/services/classes/Sistema/ConsultaSistemasClienteModulo';
import { ConsultaCliente } from '@/proxy-api/src/services/classes/Cliente/ConsultaCliente';
import { ConsultaContato } from '@/proxy-api/src/services/classes/Contato/ConsultaContato';
import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';
import { ConsultaMotivo } from '@/proxy-api/src/services/classes/Motivo/ConsultaMotivo';

import FormConsultaMotivos from '@/components/form/FormConsultaArvore';
import FormContato from '@/views/contato/form/FormContato';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TUpperEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
    },
  },

  watch: {
    'model.cliente.codigo': function (value, oldValue) {
      if (Number(value) === Number(oldValue)) {
        return;
      }

      this.filtroCliente.valor = value;

      this.model.contato = new Contato();
      this.model.contato.setCliente(this.model.cliente);
    },
  },

  computed: {
    corStatus() {
      switch (this.model.situacao.codigo) {
        case 1:
          return 'success';
        default:
          return 'primary';
      }
    },
    formConsultaMotivos() {
      return FormConsultaMotivos;
    },
  },

  data: function () {
    return {
      consultaMotivo: new ConsultaMotivo(),
      consultaContato: new ConsultaContato(),
      consultaCliente: new ConsultaCliente(),
      consultaSistemasCliente: new ConsultaSistemasCliente(
        this.model.cliente.codigo,
      ),
      consultaSistemasClienteModulo: new ConsultaSistemasClienteModulo(
        this.model.cliente.codigo,
      ),
      formContato: FormContato,
      filtroCliente: new FiltroConsulta('clienteClc'),
      sessao: Sessao,
    };
  },

  methods: {
    inicializarFiltros() {
      this.configurarFiltroContato();
    },

    antesDeAbrirCadastro(instancia) {
      instancia.model.cliente.codigo = this.model.cliente.codigo;
      instancia.model.cliente.descricao = this.model.cliente.descricao;
    },

    configurarFiltroContato() {
      this.consultaContato.filtrosFixo.push(this.filtroCliente);

      this.verificarFiltroCliente();
    },

    verificarFiltroCliente() {
      if (this.model.cliente.codigo > 0) {
        this.filtroCliente.valor = this.model.cliente.codigo;
        return;
      }

      this.filtroCliente.valor = this.sessao.usuario.cliente.codigo;
    },

    async carregarModulos() {
      if (this.model.sistema.codigo) {
        try {
          await this.consultaSistemasClienteModulo.buscarRegistro(
            this.model.sistema.codigo,
          );

          const modulos = this.consultaSistemasClienteModulo.registros.map(
            modulo => {
              return {
                moduloSmod: modulo.moduloSmod,
                descricaoMod: modulo.descricaoMod,
              };
            },
          );

          this.model.sistema.setModulos(modulos);
        } catch (error) {
          console.error(error);
        }
      }
    },
  },

  watch: {
    'model.sistema.codigo'() {
      this.carregarModulos();
    },
    'model.cliente.codigo'() {
      (this.consultaSistemasCliente = new ConsultaSistemasCliente(
        this.model.cliente.codigo,
      )),
        (this.consultaSistemasClienteModulo = new ConsultaSistemasClienteModulo(
          this.model.cliente.codigo,
        ));
    },
  },
};
</script>

const MenuContato = () => {
  return [
    {
      id: 'consulta_contato',
      text: 'Pessoas',
      icon: 'mdi-account-multiple-outline',
    },
    {
      id: 'atendimento',
      text: 'Atendimentos',
      icon: 'mdi-phone-outline',
      subItems: [
        { id: 'novo_atendimento', text: 'Novo' },
        { id: 'consulta_atendimento', text: 'Consulta' },
      ],
    },
    {
      id: 'consulta_atendimento_presencial',
      text: 'Atendimentos Presenciais',
      icon: 'mdi-alarm-light-outline',
    },
    {
      id: 'consulta_atividade_projeto',
      text: 'Atividades do Projeto',
      icon: 'mdi-walk',
    },
    {
      id: 'consulta_solicitacao',
      text: 'Solicitações',
      icon: 'mdi-alarm-light-outline',
    },
    {
      id: 'consulta_evento',
      text: 'Eventos',
      icon: 'mdi-account-clock-outline',
    },
    {
      id: 'consulta_projeto',
      text: 'Projetos',
      icon: 'mdi-file-sign',
    },
    {
      id: 'consulta_proposta',
      text: 'Propostas',
      icon: 'mdi-card-text-outline',
    },
    {
      id: 'consulta_servico',
      text: 'Serviços',
      icon: 'mdi-account-hard-hat-outline',
    },
    {
      id: 'produtos',
      text: 'Produtos',
      icon: 'mdi-shopping',
    },
  ];
};

export { MenuContato };

<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <v-data-table
        :headers="headers"
        :items="model.contatos"
        no-data-text="Sem registros no momento"
        no-results-text="Sem registros no momento"
        hide-default-footer
        disable-pagination
        style="cursor: pointer"
        @click:row="abrirContato"
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import FormContato from '@/views/contato/form/FormContato';
import { ConsultaContato } from '@/proxy-api/src/services/classes/Contato/ConsultaContato';

export default {
  components: { TCard, TContainer },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Código',
          value: 'codigo',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Cliente',
          value: 'cliente.codigo',
        },
        {
          text: 'Papel',
          value: 'papel',
        },
      ],
    };
  },

  methods: {
    async abrirContato(contato) {
      try {
        const consultaContato = new ConsultaContato();
        await consultaContato.buscarRegistro(contato.codigo);

        const instancia = TFormUtil.instanciar(FormContato);
        instancia.model.json = consultaContato.registros[0];
        TFormUtil.abrir(instancia);
        instancia.$on('click-navigation', () => {
          TFormUtil.fechar(instancia);
        });
      } catch (error) {
        console.error(error);
      }
    },
  },
};
</script>

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Sistema } from '../Sistema/Sistema';
import { ModuloSistema } from '../Sistema/ModuloSistema';
import { DataUtils } from '../../../utils/DataUtils';

class Solicitacao extends Cadastro {
  constructor(persistente) {
    super();
    this.tipo = '';
    this.classificacao = '';
    this.data = '';
    this.contato = '';
    this.cliente = new Cliente();
    this.sistema = new Sistema();
    this.moduloSistema = new ModuloSistema();
    this.observacao = '';
    this.codigoParaExclusao = 0;
  }

  set json(dados) {
    this.codigo = Number(dados.atendSolSatd) || Number(dados.autoincTds);
    this.codigoParaExclusao = dados.autoincSatd;
    this.tipo = dados.descricaoTat;
    this.classificacao = dados.descricaoClassificacao;
    this.data = DataUtils.formatarDataParaDiaMesAno(dados.datasolTds);
    this.cliente.codigo = dados.clienteTds;
    this.cliente.descricao = dados.razaoCli;
    this.sistema.codigo = dados.sistemaTds;
    this.sistema.descricao = dados.nomeSis;
    this.moduloSistema.codigo = dados.moduloTds;
    this.moduloSistema.descricao = dados.descricaoMod;
    this.contato = dados.nomeClc;
    this.observacao = dados.observacaoTds;
  }

  get json() {
    return {
      atendSolSatd: this.codigo,
      autoincSatd: this.codigoParaExclusao,
      nomeClc: this.contato.descricao,
      nomeSis: this.sistema.descricao,
      descricaoMod: this.moduloSistema.descricao,
      observacaoTds: this.observacao,
    };
  }
}

export { Solicitacao };

<template>
  <t-container align-items="center" height="100%">
    <t-empty-state
      class="mt-16"
      text="Visualização para este arquivo indisponível"
      detail="Faça o download e abra localmente"
      icon="mdi-clipboard-text"
      buttonLabel="BAIXAR"
      :buttonCallback="downloadArquivo"
    />
  </t-container>
</template>

<script>
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';

export default {
  methods: {
    downloadArquivo() {
      this.$emit('downloadArquivo', this.model);
    },
  },
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  components: {
    TContainer,
    TEmptyState,
  },
};
</script>

<template>
  <form-cadastro
    title="Abrir Ticket"
    @aposGravar="aposGravar"
    :controller="controller"
    navigationIcon=""
    :model="model"
    ref="form"
    extended
    :processamento="processamento"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Arquivos Anexados </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :model="model"
          :modoVisualizacao="modoVisualizacao"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-arquivos-anexados :model="model" />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';
import { Atendimento } from '@/proxy-api/src/services/classes/Atendimento/Atendimento';
import { AtendimentoController } from '@/proxy-api/src/services/classes/Atendimento/AtendimentoController';
import { ConsultaSistemasCliente } from '@/proxy-api/src/services/classes/Sistema/ConsultaSistemasCliente';
import { ConsultaContato } from '@/proxy-api/src/services/classes/Contato/ConsultaContato';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import CadastrarSenha from './components/CadastrarSenha.vue';
import LoginApartirDoTicket from './components/LoginApartirDoTicket.vue';

import AbaIdentificacao from './components/AbaIdentificacao';
import AbaArquivosAnexados from './components/AbaArquivosAnexados/AbaArquivosAnexados.vue';
import TelaErroTicket from './components/TelaErroTicket.vue';

export default {
  components: {
    AbaIdentificacao,
    AbaArquivosAnexados,
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
  },

  data: function () {
    return {
      modoVisualizacao: false,
      dadosTicket: {},
      sessao: Sessao,
      abaSelecionada: 0,
      model: new Atendimento(true),
      controller: new AtendimentoController(),
      autenticacaoController: new AutenticacaoController(),
      processamento: '',
      credenciais: '',
    };
  },

  methods: {
    async tentativaLogin() {
      const base64 = this.$route.params.credenciais;
      try {
        this.processamento = 'Carregando informações...';
        const dadosRecebidosNaUrl = JSON.parse(Buffer.from(base64, 'base64'));
        //Extrai do base64(se houver) dados usados para capturar informacoes do usuário: email, cpf e cliente
        const credenciasLogin = {
          cliente: dadosRecebidosNaUrl.cliente
            ? dadosRecebidosNaUrl.cliente
            : '',
          email: dadosRecebidosNaUrl.email ? dadosRecebidosNaUrl.email : '',
          documento: dadosRecebidosNaUrl.documento
            ? dadosRecebidosNaUrl.documento
            : '',
        };

        this.dadosTicket = dadosRecebidosNaUrl;

        //Requisição para pegar mais informações do usuário
        const { data, status } =
          await this.autenticacaoController.informacoesUsuario(credenciasLogin);

        //Salva no data para ser usado posteriormente
        this.credenciais = data;

        /* Verifica se já tem uma sessão ativa no navegador, se o e-mail ou documento
        dessa sessão ativa for igual ao que está tentando acessar, o usuário pode ter atualizado página.
        Se não tiver senha ativa ainda, faz login e pede para cadastrar senha novamente.
        Sem essa validação seria possível enviar ticket sem senha cadastrada */

        if (this.sessao.id) {
          if (data['E-Mail'] === this.sessao.usuario.email) {
            if (data.SenhaDefinida) return;
          }
        }

        //Preferência para usar documento como login
        const usuario = data.Documento ? data.Documento : data['E-Mail'];

        //Se 200 e não tem senha faz login sem senha para obter JWT, cadastrar senha e prosseguir com ticket
        if (status === 200 && !data.SenhaDefinida) {
          await this.loginSemSenha(usuario);
          await this.cadastrarSenha(usuario);
        }

        if (status === 200 && data.SenhaDefinida) {
          await this.abrirFormLogin(usuario);
        }
      } catch ({ message, status }) {
        if (status > 400 && status < 500) await this.abrirFormLogin('');
        else this.abrirTelaErro('Impossível abrir ticket', message);
      } finally {
        this.processamento = '';
      }
    },

    async loginSemSenha(usuario) {
      await this.autenticacaoController.acessarSemSenha(usuario);
    },

    async cadastrarSenha(usuario) {
      const instancia = TFormUtil.instanciar(CadastrarSenha);
      instancia.usuario = usuario;
      await TFormUtil.abrir(instancia);

      instancia.$on('fechar', () => {
        TFormUtil.fechar(instancia);
      });
    },

    async abrirFormLogin(usuario) {
      const instancia = TFormUtil.instanciar(LoginApartirDoTicket);
      instancia.usuario.usuario = usuario;
      await TFormUtil.abrir(instancia);

      instancia.$on('fechar', async () => {
        TFormUtil.fechar(instancia);
        this.getSessao();
        await this.buscarSistema(this.model.sistema.codigo);
        await this.buscarContato();
      });
    },

    async abrirTelaErro(title, message) {
      const instancia = TFormUtil.instanciar(TelaErroTicket);
      instancia.title = title;
      instancia.message = message;
      await TFormUtil.abrir(instancia);
    },

    abrirTicket(credenciaisBase64) {
      this.model.abrirTicket(credenciaisBase64);
    },

    async buscarSistema(codigo) {
      const consultaSistema = new ConsultaSistemasCliente(
        this.model.cliente.codigo,
      );
      await consultaSistema.buscarRegistro(codigo);
      this.model.sistema.json = consultaSistema.registros[0];
    },

    async buscarContato() {
      const consultaContato = new ConsultaContato();
      let contato = {};

      if (this.credenciais.Documento) {
        contato = await consultaContato.buscaPorCPF(this.credenciais.Documento);
        this.model.contato.json = contato;
        return;
      }

      contato = await consultaContato.buscarPorEmail(
        this.credenciais['E-Mail'],
      );

      if (!contato) {
        contato = await consultaContato.buscarPorCodigo(
          this.sessao.usuario.codigoPessoa,
        );
      }

      this.model.contato.json = contato;
    },

    incluir() {
      this.$refs.form.incluir();
    },

    aposGravar() {
      this.modoVisualizacao = true;
      this.$refs.form.actionItems.forEach(action => {
        if (action.text === 'Gravar') {
          action.available = false;
        }
      });
    },

    verificarPermissao() {
      this.$refs.form.actionItems.forEach(action => {
        if (action.text === 'Editar') {
          action.available = false;
        }
        if (action.text === 'Excluir') {
          action.available = false;
        }
        if (action.text === 'Gravar') {
          action.available = true;
        }
      });
    },

    getSessao() {
      Sessao.json = Storage.getSessao();
      this.sessao = Sessao;
    },
  },

  async mounted() {
    this.verificarPermissao();

    this.$refs.form.permissoes.incluir = true;
    this.getSessao();

    try {
      await this.tentativaLogin();

      this.abrirTicket(this.dadosTicket);

      if (this.sessao.id) {
        await this.buscarSistema(this.model.sistema.codigo);
        await this.buscarContato();
      }

      this.model.origemAtendimento.codigo = 5; //CLIENTE (REGISTRADO PELO CLIENTE)
      this.model.tipoAtendimento.codigo = 77; // TICKET
    } catch (error) {
      console.error(error);
    }
  },
};
</script>

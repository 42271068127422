<template>
  <v-hover v-slot="{ hover }">
    <v-card :elevation="hover ? 16 : 4" :style="hover && 'transform: scale(1.015)'">
      <v-card-title>
        {{ model.descricao.charAt(0).toUpperCase() + model.descricao.slice(1).toLowerCase() }}
      </v-card-title>

      <v-card-subtitle>{{ model.observacoes }}</v-card-subtitle>
      <v-card-actions class="mt-5">
        <v-btn color="error" small v-if="model.pertence" absolute bottom right @click="$emit('revogar', model)">
          <v-icon left>
            mdi-account-remove-outline  
          </v-icon>
          Revogar inscrição
        </v-btn>

        <v-btn v-else class="primary" small absolute bottom right @click="$emit('inscrever', model)">
          <v-icon left>          
            mdi-account-check-outline
          </v-icon>
          Inscrever-se
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-hover>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';

export default {
  components: { TCard },
  props: {
    model: {
      type: Object,
      required: true
    }
  }
}
</script>

<style>

</style>
<template>
  <div>
    <t-container
      v-if="model.solicitacoes.length > 0"
      vertical-scroll
      height="100%"
    >
      <div v-for="solicitacao in model.solicitacoes" :key="solicitacao.codigo">
        <t-card>
          <solicitacao-item
            @excluir="excluirSolicitacao"
            :solicitacao="solicitacao"
            :modo-visualizacao="modoVisualizacao"
          />
        </t-card>
      </div>
    </t-container>

    <t-container v-else align-items="center" height="100%">
      <slot name="empty-state">
        <t-empty-state
          text="Nenhuma solicitação foi encontrada"
          detail="Não foram preenchidas nenhuma solicitação"
          icon="mdi-text-box-plus-outline"
          :buttonLabel="modoVisualizacao ? '' : 'ADICIONAR'"
          :buttonCallback="abrirConsulta"
        />
      </slot>
    </t-container>

    <v-tooltip left v-if="model.solicitacoes.length > 0">
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="abrirConsulta"
          v-bind="attrs"
          v-on="on"
          :disabled="modoVisualizacao"
          color="accent"
          id="lateral"
          dark
          absolute
          bottom
          right
          fab
          fixed
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar solicitação</span>
    </v-tooltip>
  </div>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { ConsultaAtendimentoPresencialSolicitacao } from '@/proxy-api/src/services/classes/AtendimentoPresencial/ConsultaAtendimentoPresencialSolicitacao';
import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';
import { Solicitacao } from '@/proxy-api/src/services/classes/Solicitacao/Solicitacao';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SolicitacaoItem from './Solicitacao';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';
import FormConsulta from '@/views/consultas/FormConsulta';

export default {
  components: {
    TCard,
    TContainer,
    SolicitacaoItem,
    TEmptyState,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  async mounted() {
    this.inicializarFiltros();
  },

  watch: {
    $props: {
      handler() {
        this.verificarFiltroCliente();
      },
      deep: true,
      immediate: true,
    },
  },

  data: function () {
    return {
      consultaAtendimentoPresencialSolicitacao:
        new ConsultaAtendimentoPresencialSolicitacao(),
      filtroCliente: new FiltroConsulta('clienteTds'),
      filtroDataDaBaixa: new FiltroConsulta('databaixaTds', '', 'VAZIO'),
    };
  },

  methods: {
    inicializarFiltros() {
      this.configurarFiltroContato();
      this.configurarFiltroDataDaBaixa();

      this.verificarFiltroCliente();
    },

    configurarFiltroContato() {
      this.consultaAtendimentoPresencialSolicitacao.filtrosFixo.push(
        this.filtroCliente,
      );
    },

    configurarFiltroDataDaBaixa() {
      this.consultaAtendimentoPresencialSolicitacao.filtrosFixo.push(
        this.filtroDataDaBaixa,
      );
    },

    verificarFiltroCliente() {
      if (this.model.cliente.codigo > 0) {
        this.filtroCliente.valor = this.model.cliente.codigo;
        return;
      }

      this.filtroCliente.valor = this.sessao.usuario.cliente.codigo;
    },

    abrirConsulta() {
      let instancia = TFormUtil.instanciar(FormConsulta);

      instancia.controller = this.consultaAtendimentoPresencialSolicitacao;
      instancia.model = this.model;
      instancia.formCadastro = this.formCadastro;
      instancia.fechaAposSelecionar = true;

      TFormUtil.abrir(instancia);

      instancia.$on('selecionado', registro => {
        this.setRegistro(registro);

        TFormUtil.fechar(instancia);
      });
    },

    setRegistro(registro) {
      const solicitacao = new Solicitacao();
      solicitacao.json = registro;

      const existeSolicitacao = this.model.solicitacoes.some(
        registro => Number(registro.codigo) === Number(solicitacao.codigo),
      );

      this.model.solicitacoesExcluidas =
        this.model.solicitacoesExcluidas.filter(
          solicitacao => solicitacao.codigo !== registro.codigo,
        );

      if (existeSolicitacao) {
        return;
      }

      this.model.solicitacoes.push(solicitacao);
      this.codigoSolicitacaoSelecionada = solicitacao.codigo;
    },

    excluirSolicitacao(registro) {
      this.model.solicitacoes = this.model.solicitacoes.filter(
        solicitacao => solicitacao.codigo !== registro.codigo,
      );
      this.model.solicitacoesExcluidas.push(registro);
    },
  },
};
</script>

<style scoped>
.card-titulo {
  font-size: 14px;
}
</style>

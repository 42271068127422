import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaArea extends ConsultaController {
  constructor(sistema) {
    super('sistema_Area', 'Areas', 'codigoSare');

    this.ordenacao = 'codigoSare';

    this.addFiltroFixo('sistemaSare', 'LISTA', `0,${sistema}`);
    this.addFiltro('codigoSare', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('descricaoSare', 'Descrição', TipoFiltro.String);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoSare;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `<div class="mt-2"> ${registro.descricaoSare}  </div>`;
  }
}

export { ConsultaArea };

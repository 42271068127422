import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaSistemasClienteModulo extends ConsultaController {
  constructor(codigoCliente) {
    super(
      'clientes/sistemas/modulos',
      'Módulos do Sistema do Cliente',
      'sistemaSsc',
    );
    this.ordenacao = 'nomeSis';
    this.addFiltroFixo('clienteSsc', 'IGUAL', codigoCliente);
    this.addFiltro('sistemaSsc', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('nomeSis', 'Descrição', TipoFiltro.String);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.sistemaSsc;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.nomeSis;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.nomeSis.substr(0, 1) + '</h1>';
  }
}

export { ConsultaSistemasClienteModulo };

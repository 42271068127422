<template>
  <t-container vertical-scroll height="100%">
    <t-card title="Identificação">
      <v-row v-if="model.codigo > 0" class="mb-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-chip label color="primay">{{ model.status.descricao }}</v-chip>
        </v-col>
      </v-row>
      <search-edit
        :disabled="modoVisualizacao"
        :model="model.cliente"
        label="CLIENTE"
      />

      <t-edit
        v-model="model.descricao"
        :disabled="modoVisualizacao"
        label="Descrição"
      />

      <t-edit v-model="model.tipo" :disabled="modoVisualizacao" label="Tipo" />

      <t-edit
        v-model="model.contato.email"
        :disabled="modoVisualizacao"
        label="E-mail"
      />

      <v-row>
        <v-col cols="6">
          <t-edit
            v-model="model.dataEntrada"
            :disabled="modoVisualizacao"
            label="Data de entrada"
          />
        </v-col>
        <v-col cols="6">
          <t-edit
            v-model="model.dataFinalizacao"
            :disabled="modoVisualizacao"
            label="Data de finalização"
          />
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TEdit, TMemoEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

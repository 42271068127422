import { api } from '@/proxy-api/src/services/api';
import { Controller } from '../Controllers/Controller';

import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';

class ConsultaController extends Controller {
  constructor(rota, titulo, campoChave) {
    super(rota);

    this.rota = rota;

    if (!campoChave) {
      throw new Error('Nenhum campoChave foi informado!');
    }

    this.campoChave = campoChave;
    this.ordenacao = campoChave;
    this.sentidoDaOrdenacao = 'ASC';

    this.filtros = [];
    this.filtrosFixo = [];
    this.registros = [];

    this.titulo = 'Consulta de ' + titulo;
    this.quantidadeRegistroPorPagina = 50;
  }

  addFiltro(atributo, nome, tipo, padrao = false) {
    this.filtros.push({ atributo, nome, tipo, padrao });
  }

  addFiltroFixo(campo, operador, valor = '') {
    this.filtrosFixo.push({ campo, operador, valor });
  }

  getFiltrosFixo() {
    return this.filtrosFixo;
  }

  async buscarRegistro(codigo) {
    const filtros = [new FiltroConsulta(this.campoChave, codigo)];
    await this.buscarRegistros(filtros, true);

    if (this.registros.length === 0) {
      throw new Error('Registro indísponivel ou não encontrado');
    }
  }

  async buscarRegistros(filtros, inicializa) {
    if (inicializa) {
      this.inicializar();
    }

    const { data } = await api.get(this.rota, this.getParametros(filtros));
    const { registros } = data;

    if (registros.length > 0) {
      this.pagina++;
    }

    this.registros = this.registros.concat(registros);
  }

  getParametros(filtros) {
    return {
      params: {
        filtros: JSON.stringify(filtros.concat(this.filtrosFixo)),
        limite: this.quantidadeRegistroPorPagina,
        pagina: this.pagina,
        ordenacao: this.ordenacao,
        sentido: this.sentidoDaOrdenacao,
        childs: true,
      },
    };
  }

  inicializar() {
    this.pagina = 1;
    this.registros = [];
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigo;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.descricao;
  }

  // --------------------------------------
  // getDetalhe
  // --------------------------------------
  getDetalhe() {
    return '';
  }

  // --------------------------------------
  // getAvatar
  // --------------------------------------
  getAvatar() {
    return '';
  }

  // --------------------------------------
  // getIcon
  // --------------------------------------
  getIcon() {
    return '';
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra() {
    return '';
  }
}

export { ConsultaController };

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaAtividadeProjeto extends ConsultaController {
  constructor() {
    super('projeto_realizacao/tarefasabertas', 'Atividades do Projeto', 'codigoPrt');

    this.ordenacao = 'codigoProj';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoProj', 'Código do projeto', TipoFiltro.Inteiro, true);
    this.addFiltro('descricaoPrt', 'Descricao da atividade', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoPrt;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
			return `
    		 <div class="mt-2">${ registro.descricaoPrt }</div>
    		 <p class="text-caption font-weight-light">Projeto: ${ registro.codigoProj } | Realizado: ${ registro.percrealizacaoPrt }%</p>`
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaAtividadeProjeto };

import { TMenuItem } from './TMenuItem';

class NovaAtividadeProjeto extends TMenuItem {
  static import() {
    return import('@/views/atividadeProjeto/form/FormAtividadeProjeto');
  }

  static aposAbrir(instancia) {
    instancia.incluir();
  }
}

export { NovaAtividadeProjeto };

<template>
  <t-container>
    <t-card>
      <search-edit
        :controller="consultaSetor"
        :disabled="modoVisualizacao"
        :model="model.setor"
        label="SETOR"
      />
    </t-card>
  </t-container>
</template>

<script>
import { ConsultaSetor } from '@/proxy-api/src/services/classes/Setor/ConsultaSetor';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';

export default {
  components: {
    TCard,
    TContainer,
    SearchEdit,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      consultaSetor: new ConsultaSetor(),
    };
  },
};
</script>

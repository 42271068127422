import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Contato } from '../Contato/Contato';
import { FuncionarioProjeto } from './FuncionarioProjeto';
import { AtividadeProjeto } from './AtividadeProjeto';

import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class Projeto extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Contato();
  }

  set json(dados) {
    this.codigo = dados.codigoProj;
    this.descricaoResumida = dados.descricaoProj;
    this.descricaoCompleta = dados.descricaocompProj;
    this.tipo = dados.descricaoPrt;

    this.cliente.codigo = dados.clienteProj;
    this.cliente.descricao = dados.razaoCli;
    this.status = this.preencheStatus(dados.statusProj);
    this.contato.codigo = dados.codigoProj;
    this.contato.descricao = dados.nomeClc;

    this.dataPrevisaoInicio = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtinicioPrevProj,
    ).substring(0, 17);
    this.dataPrevisaoFim = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtterminoPrevProj,
    ).substring(0, 17);

    this.dataRealizacaoInicio = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtinicioProj,
    ).substring(0, 17);
    this.dataRealizacaoTermino = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtterminoProj,
    ).substring(0, 17);

    this.envolvidos = this.preencheFuncionariosEnvolvidos(
      dados.projetoFuncionario,
    );

    this.atividades = this.preencheAtividades(dados.projetoTarefa);
  }
  get json() {}

  preencheFuncionariosEnvolvidos(funcionarios) {
    let envolvidos = [];

    if (funcionarios) {
      funcionarios.forEach(funcionario => {
        let envolvido = new FuncionarioProjeto();

        envolvido.json = funcionario;

        envolvidos.push(envolvido);
      });
    }

    return envolvidos;
  }

  preencheAtividades(atividades) {
    let atividadesProjeto = [];

    if (atividades) {
      atividades.forEach(atividade => {
        let atividadeProjeto = new AtividadeProjeto();

        atividadeProjeto.json = atividade;

        atividadesProjeto.push(atividadeProjeto);
      });
    }

    return atividadesProjeto;
  }

  preencheStatus(status) {
    switch (status) {
      case 0:
        return { descricao: 'INDEFINIDO', cor: 'black' };
      case 1:
        return { descricao: 'PLANEJAMENTO', cor: 'purple' };
      case 2:
        return { descricao: 'EM EXECUÇÃO', cor: 'primary' };
      case 3:
        return { descricao: 'FINALIZADO', cor: 'green' };
      case 4:
        return { descricao: 'ENCERRADO', cor: 'red' };
      case 5:
        return { descricao: 'CANCELADO', cor: 'red' };
    }
  }
}

export { Projeto };

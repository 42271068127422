<template>
  <div>
    <v-row dense>
      <v-col cols="4" sm="3">
        <t-integer-edit
          :appendIconClick="abrirConsulta"
          append-icon="mdi-magnify"
          :disabled="disabled"
          v-model="valorInput"
          @change="onChange"
          :label="label"
          ref="input"
        />
      </v-col>
      <v-col cols="8" sm="9">
        <slot>
          <t-upper-edit v-model="model.descricao" disabled />
        </slot>
      </v-col>
    </v-row>
    <t-loading v-if="estaProcessando" :text="processamento" value="" />
  </div>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

import FormConsulta from '@/views/consultas/FormConsulta';

import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TLoading from 'tek-components-vuejs/src/loading/TLoading.vue';
import ExibirMensagem from '../ExibirMensagem';

export default {
  props: {
    model: {
      type: Object,
      required: true,
      default: null,
    },

    controller: {
      default: null,
    },

    label: {
      type: String,
      required: true,
    },

    callback: {
      type: Function,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    formCadastro: {
      required: false,
    },

    formConsultaAlternativo: {
      required: false,
    },
  },

  components: {
    FormConsulta,
    TIntegerEdit,
    TUpperEdit,
    TLoading,
  },

  watch: {
    'model.codigo'(value) {
      this.valorInput = value;
    },
  },

  data: function () {
    return {
      estaProcessando: false,
      processamento: 'Carregando conteúdo...',
      valorInput: this.model.codigo,
    };
  },

  methods: {
    async onChange(value) {
      if (value === this.model.codigo) {
        return;
      }

      this.estaProcessando = true;

      try {
        await this.controller.buscarRegistro(value);

        this.setRegistro(this.controller.registros[0]);
      } catch (error) {
        // ExibirMensagem.erroGenerico(error);
        TMessageDialog.info()
          .message(`Registro <b> ${value} </b> não encontrado`)
          .okCallback(() => this.erroBuscarRegistro())
          .elementFocus(this.$refs.input)
          .show();
      } finally {
        this.estaProcessando = false;
      }
    },

    setRegistro(registro) {
      this.model.json = registro;
    },

    abrirConsulta() {
      let instancia;

      if (this.formConsultaAlternativo)
        instancia = TFormUtil.instanciar(this.formConsultaAlternativo);
      else {
        instancia = TFormUtil.instanciar(FormConsulta);
      }

      instancia.controller = this.controller;
      instancia.model = this.model;
      instancia.formCadastro = this.formCadastro;
      instancia.fechaAposSelecionar = true;

      TFormUtil.abrir(instancia);

      instancia.$on('antesDeAbrirCadastro', instancia => {
        this.$emit('antesDeAbrirCadastro', instancia);
      });

      instancia.$on('selecionado', registro => {
        this.setRegistro(registro);

        TFormUtil.fechar(instancia);
      });
    },

    erroBuscarRegistro() {
      this.valorInput = this.model.codigo;
    },

    focus() {
      this.$refs.input.getRealInput().focus();
    },
  },
};
</script>

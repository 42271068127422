<template>
  <v-form ref="form" id="form">
    <t-container height="100%" vertical-scroll>
      <t-card>
        <v-select
          :items="controller.filtros"
          item-value="atributo"
          v-model="atributo"
          item-text="nome"
          persistent-hint
          label="CAMPO"
          outlined
          @change="onAtributoChange"
        />

        <v-select
          v-model="condicao"
          :items="condicoes"
          label="CONDIÇÃO"
          persistent-hint
          outlined
          @change="onCondicaoChange"
        />

        <TIntegerEdit
          ref="valor"
          v-model.number="valorNumero"
          v-if="filtroSelecionado.tipo == TipoFiltro.Inteiro"
          label="CONTEÚDO"
          @pressEnter="aplicar"
          :disabled="condicao === 'PREENCHIDO'"
        ></TIntegerEdit>

        <TCPFEdit
          ref="valor"
          v-model="valorTexto"
          label="CONTEÚDO"
          v-else-if="filtroSelecionado.tipo == TipoFiltro.CPF"
          @pressEnter="aplicar"
          :disabled="condicao === 'PREENCHIDO'"
        ></TCPFEdit>

        <TCNPJEdit
          ref="valor"
          v-model="valorTexto"
          label="CONTEÚDO"
          v-else-if="filtroSelecionado.tipo == TipoFiltro.CNPJ"
          @pressEnter="aplicar"
          :disabled="condicao === 'PREENCHIDO'"
        ></TCNPJEdit>
        <TDateEdit
          ref="valor"
          v-model="valorData"
          label="CONTEÚDO"
          placeholder=" "
          :rules="dateRules"
          v-else-if="filtroSelecionado.tipo == TipoFiltro.Data"
          @pressEnter="aplicar"
          :disabled="condicao === 'PREENCHIDO'"
        ></TDateEdit>

        <TEdit
          ref="valor"
          v-else
          v-model="valorTexto"
          label="CONTEÚDO"
          placeholder=" "
          @pressEnter="aplicar"
          :disabled="condicao === 'PREENCHIDO'"
        ></TEdit>

        <v-row dense no-gutters>
          <v-col cols="6" class="mr-1">
            <v-btn
              outlined
              block
              placeholder=" "
              color="primary"
              min-height="56"
              @click="fixar"
            >
              <v-icon dark>mdi-pin-outline</v-icon>
              Fixar
            </v-btn>
          </v-col>
          <v-col class="ml-1">
            <v-btn
              block
              placeholder=" "
              color="primary"
              min-height="56"
              @click="aplicar"
              >Aplicar</v-btn
            >
          </v-col>
        </v-row>
      </t-card>
    </t-container>
  </v-form>
</template>

<script>
import CondicaoFiltro from '@/proxy-api/src/enumerations/CondicaoFiltro';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TCPFEdit from 'tek-components-vuejs/src/edit/TCPFEdit';
import TCNPJEdit from 'tek-components-vuejs/src/edit/TCNPJEdit';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import TDateEdit from 'tek-components-vuejs/src/edit/TDateEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEdit from 'tek-components-vuejs/src/edit/TEdit.vue';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

export default {
  components: {
    TUpperEdit,
    TContainer,
    TCard,
    TCPFEdit,
    TCNPJEdit,
    TIntegerEdit,
    TDateEdit,
    TEdit,
  },

  props: {
    controller: {
      type: Object,
    },
  },

  created() {
    this.definirAtributoPadrao();
  },

  watch: {
    atributo() {
      this.condicao = this.getPrimeiracondicaoDisponivel();
    },

    filtrosFixos: {
      immediate: true,
      handler: function () {
        this.$emit('filtrosFixosChange', this.filtrosFixos);
      },
    },
  },

  computed: {
    filtroSelecionado() {
      return this.getFiltroSelecionado();
    },

    condicoes() {
      return CondicaoFiltro.toJSON(this.filtroSelecionado.tipo);
    },
  },

  data: function () {
    return {
      atributo: '',
      condicao: '',
      valorTexto: '',
      valorNumero: 0,
      valorData: '',
      dateRules: [
        valor => {
          if (
            this.filtroSelecionado.tipo === TipoFiltro.Data &&
            valor.length !== 10
          ) {
            return 'Informe uma data';
          }

          return true;
        },
      ],
      TipoFiltro: TipoFiltro,
      filtrosFixos: [],
    };
  },

  methods: {
    aplicar() {
      if (!this.$refs.form.validate()) {
        return;
      }

      this.$emit('aplicar', this.getFiltros());
    },

    fixar() {
      const filtroFixo = this.getFiltro();

      this.filtrosFixos.push(filtroFixo);
      this.filtroFixoAdicionado = true;
    },

    getFiltro() {
      const filtroSelecionado = this.getFiltroSelecionado();
      if (filtroSelecionado.tipo === 'data') {
        return {
          campo: this.atributo,
          valor: DataUtils.formatarDataParaTimeZone(this.valorData),
          operador: this.condicao,
        };
      }
      return {
        campo: this.atributo,
        valor: this.$refs.valor.value,
        operador: this.condicao,
      };
    },

    getFiltros() {
      return this.filtrosFixos.concat(this.getFiltro());
    },

    selecionarCampoValor() {
      this.$nextTick(() => {
        if (!this.$refs.valor) {
          return;
        }
        this.$refs.valor.focus();
      });
    },

    limparValores() {
      this.valorTexto = '';
      this.valorNumero = 0;
    },

    onAtributoChange() {
      this.limparValores();
      this.selecionarCampoValor();
    },

    onCondicaoChange() {
      this.selecionarCampoValor();
    },

    getFiltroSelecionado() {
      for (const filtro of this.controller.filtros) {
        if (filtro.atributo === this.atributo) {
          return filtro;
        }
      }
    },

    definirAtributoPadrao() {
      // Seleciona o atributo padrão
      for (const filtro of this.controller.filtros) {
        if (filtro.padrao) {
          this.atributo = filtro.atributo;
          return;
        }
      }

      // Seleciona o primeiro caso não tenha um padrão
      if (this.controller.filtros.length > 0) {
        this.atributo = this.controller.filtros[0].atributo;
      }
    },

    getPrimeiracondicaoDisponivel() {
      if (this.condicoes.length > 0) {
        return this.condicoes[0].value;
      }
    },
  },
};
</script>

<style scoped>
#form {
  height: 100%;
}
</style>

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';
import { Sessao } from '../Autenticacao/Sessao';

class ConsultaSolicitacao extends ConsultaController {
  constructor() {
    super('atd_solicitado', 'Solicitação', 'autoincTds');

    this.ordenacao = 'autoincTds';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltroFixo(
      'clienteTds',
      'IGUAL',
      Sessao.usuario.cliente.codigo.toString(),
    );
    this.addFiltro('autoincTds', 'Código da solicitação', TipoFiltro.Inteiro);
    this.addFiltro('sistemaTds', 'Código do sistema', TipoFiltro.Inteiro);
    this.addFiltro('nomeSis', 'Descrição do sistema', TipoFiltro.String);
    this.addFiltro('moduloTds', 'Código do módulo', TipoFiltro.Inteiro);
    this.addFiltro('descricaoMod', 'Descrição do módulo', TipoFiltro.String);
    this.addFiltro('nomeClc', 'Contato', TipoFiltro.String);
    this.addFiltro('observacaoTds', 'Observação', TipoFiltro.String);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.autoincTds;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `
    		 <div class="mt-2">${registro.descricaoTat}</div>
    		 <p class="text-caption font-weight-light">${registro.nomeSis} | ${registro.descricaoMod}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra() {
    return '';
  }
}

export { ConsultaSolicitacao };

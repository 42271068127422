import { Model } from '../classes/Model';
import { Arquivo } from '../../classes/Arquivo/Arquivo';

export class Cadastro extends Model {
  constructor() {
    super();

    this.codigo = 0;
    this.arquivos = [];
  }

  importarArquivos(arquivos) {
    arquivos = arquivos.filter(arquivo => arquivo.nomeArq);

    this.arquivos = [].concat(
      arquivos.map(arquivo => {
        let arquivoObjeto = new Arquivo();
        arquivoObjeto.json = arquivo;
        return arquivoObjeto;
      }),
    );
  }

  addArquivo(arquivo) {
    this.arquivos.push(arquivo);
  }

  removerArquivo(arquivo) {
    this.arquivos = this.arquivos.filter(a => a.codigo !== arquivo.codigo);
  }

  get json() {}

  set json(value) {}
}

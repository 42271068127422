<template>
  <form-cadastro
    :title="`Solicitação ${model.codigo ? model.codigo : ''}`"
    :controller="controller"
    :model="model"
    ref="form"
  >
    <t-container vertical-scroll height="100%">
      <t-card title="Identificação">
        <v-row dense>
          <v-col cols="2">
            <t-integer-edit
              v-model="model.sistema.codigo"
              :disabled="modoVisualizacao"
              label="Cod. Sistema"
            />
          </v-col>
          <v-col cols="10">
            <t-edit
              v-model="model.sistema.descricao"
              :disabled="modoVisualizacao"
              label="Descrição sistema"
            />
          </v-col>
        </v-row>

        <v-row dense>
          <v-col cols="2" dense>
            <t-integer-edit
              v-model="model.moduloSistema.codigo"
              :disabled="modoVisualizacao"
              label="Cod. módulo"
            />
          </v-col>
          <v-col cols="10">
            <t-edit
              v-model="model.moduloSistema.descricao"
              :disabled="modoVisualizacao"
              label="Descrição módulo"
            />
          </v-col>
        </v-row>

        <t-edit
          v-model="model.tipo"
          :disabled="modoVisualizacao"
          label="Tipo"
        />

        <t-edit
          v-model="model.classificacao"
          :disabled="modoVisualizacao"
          label="Classificação"
        />

        <v-row dense>
          <v-col cols="6">
            <t-edit
              v-model="model.contato"
              :disabled="modoVisualizacao"
              label="Contato"
            />
          </v-col>
          <v-col cols="6">
            <t-edit
              v-model="model.data"
              :disabled="modoVisualizacao"
              label="Data"
            />
          </v-col>
        </v-row>

        <v-textarea
          v-model="model.observacao"
          label="Observação"
          readonly
          outlined
          persistent-placeholder
        />
      </t-card>
    </t-container>
  </form-cadastro>
</template>

<script>
import { Solicitacao } from '@/proxy-api/src/services/classes/Solicitacao/Solicitacao';
import { SolicitacaoController } from '@/proxy-api/src/services/classes/Solicitacao/SolicitacaoController';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TEdit,
    TCard,
    TContainer,
    TIntegerEdit,
  },

  mounted() {
    this.removerAcoesDoUsuario();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new Solicitacao(),
      controller: new SolicitacaoController(),
      abaSelecionada: 0,
    };
  },

  methods: {
    removerAcoesDoUsuario() {
      this.$refs.form.actionItems = [];
    },
  },
};
</script>

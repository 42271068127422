<template>
  <iframe
    id="iframepdf"
    :src="`data:application/pdf;base64,${src}`"
    style="width: 100vw; height: calc(100vh - 64px)"
    class="mt-3"
  ></iframe>
</template>

<script>
export default {
  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

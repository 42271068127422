<template>
  <t-form title="Recuperação de acesso" :processamento="processamento">
    <t-container>
      <t-card>
        <v-text-field
          v-model="usuario"
          outlined
          label="Usuário/E-mail/Cpf"
          persistent-placeholder=""
          @keyup.enter="recuperar"
        ></v-text-field>
        <v-row class="justify-end pa-3">
          <v-btn min-height="56" outlined color="primary" @click="recuperar"
            >Recuperar</v-btn
          >
        </v-row>
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TForm, TCard, TContainer, TEdit },

  props: {
    controller: {
      type: Object,
    },
  },

  data: function () {
    return {
      usuario: '',
      processamento: '',
    };
  },

  methods: {
    esconderEmail(email) {
      const emailDisfarcado = email.replace(
        /(\w{3})[\w.-]+@([\w.]+\w)/,
        '$1***@$2',
      );
      return emailDisfarcado;
    },

    esconderTelefone(telefone) {
      return telefone.slice(0, 2) + telefone.slice(2).replace(/.(?=...)/g, '*');
    },

    mensagemSucesso(resposta) {
      let enviadoPara = resposta.EnviadoPara;
      let tipo = 'e-mail';

      if (enviadoPara === this.usuario) enviadoPara = resposta.EnviadoPara;
      else {
        tipo = enviadoPara.includes('@') ? 'e-mail' : 'WhatsApp';
        enviadoPara =
          tipo === 'e-mail'
            ? this.esconderEmail(enviadoPara)
            : this.esconderTelefone(enviadoPara);
      }

      TMessageDialog.success()
        .message(
          `Recuperação de acesso enviada para o ${tipo} <b>${enviadoPara}</b>`,
        )
        .okCallback(() => {
          this.$emit('aposFechar');
        })
        .show();
    },

    async recuperar() {
      if (this.usuario.trim().length === 0) {
        TMessageDialog.error().message('Usuário/E-mail não preenchido').show();
        return;
      }

      this.processamento = 'Buscando dados do usuário...';
      try {
        const resposta = await this.controller.recuperarAcesso(this.usuario);
        this.mensagemSucesso(resposta);
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

<style></style>

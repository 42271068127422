<template>
  <div class="container pa-0">
    <v-row
      class="ma-0 pa-3 header"
      style="border-bottom: solid 3px rgba(0, 0, 0, 0.1)"
    >
      <v-col cols="12">
        <v-row align="center" justify="center">
          <div class="subtitle-1">Filtros Fixos</div>
        </v-row>
      </v-col>
    </v-row>

    <v-container fluid class="scrollable-container pt-1">
      <v-card
        v-for="(filtroFixo, index) in filtrosFixos"
        :key="index"
        class="mt-2"
        outlined
      >
        <div class="pa-3">
          <div style="position: absolute; right: 10px; top: 10px">
            <v-btn icon tabindex="-1" @click="remover(filtroFixo)">
              <v-icon class="primary--text text--darken-3">mdi-delete</v-icon>
            </v-btn>
          </div>

          <div class="primary--text text--darken-3 font-weight-medium">
            {{ getCampo(filtroFixo.campo) }}
          </div>

          <div
            class="primary--text text--darken-3 ma-0 font-weight-bold text-caption"
          >
            {{ getCondicao(filtroFixo.operador) }}
          </div>

          <div class="primary--text text--darken-3 mt-1 text-body-2">
            {{ getConteudo(filtroFixo.valor) }}
          </div>
        </div>
      </v-card>

      <div class="linha-extra" />

      <div class="footer">
        <v-btn
          rounded
          block
          placeholder=" "
          color="error"
          min-height="56"
          @click="removerTodos"
          >Remover Todos ({{ this.filtrosFixos.length }})</v-btn
        >
      </div>
    </v-container>
  </div>
</template>
<script>
import CondicaoFiltro from '@/proxy-api/src/enumerations/CondicaoFiltro';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';
import moment from 'moment';

export default {
  props: {
    filtrosFixos: {
      type: Array,
      default: () => {
        return [];
      },
    },

    controller: {
      type: Object,
      default: null,
    },
  },

  methods: {
    remover(filtroFixo) {
      for (let i = 0; i < this.filtrosFixos.length; i++) {
        if (this.filtrosFixos[i] === filtroFixo) {
          this.filtrosFixos.splice(i, 1);
          break;
        }
      }
    },

    removerTodos() {
      this.filtrosFixos.splice(0, this.filtrosFixos.length);
    },

    getCampo(atributo) {
      const campo = this.controller.filtros.find(
        filtro => filtro.atributo === atributo,
      );
      return campo ? campo.nome : '';
    },

    getCondicao(condicao) {
      return CondicaoFiltro.get(condicao).text;
    },

    getConteudo(conteudo) {
      if (conteudo === '') {
        return '(vazio)';
      }
      if (moment(conteudo, moment.ISO_8601).isValid())
        return DataUtils.formatarDataParaDiaMesAno(conteudo);
      return conteudo;
    },
  },
};
</script>
<style>
.container {
  height: calc(100% - 75px);
}

.header {
  background-color: white;
}

.footer {
  position: absolute;
  bottom: 80px;
  width: calc(100% - 27px);
}

.scrollable-container {
  height: calc(100% - 64px);
  overflow-y: auto;
}

.linha-extra {
  height: 75px;
}
</style>

<template>
  <t-container>
    <t-card class="mt-8">
      <v-textarea
        v-model="src"
        readonly
        outlined
        persistent-placeholder
        style="width: 90vw"
        rows="16"
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';

export default {
  components: { TCard, TContainer },
  props: ['src'],
};
</script>

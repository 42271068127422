import Vue from 'vue';
import VueRouter from 'vue-router';

import Login from '@/views/login/Login';
import Principal from '@/views/principal/Principal';
import RevogacaoEmail from '@/views/revogacaoEmail/RevogacaoEmail';
import ValidacaoEmail from '@/views/validacaoEmail/ValidacaoEmail';
import ConfirmarPresenca from '@/views/confirmarPresenca/ConfirmarPresenca';
import Inscricao from '@/views/inscricao/Inscricao';
import Avaliacao from '@/views/avaliacao/Avaliacao';
import Ticket from '@/views/ticket/form/FormTicket';

Vue.use(VueRouter);

const routes = [
  {
    path: '/login',
    name: 'Login',
    component: Login,
  },
  {
    path: '/login/:operacao',
    name: 'LoginOperacao',
    component: Login,
  },
  {
    path: '/principal',
    name: 'Principal',
    component: Principal,
    meta: {
      hasAuth: true,
    },
  },
  {
    path: '/principal/:operacao',
    name: 'PrincipalOperacao',
    component: Principal,
  },
  {
    path: '/avaliacao/:credenciais',
    name: 'Avaliacao',
    component: Avaliacao,
  },
  {
    path: '/evento/convite/:credenciais',
    name: 'ConviteEvento',
    component: ConfirmarPresenca,
  },
  {
    path: '/revogacao/:credenciais',
    name: 'RevogacaoEmailComCredencial',
    component: RevogacaoEmail,
  },
  {
    path: '/revogacao',
    name: 'RevogacaoEmail',
    component: RevogacaoEmail,
  },
  {
    path: '/validacaoemail/:credenciais',
    name: 'ValidacaoEmai',
    component: ValidacaoEmail,
  },
  {
    path: '/inscricao/:credenciais',
    name: 'Inscricao',
    component: Inscricao,
  },
  {
    path: '/ticket/:credenciais',
    name: 'Ticket',
    component: Ticket,
  },
  {
    path: '/:pathMatch(.*)*',
    redirect: { name: 'Login' },
  },
];

const router = new VueRouter({
  routes,
  //mode: 'history',
});

export default router;

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class ListaPublicacao extends Cadastro {
  constructor(persistente) {
    super();
    this.codigo = 0;
    this.descricao = '';
    this.observacoes = '';
    this.pertence = false;
  }

  set json(dados) {
    this.codigo = Number(dados.codigo);
    this.descricao = dados.descricao;
    this.observacoes = dados.observacoes;
    this.pertence = dados.pertence;
  }

  get json() {
    return {
      codigo: this.codigo,
      descricao: this.descricao,
      observacoes: this.observacoes,
      pertence: this.pertence
    }
  }
}

export { ListaPublicacao }
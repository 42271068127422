<template>
  <t-container vertical-scroll height="100%">
    <t-card title="Identificação">
      <v-row v-if="model.codigo > 0" class="mb-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-chip label dark :color="model.status.cor">{{
            model.status.descricao
          }}</v-chip>
        </v-col>
      </v-row>

      <search-edit
        :disabled="modoVisualizacao"
        :model="model.cliente"
        label="CLIENTE"
      />

      <t-edit
        v-model="model.contato.descricao"
        :disabled="modoVisualizacao"
        label="Contato"
      />

      <t-edit v-model="model.tipo" :disabled="modoVisualizacao" label="Tipo" />

      <t-edit
        v-model="model.descricaoResumida"
        :disabled="modoVisualizacao"
        label="Descrição resumida"
      />

      <v-textarea
        v-model="model.descricaoCompleta"
        label="Descrição completa"
        readonly
        outlined
        persistent-placeholder
      />
    </t-card>

    <t-card title="Previsão">
      <v-row>
        <v-col cols="6">
          <t-edit
            v-model="model.dataPrevisaoInicio"
            :disabled="modoVisualizacao"
            label="Início"
          />
        </v-col>
        <v-col cols="6">
          <t-edit
            v-model="model.dataPrevisaoFim"
            :disabled="modoVisualizacao"
            label="Termino"
          />
        </v-col>
      </v-row>
    </t-card>

    <t-card title="Realização">
      <v-row>
        <v-col cols="6">
          <t-edit
            v-model="model.dataRealizacaoInicio"
            :disabled="modoVisualizacao"
            label="Início"
          />
        </v-col>
        <v-col cols="6">
          <t-edit
            v-model="model.dataRealizacaoTermino"
            :disabled="modoVisualizacao"
            label="Termino"
          />
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import FormContato from '@/views/contato/form/FormContato';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <t-date-edit
        v-model="model.dataColeta"
        :disabled="modoVisualizacao"
        label="Coleta"
      />

      <search-edit
        :model="model.atendente"
        :disabled="modoVisualizacao"
        label="Consultor"
      />

      <search-edit
        :model="model.sistema"
        :disabled="modoVisualizacao"
        label="Sistema"
      />

      <search-edit
        :model="model.moduloSistema"
        :disabled="modoVisualizacao"
        label="Modúlo"
      />

      <search-edit
        :model="model.area"
        :disabled="modoVisualizacao"
        label="Área"
      />

      <search-edit
        :model="model.origem"
        :disabled="modoVisualizacao"
        label="Origem"
      />

      <search-edit
        :model="model.tipo"
        :disabled="modoVisualizacao"
        label="Tipo"
      />

      <search-edit
        :model="model.status"
        :disabled="modoVisualizacao"
        label="Status"
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';
import TDateEdit from 'tek-components-vuejs/src/edit/TDateEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TEdit, TMemoEdit, TDateEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

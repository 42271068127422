<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <search-edit
        :model="model.cliente"
        :disabled="modoVisualizacao"
        label="Cliente"
      />

      <search-edit
        :model="model.contato"
        :disabled="modoVisualizacao"
        label="Contato"
      />

      <search-edit
        :model="model.versaoOcorrencia"
        :disabled="modoVisualizacao"
        label="Versão Ocorrência"
      />

      <search-edit
        :model="model.proposta"
        :disabled="modoVisualizacao"
        label="Proposta"
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';

export default {
  components: {
    TCard,
    TContainer,
    SearchEdit,
    TEdit,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

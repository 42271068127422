<template>
  <v-row align="center" justify="center">
    <video
      controls
      :width="$vuetify.breakpoint.xs ? '90%' : '600px'"
      class="mt-16"
    >
      <source
        :type="`video/${extrairExtensaoArquivo()}`"
        :src="`data:video/${extrairExtensaoArquivo()};base64,${
          model.arquivoEncoded
        }`"
      />
    </video>
  </v-row>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },

  methods: {
    extrairExtensaoArquivo() {
      const extensao = this.model.nome.split('.').pop();
      return extensao;
    },
  },
};
</script>

<style></style>

class HoraUtils {
  static formatarHoraParaInteiro(hora) {
    let partesHora = hora.split(':');
    let horas = parseInt(partesHora[0]);
    let minutos = parseInt(partesHora[1]);
    let decimal = horas + minutos / 60;
    return decimal;
  }

  static formatarNumeroParaHora(decimal) {
    let horas = Math.floor(decimal);
    let minutos = Math.round((decimal - horas) * 60);
    if (minutos == 60) {
      horas++;
      minutos = 0;
    }
    let strHoras = horas.toString().padStart(2, '0');
    let strMinutos = minutos.toString().padStart(2, '0');
    return strHoras + ':' + strMinutos;
  }
}

export { HoraUtils };

import { TMenuItem } from './TMenuItem';

class NovaPessoa extends TMenuItem {
  static import() {
    return import('@/views/contato/form/FormContato');
  }

  static aposAbrir(instancia) {
    instancia.incluir();
  }
}

export { NovaPessoa };

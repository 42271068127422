import Vue from 'vue';
import Vuetify from 'vuetify/lib';
import minifyTheme from 'minify-css-string';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    options: { minifyTheme },
    dark: false,
    themes: {
      light: {
        primary: '#2196F3',
        secondary: '#009688',
        accent: '#ff9800',
        error: '#AE2824',
        warning: '#ffc107',
        info: '#616161',
        success: '#4caf50',
      },
    },
  },
});

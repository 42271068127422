import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { Evento } from '@/proxy-api/src/services/classes/Evento/Evento';
const evento = new Evento();

import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

Sessao.json = Storage.getSessao();

class ConsultaEvento extends ConsultaController {
  constructor() {
    super('evento', 'Evento', 'codigoEve');

    this.ordenacao = 'codigoEve';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoEve', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('dtinicioEve', 'Data de Início', TipoFiltro.Data);

    if (!Sessao.isAcessoCliente()) {
      this.addFiltro('clienteEve', 'Codigo do cliente', TipoFiltro.Inteiro);
      this.addFiltro('razaoCli', 'Razão social', TipoFiltro.String);
    }
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoEve;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `
        <div>${registro.descricaoEve}</div>
        <p class="text-caption mb-0">${DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
          registro.dtrealizadoEve
            ? registro.dtrealizadoEve
            : registro.dtinicioEve,
        )} | ${evento.getDescricaoSituacaoEvento(registro.situacaoEve)}
        </p>
    `;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaEvento };

<template>
  <t-container>
    <t-card>
      <v-row dense class="d-flex justify-center">
        <v-col cols="6" sm="3">
          <v-switch
            :disabled="modoVisualizacao"
            v-model="model.ativo"
            color="accent"
            label="Ativo"
            inset
          />
        </v-col>

        <v-col cols="6" sm="3">
          <v-switch
            v-model="model.procurador"
            label="Procurador"
            color="accent"
            disabled
            inset
          ></v-switch>
        </v-col>

        <v-col cols="6" sm="3">
          <v-switch
            :disabled="modoVisualizacao"
            v-model="model.solicitao"
            label="Solicitação Desenvolvimento"
            color="accent"
            inset
          ></v-switch>
        </v-col>
      </v-row>

      <v-row dense class="d-flex justify-center">
        <v-col cols="6" sm="3">
          <v-switch
            v-model="model.atendeAtendimentoTelefonico"
            label="Atendimento"
            :disabled="modoVisualizacao"
            color="accent"
            inset
          ></v-switch>
        </v-col>

        <v-col cols="6" sm="3">
          <v-switch
            v-model="model.atendimentoPresencial"
            label="Solicitação Atd Presencial"
            :disabled="modoVisualizacao"
            color="accent"
            inset
          ></v-switch>
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';

export default {
  components: {
    TCard,
    TContainer,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

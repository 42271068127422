<template>
  <div>
    <v-data-table
      class="px-8 py-4"
      :headers="headers"
      :items="model.envolvidos"
      no-data-text="Sem registros no momento"
      no-results-text="Sem registros no momento"
      hide-default-footer
      disable-pagination
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Código',
          value: 'codigo',
        },
        {
          text: 'Nome Funcionário',
          value: 'nome',
        },
        {
          text: 'Papel',
          value: 'papel',
        },
        {
          text: 'Descrição Papel',
          value: 'descricaoPapel',
        },
        {
          text: 'Avaliação',
          value: 'avaliacao',
        },
        {
          text: 'Pontos',
          value: 'pontos',
        },
      ],
    };
  },
};
</script>

<style></style>

import { ConsultaController } from '../../bases/Consulta/ConsultaController';
import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';
import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '../Storage/Storage';
Sessao.json = Storage.getSessao();

class ConsultaContato extends ConsultaController {
  constructor() {
    super('clientes_contatos', 'Contatos', 'codigoClc');

    this.ordenacao = 'nomeClc';

    if (!Sessao.isAcessoCliente()) {
      this.addFiltro('clienteClc', 'Código do cliente', TipoFiltro.Inteiro);
    }
    this.addFiltro('emailClc', 'E-mail', TipoFiltro.String);
    this.addFiltro('codigoClc', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('cpfClf', 'CPF', TipoFiltro.String);
    this.addFiltro('nomeClc', 'Descrição', TipoFiltro.String, true);
  }

  async buscaPorCPF(cpf) {
    const filtros = [new FiltroConsulta('cpfClf', cpf)];
    await this.buscarRegistros(filtros, true);
    return this.registros[0];
  }

  async buscarPorEmail(email) {
    const filtros = [new FiltroConsulta('emailClc', email)];
    await this.buscarRegistros(filtros, true);
    return this.registros[0];
  }

  async buscarPorCodigo(codigo) {
    await this.buscarRegistro(codigo);
    return this.registros[0];
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoClc;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    if (!Sessao.isAcessoFuncionario())
      return `<div>${registro.nomeClc}</div>
              <p class="text-caption"> ${
                registro.ativoClc === 'S' ? 'ATIVO' : 'INATIVO'
              } </p>`;

    return `<div>${registro.nomeClc}</div>
           <p class="text-caption"> ${registro.razaoCli}  |  ${
      registro.ativoClc === 'S' ? 'ATIVO' : 'INATIVO'
    }</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return `<h1> ${registro.nomeClc.substr(0, 1)} </h1>`;
  }
}

export { ConsultaContato };

<template>
  <t-form title="Alterar Senha" :processamento="processamento">
    <t-container>
      <t-card>
        <v-text-field
          v-model="senhaAtual"
          label="SENHA ATUAL"
          outlined
          required
          :append-icon="senhaAtualVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="senhaAtualVisivel ? 'text' : 'password'"
          @click:append="senhaAtualVisivel = !senhaAtualVisivel"
          persistent-placeholder=""
        />
        <v-text-field
          v-model="novaSenha"
          label="NOVA SENHA"
          outlined
          required
          :append-icon="novaSenhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="novaSenhaVisivel ? 'text' : 'password'"
          @click:append="novaSenhaVisivel = !novaSenhaVisivel"
          @keypress.enter="alterarSenha"
          persistent-placeholder=""
        />
        <v-btn block color="primary" min-height="56" @click="alterarSenha"
          >Alterar</v-btn
        >
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';

import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TForm, TCard, TContainer, TUpperEdit },

  props: {
    usuario: {
      type: Object,
    },
  },

  data: function () {
    return {
      senhaAtual: '',
      senhaAtualVisivel: false,
      novaSenha: '',
      novaSenhaVisivel: false,
      controller: new AutenticacaoController(),
      processamento: '',
    };
  },
  methods: {
    async alterarSenha() {
      this.processamento = 'Alterando senha...';
      try {
        const response = await this.controller.alterarSenha(
          this.senhaAtual,
          this.novaSenha,
        );
        if (response) {
          TMessageDialog.success()
            .message('Senha altarada com sucesso!')
            .okCallback(() => this.$emit('fechar'))
            .show();
        }
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

import axios from 'axios';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';

const marlon = 'http://192.168.254.213:9000';
const gabriel = 'http://192.168.254.42:9000';

export const api = axios.create({
  baseURL:
    process.env.NODE_ENV === 'development'
      ? gabriel
      : 'https://api.atendimento.teksystemweb.com.br:9000',
});

api.interceptors.request.use(config => {
  validarSeTemCredenciais(config);

  config.headers.Consumer_Key = 'TEKSANSADFV21BG52GDSJE4GV86FD83DKEJFD5F8';

  return config;
});

api.interceptors.response.use(response, error);

function response(r) {
  return r;
}

function error(e) {
  tratamentoAxiosError(e);

  return Promise.reject(e);
}

function tratamentoAxiosError(e) {
  if (!e.response) throw new Error('Sem conexão com servidor');

  const { response } = e;
  const { status, data } = response;
  if (String(data.error).includes('is no longer valid')) {
    throw new Error('Sua sessão expirou, por favor faça login novamente');
  }
  if (!data.error) throw { status, message: data };
  else throw { status, message: data.error };
}

function validarSeTemCredenciais(config) {
  const credenciais = Storage.getCredenciais();

  if (!credenciais) {
    config.headers.Authorization = '';
    return;
  }

  config.headers.Authorization = `Bearer ${credenciais.token_Acesso}`;
}

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Situacao extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = '';
  }

  get json() {}

  set json(dados) {}
}

export { Situacao };

<template>
  <t-form title="Perfil do usuário">
    <t-container>
      <t-card>
        <t-upper-edit v-model="usuario.descricao" label="NOME" disabled />
        <t-upper-edit v-model="usuario.email" label="EMAIL" disabled />
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';

export default {
  components: { TForm, TCard, TContainer, TUpperEdit },

  props: {
    usuario: {
      type: Object,
    },
  },
};
</script>

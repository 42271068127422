<template>
  <t-container>
    <t-card>
      <search-edit
        :controller="consultaOrigemAtendimento"
        :model="model.origemAtendimento"
        :disabled="modoVisualizacao"
        v-show="!sessao.isAcessoCliente()"
        label="ORIGEM"
      />

      <search-edit
        :controller="consultaTipoAtendimento"
        :model="model.tipoAtendimento"
        :disabled="modoVisualizacao"
        v-show="!sessao.isAcessoCliente()"
        label="TIPO"
      />

      <search-edit
        :controller="consultaMotivo"
        :disabled="modoVisualizacao"
        :model="model.motivo"
        :formConsultaAlternativo="formConsultaMotivos"
        label="MOTIVO"
      />

      <search-edit
        :controller="consultaClassificacao"
        :disabled="modoVisualizacao"
        :model="model.classificacao"
        label="CLASSIFICAÇÃO"
      />
    </t-card>
  </t-container>
</template>

<script>
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { ConsultaOrigemAtendimento } from '@/proxy-api/src/services/classes/OrigemAtendimento/ConsultaOrigemAtendimento';
import { ConsultaTipoAtendimento } from '@/proxy-api/src/services/classes/TipoAtendimento/ConsultaTipoAtendimento';
import { ConsultaMotivo } from '@/proxy-api/src/services/classes/Motivo/ConsultaMotivo';
import { ConsultaClassificacao } from '@/proxy-api/src/services/classes/Classificacao/ConsultaClassificacao';
import FormConsultaMotivos from '@/components/form/FormConsultaArvore';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';

import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';

export default {
  components: { TCard, TContainer, SearchEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  async mounted() {
    this.inicializarFiltros();
  },

  data: function () {
    return {
      consultaOrigemAtendimento: new ConsultaOrigemAtendimento(),
      consultaTipoAtendimento: new ConsultaTipoAtendimento(),
      consultaMotivo: new ConsultaMotivo(),
      consultaClassificacao: new ConsultaClassificacao(),
      sessao: Sessao,
      filtroTipoDisponivelParaSite: new FiltroConsulta(
        'atendimentoInternoTat',
        'S',
        'IGUAL',
      ),
    };
  },

  methods: {
    inicializarFiltros() {
      this.configurarFiltroDisponivelParaSite();
    },

    configurarFiltroDisponivelParaSite() {
      this.consultaTipoAtendimento.filtrosFixo.push(
        this.filtroTipoDisponivelParaSite,
      );
    },
  },

  computed: {
    formConsultaMotivos() {
      return FormConsultaMotivos;
    },
  },
};
</script>

<style></style>

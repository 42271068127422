import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Origem extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = '';
  }
}

export { Origem };

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Classificacao extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.codigoClassificacao;
    this.descricao = dados.descricaoClassificacao;
    this.disponivelParaCliente = dados.disponivelclienteClassificacao;
    this.ativo = dados.ativoClassificacao;
  }
}

export { Classificacao };

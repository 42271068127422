import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaAtendimentoPresencialSolicitacao extends ConsultaController {
  constructor() {
    super('atd_solicitado', 'Atendimentos Presenciais', 'autoincTds');

    this.ordenacao = 'autoincTds';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('autoincTds', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('clienteTds', 'Código do Cliente', TipoFiltro.Inteiro);
    this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.autoincTds;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `
    		 <div class="mt-2">${registro.descricaoTat}</div>
    		 <p class="text-caption font-weight-light">${registro.descricaoClassificacao} | ${registro.nomeSis}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaAtendimentoPresencialSolicitacao };

import { TMenuItem } from './TMenuItem';

class NovoAtendimentoPresencial extends TMenuItem {
  static import() {
    return import(
      '@/views/atendimentoPresencial/form/FormAtendimentoPresencial'
    );
  }

  static aposAbrir(instancia) {
    instancia.incluir();
  }
}

export { NovoAtendimentoPresencial };

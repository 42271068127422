import { Cadastro } from '../../bases/Cadastros/Cadastro';

class FuncionarioProjeto extends Cadastro {
  constructor(persistente) {
    super();
  }

  set json(dados) {
    this.codigo = dados.funcionarioPrf;
    this.nome = dados.nomeAtn;
    this.papel = dados.papelPrf;
    this.descricaoPapel = dados.descricaoTippap;
    this.avaliacao = dados.avaliacaoPrf;
    this.pontos = dados.pontosPrf;
  }
}

export { FuncionarioProjeto };

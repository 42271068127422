import { Controller } from '../../bases/Controllers/Controller';
import { api } from '../../api';

class EventoController extends Controller {
  constructor() {
    super('evento');
  }

  async convite(dados) {
    if (!dados.Codigo) throw new Error('Codigo não informado');
    if (!dados.TipoPessoa) throw new Error('Tipo não informado');
    if (!dados.Situacao) throw new Error('Situacao não informado');

    try {
      const { data } = await api.post('evento/convite', dados);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async inscrever(dados) {
    if (!dados.Pessoa) this.validarInscricao(dados);

    try {
      const { data } = await api.post('evento/inscrever', dados);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async validarAcesso(dados) {
    if (!dados.Codigo) throw new Error('Codigo não informado');
    if (!dados.TipoPessoa) throw new Error('Tipo não informado');
    if (!dados.Pessoa) throw new Error('Situacao não informado');

    api.defaults.headers.Codigo = dados.Codigo;
    api.defaults.headers.TipoPessoa = dados.TipoPessoa;
    api.defaults.headers.Pessoa = dados.Pessoa;

    try {
      const { data } = await api.get('evento/acesso');
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  validarInscricao(dados) {
    if (!dados.Nome) throw new Error('Nome não informado');
    if (!dados.Telefone) throw new Error('Telefone não informado');
    if (!dados.Email) throw new Error('E-mail não informado');
    if (!dados.Cpf) throw new Error('Cpf não informado');
    if (!dados.Interesse) throw new Error('Interesse não informado');
  }

  async verificaInscricao(dados) {
    api.defaults.headers.Codigo = dados.codigoEvento;
    api.defaults.headers.Pessoa = dados.codigoPessoa;
    api.defaults.headers.TipoPessoa = dados.tipoPessoa;

    try {
      const { data } = await api.get('evento/inscrito');
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { EventoController };

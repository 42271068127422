import { api } from '../../api';
import { Controller } from '../../bases/Controllers/Controller';
import { ValidarValoresUtil } from '@/proxy-api/src/utils/ValidarValoresUtil';

class AtendimentoPresencialController extends Controller {
  constructor() {
    super('atend_externo');
  }

  async validar(registro) {
    if (ValidarValoresUtil.maiorQueZero(registro.cliente.codigo))
      throw new Error('<b>Cliente</b> não preenchido');

    if (ValidarValoresUtil.maiorQueZero(registro.contato.codigo))
      throw new Error('<b>Contato</b> não preenchido');

    if (!registro.dataDaVisita)
      throw new Error('<b>Data da visita</b> não preenchida');

    if (!registro.horarioDeEntrada)
      throw new Error('<b>Horário de entrada</b> não preenchido');

    if (!registro.horarioDeAlmocoEntrada && registro.horarioDeAlmocoSaida)
      throw new Error('<b>Horário de início para o almoço</b> não preenchido');

    if (registro.horarioDeAlmocoEntrada && !registro.horarioDeAlmocoSaida)
      throw new Error('<b>Horário de retorno do almoço</b> não preenchido');

    if (!registro.objetivoDaVisita)
      throw new Error('<b>Objetivo da visita</b> não preenchida');

    if (!registro.descricaoDoAtendimento)
      throw new Error('<b>Descrição do atendimento</b> não preenchida');
  }

  async relatorio(codigoDoArquivo) {
    try {
      if (!codigoDoArquivo) throw new Error('Arquivo indisponível');
      const url = `atend_externo/relatorio?Codigo=${codigoDoArquivo}`;

      const { data } = await api.get(url, {
        responseType: 'arraybuffer',
      });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { AtendimentoPresencialController };

import { Enumeration } from '@jslibrary/enumeration';

class LocalAtendimento extends Enumeration {
  constructor() {
    super();

    this.add(0, 'CLIENTE', 'Cliente');
    this.add(1, 'TEKSYSTEM', 'Tek-System');
  }
}

export default new LocalAtendimento();

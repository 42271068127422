import { TMenuItem } from './TMenuItem';

class ConsultaAtendimentoPresencial extends TMenuItem {
  static import() {
    return import(
      '@/views/atendimentoPresencial/consulta/FormConsultaAtendimentoPresencial'
    );
  }
}

export { ConsultaAtendimentoPresencial };

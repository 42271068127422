<template>
  <v-menu v-if="situacao" class="my-0 py-0">
    <template v-slot:activator="{ attrs }">
      <v-chip v-bind="attrs" outlined>
        {{ situacao }}
      </v-chip>
    </template>
  </v-menu>
</template>

<script>
import { api } from '@/proxy-api/src/services/api';

export default {
  data: function () {
    return {
      situacao: '',
    };
  },

  props: {
    updateComponent: Number,
  },

  watch: {
    async updateComponent() {
      await this.buscarSituacaoAtendente();
    },
  },

  methods: {
    async buscarSituacaoAtendente() {
      try {
        const { data } = await api.get(
          '/atendente/ausentesEmpresa?ApenasProprio=true',
        );
        this.situacao = data[0].descricaoOct;
      } catch (error) {
        this.situacao = '';
      }
    },
  },

  async mounted() {
    await this.buscarSituacaoAtendente();
  },
};
</script>

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class OrigemAtendimento extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.codigoOtd;
    this.descricao = dados.descricaoOtd;
    this.ativo = dados.ativoOtd === 'S' ? true : false;
  }
}

export { OrigemAtendimento };

import { TMenuItem } from './TMenuItem';

class NovoAtendimento extends TMenuItem {
  static import() {
    return import('@/views/atendimento/form/FormAtendimento');
  }

  static aposAbrir(instancia) {
    instancia.incluir();
  }
}

export { NovoAtendimento };

<template>
  <t-container>
    <t-card>
      <v-row v-if="model.codigo > 0" class="mb-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-chip outlined label :color="corStatus" class="mr-2">
            {{ model.dataInicioAtendimento }}
          </v-chip>
          <v-chip label :color="corStatus">{{
            model.situacao.descricao
          }}</v-chip>
        </v-col>
      </v-row>

      <search-edit
        :controller="consultaCliente"
        v-show="sessao.isAcessoFuncionario()"
        :disabled="modoVisualizacao"
        :model="model.cliente"
        label="CLIENTE"
      />

      <search-edit
        :controller="consultaContato"
        :disabled="
          modoVisualizacao ||
          Number(model.cliente.codigo) === 0 ||
          sessao.isAcessoContato()
        "
        :form-cadastro="formContato"
        @antesDeAbrirCadastro="antesDeAbrirCadastro"
        :model="model.contato"
        label="CONTATO"
      />
    </t-card>
  </t-container>
</template>

<script>
import { Contato } from '@/proxy-api/src/services/classes/Contato/Contato';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';

import { ConsultaCliente } from '@/proxy-api/src/services/classes/Cliente/ConsultaCliente';
import { ConsultaContato } from '@/proxy-api/src/services/classes/Contato/ConsultaContato';
import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';

import FormContato from '@/views/contato/form/FormContato';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TUpperEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    'model.cliente.codigo': function (value, oldValue) {
      if (Number(value) === Number(oldValue)) {
        return;
      }

      this.filtroCliente.valor = value;

      this.model.contato = new Contato();
      this.model.contato.setCliente(this.model.cliente);
    },
  },

  computed: {
    corStatus() {
      switch (this.model.situacao.codigo) {
        case 1:
          return 'success';
        default:
          return 'primary';
      }
    },
  },

  async mounted() {
    this.inicializarFiltros();
  },

  data: function () {
    return {
      consultaContato: new ConsultaContato(),
      consultaCliente: new ConsultaCliente(),
      formContato: FormContato,
      filtroCliente: new FiltroConsulta('clienteClc'),
      sessao: Sessao,
    };
  },

  methods: {
    inicializarFiltros() {
      this.configurarFiltroContato();
    },

    antesDeAbrirCadastro(instancia) {
      instancia.model.cliente.codigo = this.model.cliente.codigo;
      instancia.model.cliente.descricao = this.model.cliente.descricao;
    },

    configurarFiltroContato() {
      this.consultaContato.filtrosFixo.push(this.filtroCliente);

      this.verificarFiltroCliente();
    },

    verificarFiltroCliente() {
      if (this.model.cliente.codigo > 0) {
        this.filtroCliente.valor = this.model.cliente.codigo;
        return;
      }

      this.filtroCliente.valor = this.sessao.usuario.cliente.codigo;
    },
  },
};
</script>

<template>
  <v-menu rounded="b-xl" :close-on-content-click="false">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon plain>
        <v-badge
          :content="notificacoes.length"
          :value="notificacoes.length"
          color="accent"
          overlap
        >
          <v-icon>mdi-bell</v-icon>
        </v-badge>
      </v-btn>
    </template>
    <v-card width="300 px-0 mx-0">
      <v-card-title class="accent white--text px-0 mx-0">
        <v-col class="pa-0 ma-0 pl-4">
          <p class="text-caption font-weight-bold mb-0">NOTIFICAÇÕES</p>
          <span class="text-caption mb-0">{{ sessao.usuario.email }}</span>
        </v-col>
      </v-card-title>
      <v-card-text
        class="pa-0 ma-0"
        style="max-height: 450px; overflow-y: auto"
      >
        <v-list class="pa-0" width="300">
          <v-list-item
            v-if="notificacoes.length === 0"
            class="mt-3 d-flex justify-center align-center"
          >
            <div
              class="d-flex flex-column justify-center align-center text-center"
            >
              <v-icon class="pb-0">mdi-bell-remove</v-icon>
              <v-list-item-content class="text-caption gray-text">
                Nenhuma notificação para mostrar
              </v-list-item-content>
            </div>
          </v-list-item>

          <v-list-item
            v-else
            v-for="notificacao in notificacoes"
            :key="notificacao.codigo"
          >
            <div class="d-flex flex-column mt-3">
              <div class="d-flex">
                <div>
                  <div class="d-flex justify-start">
                    <v-list-item-title
                      class="ma-0 pa-0 text-caption text-gray font-weight-bold"
                      >{{ notificacao.dataHora }}</v-list-item-title
                    >
                  </div>
                  <v-list-item-content
                    class="text-caption text-gray ma-0 pa-0 mb-3"
                    style="width: 228px"
                  >
                    {{ notificacao.descricao }}
                  </v-list-item-content>
                </div>
                <v-list-item-action>
                  <v-btn
                    icon
                    rounded
                    plain
                    @click="marcarComoLida(notificacao)"
                  >
                    <v-icon small dense>mdi-check</v-icon>
                  </v-btn>
                </v-list-item-action>
              </div>
              <v-divider />
            </div>
          </v-list-item>
        </v-list>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { NotificacaoController } from '@/proxy-api/src/services/classes/Notificacao/NotificacaoController';

export default {
  props: {
    sessao: {
      type: Function,
    },
  },

  data: function () {
    return {
      controller: new NotificacaoController(),
      notificacoes: [],
    };
  },

  methods: {
    async marcarComoLida(notificacao) {
      const resposta = await this.controller.marcarComoLida(notificacao);
      if (resposta) {
        this.notificacoes = this.notificacoes.filter(
          registro => registro.codigo !== notificacao.codigo,
        );
      }
    },
  },

  async mounted() {
    this.notificacoes = await this.controller.buscar();
  },
};
</script>

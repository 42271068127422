import { api } from '../../api';

class AtendenteController {
  getUserConfig(codigoAtendente) {
    // eslint-disable-next-line no-unused-vars
    return new Promise((resolve, reject) => {
      api.get(`atendente/${codigoAtendente}`)
        .then(response => {
          if(response.status === 200) {
            return resolve(response.data);
          }
          return reject(new Error(response.data));
        })
        .catch(error => reject(error));
    });
  }
}

export { AtendenteController }

import moment from 'moment';

class DataUtils {
  static formatarDataParaDiaMesAno(data) {
    if (!data) {
      return '';
    }
    return new Date(data).toLocaleDateString('pt-br', {
      timeZone: 'UTC',
    });
    // return moment(data).utc().format('DD/MM/YYYY');
  }

  static formatarDataParaDiaMesAnoHoraMinuto(data) {
    if (!data) return '';
    return new Date(data).toLocaleString('pt-br', { timeZone: 'UTC' });
  }

  static formatarDataParaAnoMesDia(data) {
    if (!data) {
      return '';
    }

    return moment(data, 'DD/MM/YYYY').format('YYYY-MM-DD');
  }

  static formatarTimeZoneParaHoraMinuto(hora) {
    if (!hora) {
      return '';
    }

    return moment(hora, 'hh:mm').format('HH:mm');
  }

  static formatarTimeZoneParaDataHora(data) {
    if (!data) {
      return '';
    }

    return moment(data, 'YYYY-MM-DD hh:mm:ss', 'pt-Br').format('LLL');
  }

  static formatarDataParaTimeZone(data) {
    if (!data) {
      return '';
    }

    return moment(data, 'DD/MM/YYYY').toISOString();
  }

  static maiorQueDataAtual(data) {
    if (!data) {
      return false;
    }

    return moment(data, 'DD/MM/YYYY').isAfter(moment());
  }

  static menorQueDataAtual(data) {
    if (!data) {
      return false;
    }

    return moment(data, 'DD/MM/YYYY').isBefore(moment());
  }

  static somaDias(data, dias) {
    if (!data) return;
    return moment(data, 'DD/MM/YYYY').add(dias, 'days');
  }
}

export { DataUtils };

<template>
  <form-consulta
    :title="controller.titulo"
    :processamento="processamento"
    :sideSheet="sideSheet"
    :sideSheetOpen.sync="sideSheetOpen"
    :sideSheetBadge="filtrosFixos.length.toString()"
    sideSheetIcon="mdi-pin"
    sideSheetMenuText="Filtros Fixos"
  >
    <v-tooltip v-if="temFormCadastro" left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="incluir"
          v-bind="attrs"
          v-on="on"
          color="accent"
          id="lateral"
          dark
          absolute
          bottom
          right
          fab
          fixed
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Novo registro</span>
    </v-tooltip>

    <filtros
      :controller="controller"
      @aplicar="aplicarFiltros"
      @filtrosFixosChange="onFiltrosFixosChange"
    />
    <template v-slot:side-sheet>
      <filtro-fixo :filtrosFixos="filtrosFixos" :controller="controller" />
    </template>
  </form-consulta>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

import FormConsulta from '@/components/form/FormConsulta';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import Resultado from './Resultado';
import Filtros from './Filtros';
import FiltroFixo from './FiltroFixo';

export default {
  components: { FormConsulta, Filtros, FiltroFixo },

  props: {
    controller: Object,

    model: Object,

    formCadastro: {
      required: false,
    },

    fechaAposSelecionar: {
      type: Boolean,
    },
  },

  computed: {
    temFormCadastro() {
      if (this.formCadastro) {
        return true;
      }
      return false;
    },
    sideSheet() {
      if (this.filtrosFixos.length > 0) {
        return true;
      }

      return false;
    },
  },

  data: function () {
    return {
      sideSheetOpen: !this.$vuetify.breakpoint.xsOnly,
      processamento: '',
      fabSpeedDial: [],
      filtrosAplicados: [],
      filtrosFixos: [],
    };
  },

  methods: {
    selecionado(registro) {
      this.$emit('selecionado', registro);
    },

    onFiltrosFixosChange(filtrosFixos) {
      this.filtrosFixos = filtrosFixos;
    },

    abrirResultadoDaPesquisa() {
      const instancia = TFormUtil.instanciar(Resultado);

      instancia.controller = this.controller;
      instancia.processamento = this.processamento;

      instancia.$on('scroll-end', this.buscarRegistros);

      instancia.$on('redefinir', () => {
        TFormUtil.fechar(instancia);
      });

      instancia.$on('select', registro => {
        this.selecionado(registro);

        if (this.fechaAposSelecionar) {
          TFormUtil.fechar(instancia);
        }
      });

      TFormUtil.abrir(instancia);
    },

    async aplicarFiltros(filtros) {
      this.filtrosAplicados = this.corrigirFiltros(filtros);
      await this.buscarRegistros(true);
    },

    async buscarRegistros(inicializa) {
      this.processamento = 'Buscando registros...';

      try {
        await this.controller.buscarRegistros(
          this.filtrosAplicados,
          inicializa,
        );

        if (inicializa) {
          this.abrirResultadoDaPesquisa();
        }
      } catch (error) {
        TMessageDialog.info().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },

    corrigirFiltros(filtros) {
      let filtrosCorrigidos = [];
      filtrosCorrigidos = filtros.map(filtro => {
        if (filtro.campo === 'dataInicioAtd') {
          filtro.valor = filtro.valor.split('/').reverse().join('-');
        }
        return filtro;
      });
      return filtrosCorrigidos;
    },

    incluir() {
      const instancia = TFormUtil.instanciar(this.formCadastro);

      this.$emit('antesDeAbrirCadastro', instancia);
      TFormUtil.abrir(instancia);

      instancia.$on('aposGravar', cadastro => {
        this.$emit('selecionado', cadastro.json);

        TFormUtil.fechar(instancia);
      });

      instancia.incluir();
    },
  },
};
</script>

<style>
#lateral {
  bottom: 0;
  position: absolute;
  margin: 0 0 16px 16px;
}
</style>

<template>
  <v-zoomer style="margin-top: 10px; width: 100vw; height: calc(100vh - 64px)">
    <img
      :src="`data:image;base64, ${src}`"
      :style="`object-fit: contain; width: 100%; height: 100%; cursor: ${cursor}`"
      @mousedown="cursor = 'grabbing'"
      @mouseup="cursor = 'grab'"
    />
  </v-zoomer>
</template>

<script>
import VueZoomer from 'vue-zoomer';

export default {
  components: {
    VZoomer: VueZoomer.Zoomer,
  },

  data() {
    return {
      cursor: 'grab',
    };
  },

  props: {
    src: {
      type: String,
      required: true,
    },
  },
};
</script>

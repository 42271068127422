<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :loading="loading"
    loading-text="Carregando arquivos..."
    hide-default-footer
    no-data-text="Nenhum arquivo adicionado ainda"
  >
    <template v-slot:item.actions="{ item }">
      <v-btn
        small
        color="primary"
        @click="$emit('downloadArquivo', item)"
        class="mr-1"
      >
        <v-icon>mdi-download</v-icon>
      </v-btn>
    </template>
  </v-data-table>
</template>

<script>
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  data() {
    return {
      headers: [
        {
          text: 'Descrição do arquivo',
          value: 'descricao',
          sortable: false,
        },
        {
          text: 'Tipo',
          sortable: false,
          value: 'tipo',
        },
        {
          text: 'Ação',
          value: 'actions',
          sortable: false,
          align: 'end',
        },
      ],
    };
  },
  props: {
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  methods: {
    removerArquivo(arquivo) {
      TMessageDialog.question()
        .message('Tem certeza que deseja remover o arquivo?')
        .okCallback(() => {
          this.$emit('removerArquivo', arquivo);
        })
        .show();
    },
  },
};
</script>

<style></style>

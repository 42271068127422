import { api } from '../../api';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
class AutenticacaoController {
  async acessar(autenticacao) {
    this.validaAcesso(autenticacao);
    try {
      const response = await api.post('auth', {
        Username: autenticacao.usuario,
        Password: autenticacao.senha.toUpperCase(),
      });

      if (response.status === 201) {
        api.defaults.headers.Authorization = `Bearer ${response.data.token_Acesso}`;
        Storage.defineTipoStorage(autenticacao.permanecerConectado);
        Storage.setCredenciais(response.data);
        await this.buscarSessao();
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async deslogar(sessao) {
    const { id } = sessao;

    try {
      await api.delete(`sections/${id}`);
    } catch (e) {
      throw new Error(e.message);
    } finally {
      Storage.removerStorage();
    }
  }

  async buscarSessao() {
    try {
      const { data } = await api.get('sections/current');

      Sessao.json = data;
      Storage.setSessao(data);
    } catch (error) {
      Storage.removerStorage();
      throw new Error(error.message);
    }
  }

  async recuperarAcesso(usuarioEmail) {
    try {
      const { data } = await api.post('auth/recoverpassword', {
        Username: usuarioEmail,
      });

      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async verificaSessao() {
    if (Storage.getSessao()) {
      try {
        await this.buscarSessao();
        return true;
      } catch {
        return false;
      }
    }
    return false;
  }

  async informacoesUsuario(credenciais) {
    if (!credenciais.cliente) throw new Error('Cliente não informado');
    if (!credenciais.email && !credenciais.documento)
      throw new Error(
        'Nenhum e-mail ou documento associoado ao seu usuário no sistema, cadastre um deles e tente novamente.',
      );

    api.defaults.headers.Cliente = credenciais.cliente;
    api.defaults.headers.Email = credenciais.email;
    api.defaults.headers.Documento = credenciais.documento;

    try {
      const response = await api.get('/auth/username');
      return response;
    } catch (error) {
      throw error;
    }
  }

  async alterarSenha(Password, NewPassword) {
    Password = Password.toUpperCase();
    NewPassword = NewPassword.toUpperCase();

    if (!Password) throw new Error('Senha atual não preenchida');
    if (!NewPassword) throw new Error('Nova senha não preenchida');

    try {
      const { data } = await api.post('/auth/changepassword', {
        Password,
        NewPassword,
      });
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async cadastrarSenha(NewPassword) {
    NewPassword = NewPassword.toUpperCase();

    if (!NewPassword) throw new Error('Senha não preenchida');

    try {
      const { data } = await api.post('/auth/changepassword', {
        Password: '',
        NewPassword,
      });
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  validaAcesso(usuario) {
    if (!usuario.usuario)
      throw new Error('Por favor digite seu nome de usuário');
    if (!usuario.senha) throw new Error('Por favor digite sua senha');
  }

  async acessarSemSenha(usuario) {
    if (!usuario) throw new Error('Usuário inválido para login sem senha');

    try {
      const response = await api.post('auth', {
        Username: usuario,
        Password: '',
      });

      if (response.status === 201) {
        api.defaults.headers.Authorization = `Bearer ${response.data.token_Acesso}`;
        await this.buscarSessao();
        Storage.setCredenciais(response.data);
      }
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { AutenticacaoController };

import { Controller } from '../../bases/Controllers/Controller';
import { api } from '../../api';

export class ProcessamentoController extends Controller {
  constructor() {
    super();
  }

  async rodarProcessamento(nomeDaUnit) {
    try {
      const resposta = api.post(`/interpreterEx?unit=${nomeDaUnit}`);
      return resposta;
    } catch (error) {
      throw new Error(error);
    }
  }
}

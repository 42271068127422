<template>
  <v-card class="scrollable-container pa-2 -1">
    <v-row justify="center" class="my-1">
      <v-card-title class="text-center">Operações Abertas</v-card-title>
    </v-row>

    <status :model="model" @selecionado="filtrarPendencias" :filtro="filtro" />
    <pendencias :model="model" :filtro="filtro" />
  </v-card>
</template>

<script>
import Status from './Status.vue';
import Pendencias from './Pendencias.vue';

export default {
  components: { Status, Pendencias },

  data() {
    return {
      filtro: '',
    };
  },

  props: {
    model: {
      type: Object,
    },
  },

  methods: {
    filtrarPendencias(pendencia) {
      if (pendencia === null) this.filtro = '';
      else this.filtro = pendencia.sigla;
    },
  },
};
</script>

<style scoped>
.scrollable-container {
  height: calc(100vh - 64px);
  overflow-y: auto;
  overflow-x: hidden;
}
</style>

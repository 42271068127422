import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class Inscricao extends Cadastro {
  constructor(persistente) {
    super();
    this.nome = '';
    this.telefone = '';
    this.email = '';
    this.cpf = '';
    this.interesse = '';
    this.maiorDeIdade = false;
    this.expiracao = '';
    this.titulo = '';
    this.descricao = '';
    this.maiorDeIdade = false;
    this.tipo = '';
  }

  preencheTipoInscricao(sigla) {
    switch (sigla) {
      case 'EVE':
        return 'Evento';
    }
  }

  set json(credenciaisBase64) {
    try {
      const credenciais = JSON.parse(Buffer.from(credenciaisBase64, 'base64'));
      this.codigo = credenciais.codigo;
      this.expiracao = DataUtils.formatarDataParaDiaMesAno(
        credenciais.expiracao,
      );
      this.titulo = credenciais.titulo;
      this.descricao = credenciais.descricao;
      this.tipo = this.preencheTipoInscricao(credenciais.tipo);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  get json() {
    return {
      Codigo: this.codigo.toString(),
      Nome: this.nome,
      Telefone: this.telefone,
      Email: this.email,
      Cpf: this.cpf,
      Interesse: this.interesse,
    };
  }
}

export { Inscricao };

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaSetor extends ConsultaController {
  constructor() {
    super('setor', 'Setor', 'codigoSetor');

    this.addFiltro('codigoSetor', 'CÓDIGO', TipoFiltro.Inteiro);
    this.addFiltro('descricaoSetor', 'DESCRIÇÃO', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoSetor;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.descricaoSetor;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.descricaoSetor.substr(0, 1) + '</h1>';
  }
}

export { ConsultaSetor };

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

Sessao.json = Storage.getSessao();

class ConsultaProjeto extends ConsultaController {
  constructor() {
    super('projeto', 'Projeto', 'codigoProj');

    this.ordenacao = 'codigoProj';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoProj', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('dtinicioProj', 'Data início', TipoFiltro.Data);

    if (!Sessao.isAcessoCliente()) {
      this.addFiltro('clienteProj', 'Código do Cliente', TipoFiltro.Inteiro);
      this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String, true);
    }
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoProj;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `<div class="mt-2">${registro.descricaoProj}</div>
    		 <p class="text-caption font-weight-light">${DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
           registro.dtinicioProj,
         )}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaProjeto };

<template>
  <form-cadastro
    :title="`Atendimento Presencial ${model.codigo ? model.codigo : ''}`"
    @stateChanged="onStateChanged"
    @aposGravar="aposGravar"
    @aposFechar="aposFechar"
    @aposExcluir="aposExcluir"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Visita </v-tab>
        <v-tab :disabled="Number(model.cliente.codigo) === 0">
          Solicitação
        </v-tab>
        <v-tab> Objetivo da visita </v-tab>
        <v-tab> Descrição do atendimento </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
      <v-tab-item disabled>
        <aba-visita :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
      <v-tab-item>
        <aba-solicitacao :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
      <v-tab-item>
        <aba-objetivo-da-visita
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
      <v-tab-item>
        <aba-descricao-do-atendimento
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { AtendimentoPresencial } from '@/proxy-api/src/services/classes/AtendimentoPresencial/AtendimentoPresencial';
import { ConsultaAtendimentoPresencial } from '@/proxy-api/src/services/classes/AtendimentoPresencial/ConsultaAtendimentoPresencial';
import { AtendimentoPresencialController } from '@/proxy-api/src/services/classes/AtendimentoPresencial/AtendimentoPresencialController';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaVisita from './components/AbaVisita';
import AbaSolicitacao from './components/AbaSolicitacao';
import AbaObjetivoDaVisita from './components/AbaObjetivoDaVisita';
import AbaDescricaoDoAtendimento from './components/AbaDescricaoDoAtendimento';
import ExibicaoRelatorio from '@/components/ExibicaoRelatorio';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
    AbaIdentificacao,
    AbaVisita,
    AbaSolicitacao,
    AbaObjetivoDaVisita,
    AbaDescricaoDoAtendimento,
  },

  mounted() {
    this.verificarSePermiteEdicao();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new AtendimentoPresencial(true),
      controller: new AtendimentoPresencialController(),
      consulta: new ConsultaAtendimentoPresencial(),
      abaSelecionada: 0,
      itensDeAcao: [
        {
          icon: 'mdi-printer',
          text: 'Imprimir relátorio do atendimento',
          available: true,
          callback: this.imprimir,
        },
      ],
    };
  },

  methods: {
    onStateChanged(emEdicao) {
      this.modoVisualizacao = !emEdicao;
    },

    incluir() {
      this.$refs.form.incluir();
    },

    async aposGravar(registro) {
      this.abaSelecionada = 0;
      //Faz nova consulta após gravar pois POST e PUT não retornam todos os campos
      this.model.solicitacoesExcluidas = [];
      await this.consulta.buscarRegistro(registro.autoincSai);
      this.model.json = this.consulta.registros[0];
      this.$emit('aposGravar', this.model.json);
      this.verificarSePermiteEdicao();
    },

    aposExcluir(registro) {
      this.$emit('aposExcluir', registro);
    },

    verificarSePermiteEdicao() {
      if (this.model.horarioDeSaida === '') {
        return;
      }

      this.$refs.form.actionItems.forEach(action => {
        if (action.text === 'Editar') {
          action.available = false;
        }
      });

      this.$refs.form.actionItems = [
        ...this.itensDeAcao,
        ...this.$refs.form.actionItems,
      ];
    },

    async imprimir() {
      this.$refs.form.processamento = 'Carrendo relatório...';

      try {
        const fileURL = await this.controller.relatorio(this.model.codigo);

        const instancia = TFormUtil.instanciar(ExibicaoRelatorio);

        instancia.url = fileURL;

        TFormUtil.abrir(instancia);
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.$refs.form.processamento = '';
      }
    },

    aposFechar() {
      this.$emit('aposFechar');
    },
  },
};
</script>

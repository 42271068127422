import { Cadastro } from '../../bases/Cadastros/Cadastro';

export class Atendente extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = ''
    this.situacao = ''
    this.codigoEad = ''
  }
}

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaAtividadesProjeto extends ConsultaController {
  constructor() {
    super('projeto_realizacao', 'Atividades do Projeto', 'codigoPrr');

    this.ordenacao = 'codigoPrr';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoPrr', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('clientePrr', 'Código do Cliente', TipoFiltro.Inteiro);
    this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoPrr;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
			return `
    		 <div class="mt-2">${ registro.razaoCli }</div>
    		 <p class="text-caption font-weight-light">Pessoa: ${ registro.nomeClc }</p>`
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaAtividadesProjeto };

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Pendencia } from './Pendencia';
import { Status } from './Status';

class OperacoesAbertas extends Cadastro {
  constructor(persistente) {
    super();

    this.pendencias = [];
    this.status = [];
  }

  set json(dados) {
    this.pendencias = dados.pendencias.map(registro => {
      const pendencia = new Pendencia();
      pendencia.json = registro;
      return pendencia;
    });

    this.status = dados.status.map(registro => {
      const status = new Status();
      status.json = registro;
      return status;
    });
  }

  get json() {}
}

export { OperacoesAbertas };

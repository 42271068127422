import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaCliente extends ConsultaController {
  constructor() {
    super('clientes', 'Cliente', 'codigoCli');

    this.ordenacao = 'razaoCli';

    this.addFiltro('codigoCli', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String, true);
    this.addFiltro('fantasiaCli', 'Fantasia', TipoFiltro.String);
    this.addFiltro('cnpjCli', 'CNPJ', TipoFiltro.CNPJ);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoCli;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.razaoCli;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.razaoCli.substr(0, 1) + '</h1>';
  }
}

export { ConsultaCliente };

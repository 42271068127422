import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';
import { api } from '../../api';

class ConsultaMotivo extends ConsultaController {
  constructor() {
    super('problemas', 'Motivos', 'codigoPro');

    this.ordenacao = 'nomePro';

    this.addFiltroFixo('ativoPro', 'IGUAL', 'S');
    this.addFiltroFixo('cabecalhoPro', 'IGUAL', 'N');
    this.addFiltro('codigoPro', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('nomePro', 'Descrição', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoPro;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.nomePro;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.nomePro.substr(0, 1) + '</h1>';
  }

  async montaArvoreMotivos() {
    const results = [];
    const motivos = [];

    try {
      /** A primeira interação irá carregar a página 1 e trazer a quantidade total de registros */
      const { data } = await api.get('problemas', {
        params: {
          filtros: JSON.stringify([
            { campo: 'nomePro', operador: 'PREENCHIDO', valor: '' },
            { campo: 'codigoPro', operador: 'MAIOR', valor: 0 },
            { campo: 'ativoPro', operador: 'IGUAL', valor: 'S' },
          ]),
          pagina: 1,
          limite: 50,
          ordenacao: 'nivelPro',
          sentido: 'ASC',
        },
      });

      motivos.push(...data.registros);

      /**
       * Pega a quaantidade total de registros
       * */
      const totalInteracoes = Math.ceil(
        Number(data.paginacao.totalRegistros) / 50,
      );

      if (totalInteracoes > 0) {
        /**
         * Efetua a paginação desconsiderenando a primeira requisição feita para o servidor.
         * */
        for (let i = 0; i < 1; i++) {
          let { data } = await api.get('problemas', {
            params: {
              filtros: JSON.stringify([
                { campo: 'nomePro', operador: 'PREENCHIDO', valor: '' },
                { campo: 'codigoPro', operador: 'MAIOR', valor: 0 },
                { campo: 'ativoPro', operador: 'IGUAL', valor: 'S' },
              ]),
              pagina: i + 2,
              limite: 50,
              ordenacao: 'nivelPro',
              sentido: 'ASC',
            },
          });

          motivos.push(...data.registros);
        }
      }

      let getFirstFather = motivos.filter(motivo => {
        if (motivo.nivelPro.split('.').length == 1) {
          let name = motivo.nomePro;

          delete motivo.nomePro;

          motivo.name = name;

          return motivo.nivelPro.split('.').length == 1;
        }
      });

      getFirstFather.forEach(item => {
        let children = motivos.filter(motivo => {
          if (
            motivo.nivelPro.split('.').length == 2 &&
            motivo.nivelPro.split('.')[0] == item.nivelPro.split('.')[0]
          ) {
            let name = motivo.nomePro;

            delete motivo.nomePro;

            motivo.name = name;

            return motivo;
          }
        });

        if (children.length > 0) {
          item.children = children;

          item.children.map(item2 => {
            let children = motivos.filter(motivo => {
              if (
                motivo.nivelPro.split('.').length == 3 &&
                motivo.nivelPro.split('.')[1] == item2.nivelPro.split('.')[1] &&
                motivo.nivelPro.split('.')[0] == item2.nivelPro.split('.')[0]
              ) {
                if (motivo?.nomePro) {
                  let name = motivo.nomePro;

                  delete motivo.nomePro;

                  motivo.name = name;

                  return motivo;
                }

                return motivo;
              }
            });

            if (children.length > 0) {
              item2.children = children;

              item2.children.map(item3 => {
                let children = motivos.filter(motivo => {
                  if (
                    motivo.nivelPro.split('.').length == 4 &&
                    motivo.nivelPro.split('.')[0] ==
                      item3.nivelPro.split('.')[0] &&
                    motivo.nivelPro.split('.')[1] ==
                      item3.nivelPro.split('.')[1] &&
                    motivo.nivelPro.split('.')[2] ==
                      item3.nivelPro.split('.')[2]
                  ) {
                    if (motivo?.nomePro) {
                      let name = motivo.nomePro;

                      delete motivo.nomePro;

                      motivo.name = name;

                      return motivo;
                    }

                    return motivo;
                  }
                });

                if (children.length > 0) {
                  item3.children = children;
                }
              });
            }
          });
        }
        item.children = children;
        results.push(item);
      });

      return results;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { ConsultaMotivo };

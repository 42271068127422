<template>
  <v-row dense>
    <v-col cols="12">
      <v-row dense class="d-flex justify-space-between">
        <v-col cols="5" sm="3">
          <t-integer-edit
            v-model="solicitacao.codigo"
            label="Solicitacão"
            disabled
          />
        </v-col>
        <v-col cols="1">
          <v-btn
            @click="excluir"
            :disabled="modoVisualizacao"
            icon
            color="error"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-col>
    <v-col cols="12" class="mt-n4">
      <t-edit
        v-model="solicitacao.contato.descricao"
        label="Contato"
        disabled
      />
    </v-col>

    <v-col cols="12" sm="6" class="mt-n4">
      <t-edit
        v-model="solicitacao.sistema.descricao"
        label="Sistema"
        disabled
      />
    </v-col>
    <v-col cols="12" sm="6" class="mt-n4">
      <t-edit
        v-model="solicitacao.moduloSistema.descricao"
        label="Modulo"
        disabled
      />
    </v-col>

    <v-col>
      <v-textarea
        v-model="solicitacao.observacao"
        readonly
        label="Descrição"
        persistent-placeholder
        outlined
        no-resize
      />
    </v-col>
  </v-row>
</template>

<script>
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';

export default {
  components: { TEdit, TIntegerEdit, TMemoEdit },

  props: {
    solicitacao: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  methods: {
    excluir() {
      this.$emit('excluir', this.solicitacao);
    },
  },
};
</script>

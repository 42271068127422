<template>
  <form-cadastro
    :title="`Evento ${model.codigo ? model.codigo : ''}`"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Observações </v-tab>
        <v-tab> Funcionários envolvidos </v-tab>
        <v-tab> Contatos </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :modo-visualizacao="modoVisualizacao"
          :model="model"
          @inscrever="inscrever"
          @acessarEvento="acessarEvento"
        />
      </v-tab-item>
      <v-tab-item>
        <aba-observacoes :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
      <v-tab-item>
        <aba-funcionarios-envolvidos
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
      <v-tab-item>
        <aba-contatos :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { Evento } from '@/proxy-api/src/services/classes/Evento/Evento';
import { EventoController } from '@/proxy-api/src/services/classes/Evento/EventoController';
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
Sessao.json = Storage.getSessao();

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaObservacoes from './components/AbaObservacoes.vue';
import AbaFuncionariosEnvolvidos from './components/AbaFuncionariosEnvolvidos';
import AbaContatos from './components/AbaContatos';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
    AbaIdentificacao,
    AbaFuncionariosEnvolvidos,
    AbaContatos,
    AbaObservacoes,
  },

  async mounted() {
    this.removerAcoesDoUsuario();
    await this.verificainscricao();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new Evento(),
      controller: new EventoController(),
      abaSelecionada: 0,
      autenticacaoController: new AutenticacaoController(),
    };
  },

  methods: {
    async acessarEvento() {
      const dados = {
        Codigo: this.model.codigo,
        TipoPessoa: Sessao.codigoTipoPessoa(),
        Pessoa: Sessao.usuario.codigoPessoa,
      };

      try {
        await this.controller.validarAcesso(dados);
        window.open(this.model.link, '_blank');
      } catch (error) {
        TMessageDialog.error()
          .title('Impossível acessar evento')
          .message(`${error.message}`)
          .show();
      }
    },

    removerAcoesDoUsuario() {
      this.$refs.form.actionItems = [];
    },

    async verificainscricao() {
      this.model.inscrito = await this.controller.verificaInscricao({
        codigoEvento: this.model.codigo,
        codigoPessoa: Sessao.usuario.codigoPessoa,
        tipoPessoa: Sessao.codigoTipoPessoa(),
      });
    },

    async buscaDocumentoUsuario() {
      const credenciasLogin = {
        cliente: dadosRecebidosNaUrl.cliente ? dadosRecebidosNaUrl.cliente : '',
        email: dadosRecebidosNaUrl.email ? dadosRecebidosNaUrl.email : '',
      };
    },

    async inscrever() {
      const payload = {
        Codigo: this.model.codigo,
        Pessoa: Sessao.usuario.codigoPessoa,
        TipoPessoa: Sessao.codigoTipoPessoa(),
      };
      try {
        const resposta = await this.controller.inscrever(payload);
        TMessageDialog.success().message(`${resposta}`).show();
        this.model.inscrito = true;
      } catch (error) {
        TMessageDialog.error()
          .title('Impossível realizar inscrição')
          .message(`${error.message}`)
          .show();
      }
    },
  },
};
</script>

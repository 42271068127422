<template>
  <t-form
    title="Listas de E-mail da Tek-System"
    :navigationIcon="iconeVoltar ? 'mdi-arrow-left' : ''"
    :processamento="processamento"
  >
    <t-container vertical-scroll height="100%" class="px-3">
      <card-revogacao
        v-for="lista in model.listas"
        :key="lista.codigo"
        :model="lista"
        class="my-6 py-2"
        @inscrever="inscrever"
        @revogar="revogar"
      ></card-revogacao>
    </t-container>
  </t-form>
</template>

<script>
import { Revogacao } from '../../proxy-api/src/services/classes/Revogacao/Revogacao';
import { RevogacaoController } from '../../proxy-api/src/services/classes/Revogacao/RevogacaoController';

import TToolbar from 'tek-components-vuejs/src/toolbar/TToolbar.vue';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import CardRevogacao from './CardRevogacao.vue';
import TForm from 'tek-components-vuejs/src/form/TForm';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import ModalRevogacao from './ModalRevogacao.vue';
import { Storage } from '../../proxy-api/src/services/classes/Storage/Storage';
import { Sessao } from '../../proxy-api/src/services/classes/Autenticacao/Sessao';
import router from '../../router';

export default {
  components: { TToolbar, TContainer, CardRevogacao, TForm },

  data: function () {
    return {
      model: new Revogacao(),
      sessao: null,
      controller: new RevogacaoController(),
      processamento: '',
    };
  },

  props: {
    iconeVoltar: {
      type: Boolean,
      default: false,
    },
  },

  methods: {
    async inscrever(lista) {
      this.model.lista = lista.codigo;
      this.processamento = 'Realizando inscrição...';
      try {
        const response = await this.controller.inscrever(this.model.json);
        if (response) {
          TMessageDialog.success().message(response).show();
          this.inverteEstadoBotao();
        }
        this.processamento = '';
      } catch (error) {
        this.processamento = '';
        TMessageDialog.error().message(error.message).show();
      }
    },

    inverteEstadoBotao() {
      const listaIndex = this.model.listas.findIndex(
        lista => lista.codigo === this.model.lista,
      );
      this.model.listas[listaIndex].pertence =
        !this.model.listas[listaIndex].pertence;
    },

    revogar(lista) {
      this.model.lista = lista.codigo;
      this.modalRevogacao();
    },

    modalRevogacao() {
      const instancia = TFormUtil.instanciar(ModalRevogacao);
      TFormUtil.abrir(instancia);
      instancia.$on('fecharModal', () => {
        TFormUtil.fechar(instancia);
      });

      instancia.$on('enviarRevogacao', motivoRevogacao => {
        this.model.motivo = motivoRevogacao;
        TFormUtil.fechar(instancia);
        this.enviarRevogacao();
      });
    },

    async enviarRevogacao() {
      this.processamento = 'Revogando inscrição...';
      try {
        const response = await this.controller.revogarInscricao(
          this.model.json,
        );
        if (response) {
          TMessageDialog.success().message(response).show();
          this.inverteEstadoBotao();
        }
        this.processamento = '';
      } catch (error) {
        this.processamento = '';
        TMessageDialog.error().message(error.message).show();
      }
    },

    getSessao() {
      Sessao.json = Storage.getSessao();
      this.sessao = Sessao;
    },

    mensagemErroTravada(mensagem) {
      TMessageDialog.error().title('Erro!').message(mensagem).show();
    },
  },

  async mounted() {
    try {
      let credenciais;
      if (router.currentRoute.name !== 'Principal') {
        const credenciaisBase64 = this.$route.params.credenciais;
        credenciais = JSON.parse(Buffer.from(credenciaisBase64, 'base64'));
      } else {
        this.getSessao();

        if (!this.sessao.usuario.codigo) {
          router.push('/login');
          return;
        }

        credenciais = {
          codigo: this.sessao.usuario.codigoPessoa,
          tipo: this.sessao.codigoTipoPessoa(),
        };
      }

      this.processamento = 'Carregando listas de publicação...';

      let response = await this.controller.carregarListas(credenciais);
      this.model.json = { ...credenciais, ...response };
    } catch (error) {
      this.mensagemErroTravada(
        'Não foi possível carregar as listas de publicação',
      );
    } finally {
      this.processamento = '';
    }
  },
};
</script>

<template>
  <t-form
    ref="form"
    :title="titulo"
    :processamento="carregamentoRelatorio"
    trataFechar
    @fechar="fechar"
  >
    <iframe
      v-if="$vuetify.breakpoint.mdAndUp && !isIphone"
      id="iframepdf"
      :src="url"
      class="fill-height"
      style="width: 100%"
    ></iframe>

    <t-container v-else align-items="center" height="100%">
      <t-empty-state
        text="O seu relatório já está disponível"
        detail="Click no botão abaixo para acessá-lo agora mesmo"
        icon="mdi-clipboard-text"
        buttonLabel="ABRIR"
        :buttonCallback="download"
      />
    </t-container>
  </t-form>
</template>

<script>
import { BrowserUtil } from 'tek-utils-js';

import TForm from 'tek-components-vuejs/src/form/TForm';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';

export default {
  components: {
    TForm,
    TContainer,
    TEmptyState,
  },

  data() {
    return {
      titulo: 'Visualização',
      carregamentoRelatorio: '',
      url: '',
      isIphone: window.navigator.userAgent.indexOf('iPhone OS') !== -1,
    };
  },

  methods: {
    fechar() {
      this.$refs.form.fechar();
    },

    download() {
      const link = `${this.url}`;
      BrowserUtil.abrir(link);
    },
  },
};
</script>

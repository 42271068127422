import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Contato } from '../Contato/Contato';
import { Sistema } from '../Sistema/Sistema';
import { ModuloSistema } from '../Sistema/ModuloSistema';

class Solicitacao extends Cadastro {
  constructor(persistente) {
    super();
    this.contato = new Contato();
    this.sistema = new Sistema();
    this.moduloSistema = new ModuloSistema();
    this.codigoParaExclusao = 0;
  }

  set json(dados) {
    this.codigo = Number(dados.atendSolSatd) || Number(dados.autoincTds);
    this.codigoParaExclusao = dados.autoincSatd;
    this.contato.descricao = dados.nomeClc;
    this.sistema.descricao = dados.nomeSis;
    this.moduloSistema.descricao = dados.descricaoMod;
    this.observacao = dados.observacaoTds;
  }

  get json() {
    return {
      atendSolSatd: this.codigo,
      autoincSatd: this.codigoParaExclusao,
      nomeClc: this.contato.descricao,
      nomeSis: this.sistema.descricao,
      descricaoMod: this.moduloSistema.descricao,
      observacaoTds: this.observacao,
    };
  }
}

export { Solicitacao };

<template>
  <t-form
    title="Confirmar Presença"
    navigationIcon=""
    :processamento="processamento"
  >
    <t-container>
      <t-empty-state
        class="mt-12"
        v-if="conviteNaoEncontrado"
        text="Convite não encontrado"
        icon="mdi-alert-circle-outline"
      />
      <t-empty-state
        class="mt-12"
        v-else-if="conviteExpirado"
        text="Convite expirado"
        :detail="`A data limite para confirmar presença foi ${dataExpiracao}`"
        icon="mdi-clock"
      />
      <t-card v-else-if="respondendo">
        <div class="px-4 py-2">
          <div class="mb-8 text-center">
            <h3 class="font-weight-regular">
              Deseja confirmar participação no evento?
            </h3>
            <h3>{{ credenciais.descricao }}</h3>
          </div>
          <v-row>
            <v-col cols="12" class="text-center">
              <v-btn
                color="error"
                class="mr-4"
                @click="convite('N')"
                :disabled="processamento !== ''"
              >
                Não
              </v-btn>
              <v-btn
                color="success"
                class="mr-4"
                @click="convite('S')"
                :disabled="processamento !== ''"
              >
                Sim
              </v-btn>
            </v-col>
          </v-row>
        </div>
      </t-card>
      <t-empty-state
        class="mt-12"
        v-else
        text="Obrigado por responder"
        detail="Sua resposta foi enviada corretamente"
        icon="mdi-check-circle"
      />
    </t-container>
  </t-form>
</template>

<script>
import { EventoController } from '../../proxy-api/src/services/classes/Evento/EventoController';
import { DataUtils } from '../../proxy-api/src/utils/DataUtils';

import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';

export default {
  components: { TForm, TContainer, TCard, TEmptyState },

  data: function () {
    return {
      // FORMATO CREDENCIAIS
      // {
      //   "tipoPessoa": 1 | 2 | 3 (Funcionario, cliente ou contato),
      //   "codigo": Number: Codigo relaciona evento/pessoa,
      //   "descricao": String: Descricao do Evento,
      //   "expiracao": String: data no formato "aaaa-mm-dd"
      // }

      credenciais: {},
      processamento: '',
      controller: new EventoController(),
      pessoa: {},
      conviteNaoEncontrado: false,
      conviteExpirado: false,
      respondendo: true,
    };
  },

  mounted: async function () {
    try {
      this.processamento = 'Carregando convite...';
      const credenciaisBase64 = this.$route.params.credenciais;
      const credenciais = JSON.parse(Buffer.from(credenciaisBase64, 'base64'));
      this.credenciais = credenciais;
      this.verificaConviteExpirado();
    } catch (error) {
      this.conviteNaoEncontrado = true;
      TMessageDialog.error().message(error.message).show();
    } finally {
      this.processamento = '';
    }
  },

  methods: {
    verificaConviteExpirado() {
      const dataExpiracao = DataUtils.formatarDataParaDiaMesAno(
        this.credenciais.expiracao,
      );
      if (DataUtils.menorQueDataAtual(dataExpiracao)) {
        this.conviteExpirado = true;
        return;
      }
    },

    async convite(situacao) {
      this.processamento = 'Confirmando presença...';
      let dados = {
        TipoPessoa: this.credenciais.tipoPessoa,
        Codigo: this.credenciais.codigo,
        Situacao: situacao,
        Pessoa: this.credenciais.pessoa,
      };
      try {
        const response = await this.controller.convite(dados);
        if (response)
          TMessageDialog.success()
            .message(response)
            .okCallback(() => (this.respondendo = false))
            .show();
      } catch (error) {
        TMessageDialog.error()
          .title('Erro ao confirmar presença')
          .message(error.message)
          .show();
      } finally {
        this.processamento = '';
      }
    },
  },

  computed: {
    dataExpiracao() {
      return DataUtils.formatarDataParaDiaMesAno(this.credenciais.expiracao);
    },
  },
};
</script>

<template>
  <t-form
    title="Validação de E-mail"
    navigationIcon=""
    :processamento="processamento"
  >
    <t-container>
      <t-card>
        <span>
          Olá <b>{{ credenciais.nome }}</b>
        </span>

        <div class="pl-4 mt-3">
          <div v-if="verificado">
            <v-icon color="success"> mdi-check-bold </v-icon>
            E-mail verificado!
          </div>

          <div v-else class="d-flex justify-space-between">
            <v-row align="center">
              <v-icon color="error" class="mr-1">mdi-close-thick</v-icon>
              <span>E-mail não verificado</span>
            </v-row>
            <v-btn right color="primary" @click="validarEmail">
              Tentar Novamente
            </v-btn>
          </div>
        </div>
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import { UsuarioController } from '../../proxy-api/src/services/classes/Usuario/UsuarioController';

import TToolbar from 'tek-components-vuejs/src/toolbar/TToolbar.vue';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TToolbar, TForm, TContainer, TCard },
  data: function () {
    return {
      credenciais: {},
      verificado: true,
      controller: new UsuarioController(),
      processamento: '',
    };
  },
  methods: {
    async validarEmail() {
      try {
        this.processamento = 'Validando e-mail';
        const response = await this.controller.validarEmail(this.credenciais);
        if (response) {
          this.verificado = true;
          TMessageDialog.success()
            .title('E-mail validado!')
            .message(response)
            .show();
        }
      } catch (error) {
        this.verificado = false;
        TMessageDialog.error()
          .title('E-mail não validado!')
          .message(error.message)
          .show();
      } finally {
        this.processamento = '';
      }
    },
  },
  mounted: async function () {
    const credenciaisBase64 = this.$route.params.credenciais;
    const credenciais = JSON.parse(Buffer.from(credenciaisBase64, 'base64'));
    this.credenciais = credenciais;
    await this.validarEmail();
  },
};
</script>

<style></style>

<template>
  <t-form v-bind="$attrs" v-on="$listeners">
    <slot></slot>
    <template v-slot:side-sheet>
      <slot name="side-sheet"> </slot>
    </template>
  </t-form>
</template>

<script>
import TForm from 'tek-components-vuejs/src/form/TForm';

export default {
  components: { TForm },
};
</script>

<style></style>

<template>
  <form-cadastro
    :title="`Pessoa ${model.codigo ? model.codigo : ''}`"
    @stateChanged="onStateChanged"
    @aposGravar="aposGravar"
    @aposExcluir="aposExcluir"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Setor </v-tab>
        <v-tab v-if="!sessao.isAcessoContato()"> Particularidades </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          ref="abaIdentificacao"
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-setor :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>

      <v-tab-item>
        <aba-particularidades
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { ContatoController } from '@/proxy-api/src/services/classes/Contato/ContatoController';
import { Contato } from '@/proxy-api/src/services/classes/Contato/Contato';

import FormCadastro from '@/components/form/FormCadastro';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaSetor from './components/AbaSetor';
import AbaParticularidades from './components/AbaParticularidades';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';

export default {
  components: {
    AbaIdentificacao,
    AbaSetor,
    AbaParticularidades,
    FormCadastro,
  },

  computed: {
    contatoController: function () {
      return new ContatoController();
    },

    consultaSetor: function () {
      return new ConsultaSetor();
    },
  },

  data() {
    return {
      modoVisualizacao: true,
      model: new Contato(true),
      controller: new ContatoController(),
      abaSelecionada: 0,
      sessao: new Sessao(),
    };
  },

  created() {
    Sessao.json = Storage.getSessao();
    this.sessao = Sessao;
  },

  methods: {
    onStateChanged(emEdicao) {
      this.modoVisualizacao = !emEdicao;
    },

    incluir() {
      this.$refs.form.incluir();
    },

    aposGravar(registro) {
      this.abaSelecionada = 0;

      this.$emit('aposGravar', registro);
    },

    aposExcluir(registro) {
      this.$emit('aposExcluir', registro);
    },

    fechar() {
      this.$refs.form.fechar();
    },
  },
};
</script>

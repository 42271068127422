import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { DataUtils } from '../../../utils/DataUtils';
import {
  siglaParaDescricaoAtendimento,
  siglaParaIconeCorAtendimento,
} from '../../../utils/siglaAtendimento';

class Pendencia extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.dataInclusao = '';
    this.dataEmDateJS = '';
    this.status = '';
    this.tipo = '';
    this.descricaoTipo = '';
    this.icone = '';
    this.cor = '';
  }

  set json(dados) {
    this.codigo = Number(dados.codigo);
    this.descricao = dados.descricao;
    this.dataInclusao = DataUtils.formatarDataParaDiaMesAno(dados.inclusao);
    this.dataEmDateJS = new Date(dados.inclusao + 'T00:00');
    this.status = dados.status;
    this.tipo = dados.tipo;
    this.descricaoTipo = siglaParaDescricaoAtendimento(this.tipo);
    this.icone = siglaParaIconeCorAtendimento(this.tipo).icone;
    this.cor = siglaParaIconeCorAtendimento(this.tipo).cor;
  }

  get json() {}

  setCorEIcone(tipo) {
    switch (tipo) {
      case 'ATI':
        return { icone: 'mdi-phone-in-talk', cor: '#4BB991' };
      case 'ATE':
        return { icone: 'mdi-account-voice', cor: '#9BB3C7' };
      case 'SER':
        return { icone: 'mdi-account-wrench', cor: '#4B6AB9' };
      case 'PRO':
        return { icone: 'mdi-projector-screen', cor: '#B96C4B' };
      case 'PRP':
        return { icone: 'mdi-notebook', cor: '#B94B94' };
      case 'EVE':
        return { icone: 'mdi-calendar-clock', cor: '#B94B4B' };
    }
  }

  setDescricaoTipo(tipo) {
    switch (tipo) {
      case 'ATI':
        return 'Atendimento';
      case 'ATE':
        return 'Solicitação';
      case 'SER':
        return 'Serviço';
      case 'PRO':
        return 'Projeto';
      case 'PRP':
        return 'Proposta';
      case 'EVE':
        return 'Evento';
    }
  }
}

export { Pendencia };

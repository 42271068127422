import { Controller } from '../../bases/Controllers/Controller';
import { api } from '../../api';
import { Notificacao } from './Notificacao';

class NotificacaoController extends Controller {
  constructor() {
    super('inscricao');
  }

  async buscar() {
    try {
      const { data } = await api.get('/notificacao/abertasUsuario');
      const notificacoes = data.map(registro => {
        const notificacao = new Notificacao();
        notificacao.json = registro;
        return notificacao;
      });
      return notificacoes;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async marcarComoLida(notificacao) {
    try {
      const { data } = await api.post(
        `/notificacao/marcarLida/${notificacao.codigo}`,
      );
      return true;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { NotificacaoController };

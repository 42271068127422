import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaTipoAtendimento extends ConsultaController {
  constructor() {
    super('tipo_atendimento', 'Tipo de Atendimento', 'codigoTat');

    this.ordenacao = 'descricaoTat';

    this.addFiltroFixo('ativoTat', 'IGUAL', 'S');
    this.addFiltro('codigoTat', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('descricaoTat', 'Descrição', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoTat;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.descricaoTat;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.descricaoTat.substr(0, 1) + '</h1>';
  }
}

export { ConsultaTipoAtendimento };

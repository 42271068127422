import { Controller } from '../../bases/Controllers/Controller';
import { EventoController } from '../Evento/EventoController';

class InscricaoController extends Controller {
  constructor() {
    super('inscricao');
  }

  async inscrever(dados) {
    try {
      const eventoController = new EventoController();

      const response = await eventoController.inscrever(dados);
      return response;
    } catch (error) {
      throw new Error(error);
    }
  }
}

export { InscricaoController };

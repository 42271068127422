export function siglaParaDescricaoAtendimento(tipo) {
  switch (tipo) {
    case 'ATI':
      return 'atendimento';
    case 'ATD':
      return 'atendimento';
    case 'ATE':
      return 'solicitação';
    case 'SER':
      return 'serviço';
    case 'PRO':
      return 'projeto';
    case 'PRP':
      return 'proposta';
    case 'EVE':
      return 'evento';
    default:
      return tipo;
  }
}

export function descricaoAtendimentoParaSigla(tipo) {
  tipo = tipo.toUpperCase();
  switch (tipo) {
    case 'ATENDIMENTO':
      return 'ATI';
    case 'SOLICITAÇÃO':
      return 'ATE';
    case 'SOLICITACAO':
      return 'ATE';
    case 'SERVIÇO':
      return 'SER';
    case 'SERVICO':
      return 'SER';
    case 'PROJETO':
      return 'PRO';
    case 'PROPOSTA':
      return 'PRP';
    case 'EVENTO':
      return 'EVE';
  }
}

export function siglaParaIconeCorAtendimento(tipo) {
  switch (tipo) {
    case 'ATI':
      return { icone: 'mdi-phone-in-talk', cor: '#4BB991' };
    case 'ATE':
      return { icone: 'mdi-account-voice', cor: '#9BB3C7' };
    case 'SER':
      return { icone: 'mdi-account-wrench', cor: '#4B6AB9' };
    case 'PRO':
      return { icone: 'mdi-projector-screen', cor: '#B96C4B' };
    case 'PRP':
      return { icone: 'mdi-notebook', cor: '#B94B94' };
    case 'EVE':
      return { icone: 'mdi-calendar-clock', cor: '#B94B4B' };
  }
}

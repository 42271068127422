<template>
  <t-form
    title="Formulário de Inscrição"
    navigationIcon=""
    :processamento="processamento"
  >
    <t-container>
      <t-empty-state
        class="mt-12"
        v-if="inscricaoNaoEncontrada"
        text="Inscrição não encontrada"
        icon="mdi-alert-circle-outline"
      />

      <t-empty-state
        class="mt-12"
        v-else-if="inscricaoExpirada"
        text="Data de inscrição expirada"
        :detail="`A data limite para realizar inscrição no ${this.model.tipo} foi ${this.model.expiracao}`"
        icon="mdi-clock"
      />

      <t-card v-else-if="respondendo">
        <div class="pa-3 text-center">
          <v-row justify="center" align="center" class="fill-height mb-12">
            <v-img
              src="@/assets/teksystem.png"
              max-height="150"
              max-width="150"
            />
          </v-row>

          <div class="text-h5 mb-12">
            {{ this.model.descricao }}
          </div>

          <div>
            <t-edit
              label="Nome Completo"
              v-model="model.nome"
              :rules="[value => !!value || 'Informe um nome']"
            />
            <t-telefone-edit
              label="Telefone"
              v-model="model.telefone"
              color="primary"
              :rules="[value => !!value || 'Informe um telefone']"
            />
            <t-email-edit
              label="E-mail"
              v-model="model.email"
              color="primary"
              :rules="[value => !!value || 'Informe um e-mail']"
            />
            <TCPFEdit
              label="CPF"
              v-model="model.cpf"
              color="primary"
              :rules="[value => !!value || 'Informe um cpf']"
            />

            <v-radio-group v-model="model.interesse" class="mb-2" required>
              <template v-slot:label>
                <h3>Estou preenchendo este formulário em conexão com:</h3>
              </template>
              <v-radio value="Interesse da empresa">
                <template v-slot:label>
                  <div>Interesse da empresa.</div>
                </template>
              </v-radio>
              <v-radio value="Interesse pessoal">
                <template v-slot:label>
                  <div>Interesse pessoa.</div>
                </template>
              </v-radio>
              <v-radio value="Interesse comercial">
                <template v-slot:label>
                  <div>Interesse comercial.</div>
                </template>
              </v-radio>
            </v-radio-group>

            <v-checkbox
              v-model="model.maiorDeIdade"
              label="Confirmo ter 18 anos ou mais."
              color="primary"
              class="mb-4"
            />

            <v-btn
              block
              placeholder=" "
              color="primary"
              min-height="56"
              @click="inscrever"
              :disabled="!model.maiorDeIdade"
            >
              Inscrever-se</v-btn
            >
          </div>
        </div>
      </t-card>
      <t-empty-state
        class="mt-12"
        v-else
        text="Inscrição realizada!"
        :detail="`Você está inscrito(a) no ${this.model.tipo.toLowerCase()}.`"
        icon="mdi-check-circle"
      />
    </t-container>
  </t-form>
</template>

<script>
import { InscricaoController } from '../../proxy-api/src/services/classes/Inscricao/InscricaoController';
import { Inscricao } from '../../proxy-api/src/services/classes/Inscricao/Inscricao';
import { DataUtils } from '../../proxy-api/src/utils/DataUtils';

import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';
import TEmailEdit from 'tek-components-vuejs/src/edit/TEmailEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TCPFEdit from 'tek-components-vuejs/src/edit/TCPFEdit';
import TTelefoneEdit from 'tek-components-vuejs/src/edit/TTelefoneEdit';

export default {
  components: {
    TForm,
    TContainer,
    TCard,
    TEmptyState,
    TEdit,
    TEmailEdit,
    TCPFEdit,
    TTelefoneEdit,
  },

  data: function () {
    return {
      model: new Inscricao(),
      controller: new InscricaoController(),
      processamento: '',
      inscricaoNaoEncontrada: false,
      inscricaoExpirada: false,
      respondendo: true,
    };
  },

  async mounted() {
    try {
      this.processamento = 'Carregando...';
      const credenciaisBase64 = this.$route.params.credenciais;
      this.model.json = credenciaisBase64;
      this.verificaDataLimiteInscricao();
    } catch (error) {
      console.error(error);
      TMessageDialog.error().message('Inscrição não encontrada').show();
      this.inscricaoNaoEncontrada = true;
    } finally {
      this.processamento = '';
    }
  },

  methods: {
    verificaDataLimiteInscricao() {
      const dataExpiracao = DataUtils.formatarDataParaDiaMesAno(
        this.model.expiracao,
      );
      if (DataUtils.menorQueDataAtual(dataExpiracao)) {
        this.inscricaoExpirada = true;
        return;
      }
    },

    async inscrever() {
      this.processamento = 'Confirmando presença...';
      try {
        const response = await this.controller.inscrever(this.model.json);
        if (response)
          TMessageDialog.success()
            .message(response)
            .okCallback(() => (this.respondendo = false))
            .show();
      } catch (error) {
        TMessageDialog.error()
          .title('Erro ao inscrever-se')
          .message(`${error}`)
          .show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

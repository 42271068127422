<template>
  <v-sheet class="mx-auto" max-width="1300">
    <v-slide-group multiple v-model="slide">
      <v-slide-item>
        <status-card
          :selecionado="filtro === ''"
          descricao="Todos"
          icone="mdi-restore"
          :quantidade="totalPendencias"
          cor="primary"
          @selecionado="$emit('selecionado', null)"
        />
      </v-slide-item>
      <v-slide-item v-for="status in model.status" :key="status.descricao">
        <status-card
          :selecionado="filtro === status.sigla"
          :descricao="status.descricao"
          :icone="status.icone"
          :quantidade="status.quantidade"
          :cor="status.cor"
          @selecionado="$emit('selecionado', status)"
        />
      </v-slide-item>
    </v-slide-group>
  </v-sheet>
</template>

<script>
import StatusCard from './StatusCard.vue';
export default {
  components: { StatusCard },
  props: {
    model: {
      type: Object,
      required: true,
    },
    filtro: {
      type: String,
    },
  },
  data() {
    return {
      slide: [],
    };
  },
  computed: {
    totalPendencias() {
      return this.model.pendencias.length;
    },
  },
};
</script>

import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import router from '../router';

export default class ExibirMensagem {
  static erroGenerico(erro) {
    if (erro.message.includes('sessão expirou')) {
      TMessageDialog.info()
        .okCallback(() => {
          router.push('/login');
        })
        .message(erro.message)
        .show();
      return;
    }
    TMessageDialog.error().message(erro.message).show();
    return;
  }

  static erro(titulo, mensagem, callback = () => {}) {
    TMessageDialog.error()
      .okCallback(callback)
      .title(titulo)
      .message(mensagem)
      .show();
  }

  static sucesso(titulo, mensagem, callback = () => {}) {
    TMessageDialog.success()
      .okCallback(callback)
      .title(titulo)
      .message(mensagem)
      .show();
  }

  static info(titulo, mensagem, callback = () => {}) {
    TMessageDialog.info()
      .okCallback(callback)
      .title(titulo)
      .message(mensagem)
      .show();
  }

  static pergunta(titulo, mensagem, callback = () => {}) {
    TMessageDialog.question()
      .title(titulo)
      .message(mensagem)
      .okCallback(callback)
      .show();
  }
}

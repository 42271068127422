<template>
  <t-form navigationIcon="" title="Abrir Ticket">
    <t-container align-items="center" class="px-2">
      <v-card class="elevation-4 my-6 mx-auto py-2 px-1" width="700">
        <v-card-title>
          <v-icon>mdi-alert</v-icon>
          <span class="ml-2">{{ title }}</span>
        </v-card-title>
        <v-card-text>
          <p>
            {{ message }}
          </p>
        </v-card-text>
      </v-card>
    </t-container>
  </t-form>
</template>

<script>
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';

export default {
  components: {
    TContainer,
    TForm,
  },
  data() {
    return {
      title: '',
      message: '',
    };
  },
};
</script>

<style></style>

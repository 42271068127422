<template>
  <div>
    <t-container
      v-if="model.funcionariosEnvolvidos.length > 0"
      vertical-scroll
      height="100%"
    >
      <t-card>
        <v-data-table
          :headers="headers"
          :items="model.funcionariosEnvolvidos"
          fixed-header
          height="560"
          disable-pagination
          hide-default-footer
        >
        </v-data-table>
      </t-card>
    </t-container>

    <t-container v-else align-items="center" height="100%">
      <slot name="empty-state">
        <t-empty-state
          detail="Nenhum participante da Tek-System foi encontrado para o evento"
          text="Nenhum registro encontrado"
          icon="mdi-magnify"
        />
      </slot>
    </t-container>
  </div>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';

export default {
  components: { TCard, TContainer, SearchEdit, TEdit, TEmptyState },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      headers: [
        {
          text: 'Código',
          value: 'codigo',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Papel',
          value: 'papel',
        },
        {
          text: 'Situação',
          value: 'situacao',
        },
      ],
    };
  },
};
</script>

<style scoped>
@media (max-width: 575px) {
  .v-list-item__title {
    white-space: pre-wrap;
  }
}
</style>

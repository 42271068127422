<template>
  <form-consulta :title="controller.titulo" :processamento="processamento">
    <t-container>
      <t-card>
        <v-treeview
          ref="tree"
          hoverable
          dense
          shaped
          item-key="codigoPro"
          :items="motivos"
          activatable
          color="primary"
          open-all
          open-on-click
        >
          <template v-slot:label="{ item }">
            <span @click="() => selecionar(item)">{{ item.name }}</span>
          </template>
        </v-treeview>
      </t-card>
    </t-container>
  </form-consulta>
</template>

<script>
import FormConsulta from './FormConsulta';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TCard from 'tek-components-vuejs/src/card/TCard';

export default {
  components: {
    FormConsulta,
    TContainer,
    TCard,
  },

  props: {
    model: {
      type: Object,
      default: null,
    },

    controller: {
      type: Object,
      default: null,
    },
  },

  data() {
    return {
      processamento: '',
      motivos: [],
    };
  },

  methods: {
    async montaArvoreMotivos() {
      try {
        this.processamento = 'Carregando árvore de motivos...';
        this.motivos = await this.controller.montaArvoreMotivos();
      } catch (error) {
        TMessageDialog.error(error.message);
      } finally {
        this.processamento = '';
      }
    },

    selecionar(item) {
      //Verifica se o item clicado é um filho
      if (!item?.children) {
        this.$emit('selecionado', item);
      }
    },
  },

  async mounted() {
    await this.montaArvoreMotivos();
  },
};
</script>

<style></style>

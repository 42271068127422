import FormAtendimento from '../../../views/atendimento/form/FormAtendimento';
import FormAtendimentoPresencial from '../../../views/atendimentoPresencial/form/FormAtendimentoPresencial';
import FormEvento from '../../../views/evento/form/FormEvento';
import FormProjeto from '../../../views/projeto/form/FormProjeto';
import FormProposta from '../../../views/proposta/form/FormProposta';
import FormServico from '../../../views/servico/form/FormServico';
import FormAtividadeProjeto from '../../../views/atividadeProjeto/form/FormAtividadeProjeto';

import { ConsultaAtendimento } from '../../../proxy-api/src/services/classes/Atendimento/ConsultaAtendimento';
import { ConsultaAtendimentoPresencial } from '../../../proxy-api/src/services/classes/AtendimentoPresencial/ConsultaAtendimentoPresencial';
import { ConsultaEvento } from '../../../proxy-api/src/services/classes/Evento/ConsultaEvento';
import { ConsultaProjeto } from '../../../proxy-api/src/services/classes/Projeto/ConsultaProjeto';
import { ConsultaProposta } from '../../../proxy-api/src/services/classes/Proposta/ConsultaProposta';
import { ConsultaServico } from '../../../proxy-api/src/services/classes/Servico/ConsultaServico';
import { ConsultaAtividadesProjeto } from '../../../proxy-api/src/services/classes/Projeto/ConsultaAtividadesProjeto.js';

import { TFormUtil } from '../../../proxy-api/src/utils/TFormUtil';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

import router from '../../../router';

class Operacao {
  async abrir({ tipo, codigo }) {
    const operacao = this.getOperacao(tipo);
    if (operacao === null) return;
    const instancia = TFormUtil.instanciar(operacao.form);
    try {
      await operacao.consulta.buscarRegistro(codigo);
    } catch (error) {
      TMessageDialog.error().message(error.message).show();
    }
    instancia.model.json = operacao.consulta.registros[0];
    TFormUtil.abrir(instancia);
    instancia.$on('click-navigation', () => {
      this.trataFechar();
    });
  }

  trataFechar() {
    if (router.currentRoute.name === 'PrincipalOperacao')
      router.push({ name: 'Principal' });
  }

  getOperacao(tipo) {
    switch (tipo) {
      case 'ATI':
        return {
          form: FormAtendimento,
          consulta: new ConsultaAtendimento(),
        };
      case 'ATD':
        return {
          form: FormAtendimento,
          consulta: new ConsultaAtendimento(),
        };
      case 'ATE':
        return {
          form: FormAtendimentoPresencial,
          consulta: new ConsultaAtendimentoPresencial(),
        };
      case 'EVE':
        return { form: FormEvento, consulta: new ConsultaEvento() };
      case 'PRO':
        return { form: FormProjeto, consulta: new ConsultaProjeto() };
      case 'PRP':
        return { form: FormProposta, consulta: new ConsultaProposta() };
      case 'SER':
        return { form: FormServico, consulta: new ConsultaServico() };
      case 'PRR':
        return {
          form: FormAtividadeProjeto,
          consulta: new ConsultaAtividadesProjeto(),
        };
      default:
        return null;
    }
  }
}

export { Operacao };

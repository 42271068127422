<template>
  <t-form
    title="Cadastrar Senha"
    :processamento="processamento"
    navigationIcon=""
  >
    <t-container>
      <p class="text-center my-8 text-gray">
        Antes de abrir um ticket cadastre sua senha de acesso ao Atendimento Web
        por favor.
      </p>
      <t-card>
        <v-text-field
          v-model="senha"
          label="SUA SENHA"
          outlined
          required
          :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="senhaVisivel ? 'text' : 'password'"
          @click:append="senhaVisivel = !senhaVisivel"
          persistent-placeholder=""
        />
        <v-text-field
          v-model="confirmacaoSenha"
          label="CONFIRMAÇÃO DA SENHA"
          outlined
          required
          :append-icon="confirmacaoSenhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="confirmacaoSenhaVisivel ? 'text' : 'password'"
          @click:append="confirmacaoSenhaVisivel = !confirmacaoSenhaVisivel"
          @keypress.enter="cadastrarSenha"
          persistent-placeholder=""
        />
        <v-btn block color="primary" min-height="56" @click="cadastrarSenha"
          >Cadastrar senha</v-btn
        >
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';

import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TForm, TCard, TContainer, TUpperEdit },

  data: function () {
    return {
      senha: '',
      senhaVisivel: false,
      confirmacaoSenha: '',
      confirmacaoSenhaVisivel: false,
      controller: new AutenticacaoController(),
      processamento: '',
    };
  },

  methods: {
    async cadastrarSenha() {
      this.processamento = 'Cadastrando sua senha...';
      try {
        if (this.senha !== this.confirmacaoSenha)
          throw new Error('Senhas não coincidem');
        const response = await this.controller.cadastrarSenha(this.senha);
        if (response) {
          TMessageDialog.success()
            .title('Senha cadastrada com sucesso!')
            .message(
              `Seu próximo acesso será com usuário: <b>${this.usuario}</b> + <b>senha cadastrada</b>`,
            )
            .okCallback(() => this.$emit('fechar'))
            .show();
        }
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Atendente } from '../Atendente/Atendente';
import { Origem } from '../Origem/Origem';
import { Sistema } from '../Sistema/Sistema';
import { ModuloSistema } from '../Sistema/ModuloSistema';
import { TipoServico } from '../TipoServico/TipoServico';
import { Status } from '../Status/Status';
import { Pessoa } from '../Pessoa/Pessoa';
import { Proposta } from '../Proposta/Proposta';
import { Area } from '../Area/Area';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class Servico extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Pessoa();
    this.atendente = new Atendente();
    this.origem = new Origem();
    this.area = new Area();
    this.sistema = new Sistema();
    this.moduloSistema = new ModuloSistema();
    this.tipo = new TipoServico();
    this.status = new Status();
    this.proposta = new Proposta();
    this.versaoOcorrencia = {};
  }

  set json(dados) {
    this.codigo = Number(dados.autoincSvr);
    this.descricao = dados.descricaoSvr;
    this.justificativa = dados.justificativaSvr;

    this.cliente.codigo = dados.clienteSvr;
    this.cliente.descricao = dados.razaoCli;

    this.contato.codigo = dados.contatoSvr;
    this.contato.descricao = dados.nomeClc;

    this.atendente.codigo = dados.atendcoletaSvr;
    this.atendente.descricao = dados.nomeAtnCol;

    this.relatadoPor = dados.nomeClc;

    this.origem.codigo = dados.origemSvr;
    this.origem.descricao = dados.descricaoOri;

    this.area.codigo = dados.codareaSvr;
    this.area.descricao = dados.areaSvr;

    this.sistema.codigo = dados.sistemaSvr;
    this.sistema.descricao = dados.nomeSis;

    this.moduloSistema.codigo = dados.moduloSvr;
    this.moduloSistema.descricao = dados.descricaoMod;

    this.status.codigo = dados.statusSvr;
    this.status.descricao = dados.descricaoStserv;

    this.tipo.codigo = dados.tiposervSvr;
    this.tipo.descricao = dados.nomeTsv;

    this.versaoOcorrencia.codigo = dados.verOcorSvr;
    this.versaoOcorrencia.descricao = dados.versaoSveOco;

    this.proposta.descricao = dados.descricaoPro;
    this.proposta.codigo = dados.propostaSvr;

    this.dataColeta = DataUtils.formatarDataParaDiaMesAno(dados.datacoletaSvr);
  }

  get json() {}
}

export { Servico };

import * as Yup from 'yup';
import moment from 'moment';

import { api } from '../../api';
import { Storage } from '../Storage/Storage';

import { AtendenteController } from '../Atendente/AtendenteController';

import { SessaoController } from '../Autenticacao/SessaoController';

class UsuarioController {
  setPermissoesNavegacaoMenu(jsonMenu, permissoes) {
    jsonMenu.map(menu => {
      if (menu?.children)
        this.setPermissoesNavegacaoMenu(menu.children, permissoes);
      else {
        const permissaoEncontrada = permissoes.some(
          permissao => permissao === menu.permissao,
        );

        if (!permissaoEncontrada && menu?.permissao) menu.desabilitado = true;
      }
    });
  }

  async buscarNavegacaoMenu(tipo) {
    let menu = null;

    if (tipo === 'funcionario') menu = menuFuncionario;
    else menu = menuCliente;

    try {
      const { data } = await api.get('permissions');

      this.setPermissoesNavegacaoMenu(menu, data);
      return menu;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async VerificarTipoDeAcesso(usuarioSessao, sessaoVue) {
    try {
      if (usuarioSessao.usuario.codigoCliente === 0) {
        let atendenteController = new AtendenteController();

        const { codigo } = usuarioSessao.usuario;
        const usuario = await atendenteController.getUserConfig(codigo);

        sessaoVue.set('tipoUsuario', 'funcionario');

        return {
          data: {
            usuario,
            tipoDoAcesso: 'funcionario',
          },
        };
      }

      sessaoVue.set(
        'codigoEmpresa',
        String(usuarioSessao.usuario.codigoCliente),
      );
      sessaoVue.set('tipoUsuario', 'cliente');

      return {
        data: {
          cliente: usuarioSessao.usuario.codigoCliente,
          tipoDoAcesso: 'cliente',
        },
      };
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async RecuperarSecaoAtual() {
    let token = SessaoController.idToken;

    try {
      const response = await api.get('/sections/current');

      return {
        data: response.data,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async destruirSessao() {
    const credenciais = Storage.getCredenciais();

    try {
      await api.delete(`sections/${credenciais.id}`);
    } catch (e) {
      throw new Error(e.message);
    }
  }

  async validarEmail(credenciais) {
    if (!credenciais) throw new Error('Não foi informado suas credenciais');

    let credencialsYup = Yup.object().shape({
      expiracao: Yup.string().required(),
      email: Yup.string().required(),
      codigo: Yup.number().required(),
    });

    try {
      await credencialsYup.validate(credenciais, { abortEarly: false });
    } catch (error) {
      throw new Error('Credenciais inválidas');
    }

    let data = moment(credenciais.expiracao).add(3, 'hours').format();

    if (moment().isSameOrAfter(data)) {
      throw new Error('Expirou périodo de validação');
    }

    try {
      const { data } = await api.post('clientes_contatos/validateemail', {
        ...credenciais,
      });
      return data;
    } catch (error) {
      throw new Error('Não foi possível validar seu e-mail');
    }
  }
}

export { UsuarioController };

<template>
  <t-container height="100%" vertical-scroll>
    <t-card title="Identificação">
      <t-date-edit
        v-model="model.dataDaVisita"
        :disabled="modoVisualizacao"
        label="DATA DA VISITA"
      />
      <v-select
        v-model="model.localDoAtendimento"
        outlined
        label="LOCAL DA VISITA"
        persistent-placeholder
        :items="LocalAtendimento.toJSON()"
        :disabled="modoVisualizacao"
      ></v-select>

      <v-row dense>
        <v-col cols="6">
          <t-time-edit
            v-model="model.horarioDeEntrada"
            label="HORÁRIO DE ENTRADA"
            :disabled="modoVisualizacao"
          />
        </v-col>
        <v-col cols="6">
          <t-time-edit
            v-model="model.horarioDeSaida"
            label="HORÁRIO DE SAÍDA"
            :disabled="modoVisualizacao"
          />
        </v-col>
      </v-row>

      <t-time-edit
        v-model="model.tempoDeslocamento"
        label="TEMPO DE DESLOCAMENTO (HH:MM)"
        :disabled="modoVisualizacao"
      />
    </t-card>

    <t-card title="Intervalo para almoço">
      <v-row dense>
        <v-col cols="6">
          <t-time-edit
            v-model="model.horarioDeAlmocoEntrada"
            label="HORÁRIO DE INÍCIO"
            :disabled="modoVisualizacao"
          />
        </v-col>
        <v-col cols="6">
          <t-time-edit
            v-model="model.horarioDeAlmocoSaida"
            label="HORÁRIO DE RETORNO"
            :disabled="modoVisualizacao"
          />
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import LocalAtendimento from '@/proxy-api/src/enumerations/LocalAtendimento';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TDateEdit from 'tek-components-vuejs/src/edit/TDateEdit';
import TTimeEdit from 'tek-components-vuejs/src/edit/TTimeEdit';

export default {
  components: { TCard, TContainer, TDateEdit, TTimeEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      LocalAtendimento: LocalAtendimento,
    };
  },
};
</script>

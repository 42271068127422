import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Atendente } from '../Atendente/Atendente';
import { Contato } from '../Contato/Contato';

import { DataUtils } from '@/proxy-api/src/utils/DataUtils';
import { Situacao } from '../Atendimento/Situacao';

class Evento extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.situacao = new Situacao();
    this.atendente = new Atendente();
    this.funcionariosEnvolvidos = [];
    this.participantesStakeholders = [];
    this.permiteInscricao = false;
    this.inscrito = false;
    this.link = '';
  }

  set json(dados) {
    this.codigo = Number(dados.codigoEve);
    this.descricao = dados.descricaoEve;

    this.situacao.codigo = Number(dados.situacaoEve);
    this.situacao.descricao = this.getDescricaoSituacaoEvento(
      Number(dados.situacaoEve),
    );

    this.cliente.codigo = dados.clienteEve;
    this.cliente.descricao = dados.razaoCli;

    this.atendente.codigo = dados.funcionarioEve;
    this.atendente.descricao = dados.nomeAtn;

    this.tipo = dados.descricaoTipeve;
    this.link = dados.linkacessoaEve;

    this.dataPrevisaoInicio = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtinicioEve,
    ).substring(0, 17);
    this.dataPrevisaoFim = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtfimEve,
    ).substring(0, 17);

    this.dataRealizacaoInicio = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtrealizadoEve,
    ).substring(0, 17);
    this.dataRealizacaoTermino = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dtfimrealizadoEve,
    ).substring(0, 17);

    this.observacoes = dados.observacoesEve;

    this.funcionariosEnvolvidos = dados.eventoAtd.map(participante => {
      const atendente = new Atendente();

      atendente.codigo = participante.atendenteEad;
      atendente.codigoEad = participante.codigoEad;
      atendente.descricao = participante.nomeAtn;
      atendente.papel = participante.descricaoTippap;
      atendente.situacao = this.getDescricaoSituacaoAtendente(
        Number(participante.situacaoEad),
      );

      return atendente;
    });

    this.participantesStakeholders = dados.eventoClicontato.map(stakeholder => {
      return {
        codigo: Number(stakeholder.codigoEcc),
        descricao: stakeholder.nomeClc,
        papel: stakeholder.descricaoTippap,
        codigoEcc: stakeholder.codigoEcc,
      };
    });

    this.contatos = dados.eventoClicontato.map(registro => {
      const contato = new Contato();
      contato.json = registro;
      return contato;
    });

    this.permiteInscricao = dados.inscricaopublicaEve === 'S' ? true : false;
  }

  getDescricaoSituacaoEvento(codigo) {
    switch (codigo) {
      case 0:
        return 'INDEFINIDO';
      case 1:
        return 'AGUARDANDO AUTORIZAÇÃO';
      case 2:
        return 'REJEITADO';
      case 3:
        return 'ABERTO';
      case 4:
        return 'CONFIRMADO';
      case 5:
        return 'CANCELADO';
      case 6:
        return 'EM EXECUÇÃO';
      case 7:
        return 'REALIZADO';
    }
  }

  getDescricaoSituacaoAtendente(codigo) {
    switch (codigo) {
      case 0:
        return 'INDEFINIDO';
      case 1:
        return 'CONVIDAR';
      case 2:
        return 'CONVIDADO';
      case 3:
        return 'REJEITADO';
      case 4:
        return 'CONFIRMADO';
      case 5:
        return 'FALTOU';
      case 6:
        return 'REALIZADO';
    }
  }

  get json() {}
}

export { Evento };

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Processamento extends Cadastro {
  constructor(persistente) {
    super();

    this.text = '';
    this.nomeDaUnit = '';
    this.id = 'processamento';
  }

  set json(dados) {
    this.codigo = dados.codigoPro;
    this.text = dados.descricaoPro;
    this.nomeDaUnit = dados.nomePro;
  }
}

export { Processamento };

<template>
  <t-form title="Login" :processamento="processamento" navigationIcon="">
    <t-container>
      <p class="text-center my-8 text-gray">
        Antes de abrir um ticket entre com suas credenciais por favor.
      </p>
      <t-card>
        <v-text-field
          v-model="usuario.usuario"
          label="USUÁRIO"
          outlined
          required
          type="text"
          persistent-placeholder=""
        />
        <v-text-field
          v-model="usuario.senha"
          label="SENHA"
          outlined
          required
          :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="senhaVisivel ? 'text' : 'password'"
          @click:append="senhaVisivel = !senhaVisivel"
          @keypress.enter="acessar"
          persistent-placeholder=""
        />
        <v-btn block color="primary" min-height="56" @click="acessar"
          >Entrar</v-btn
        >
      </t-card>
    </t-container>
  </t-form>
</template>

<script>
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';

import TForm from 'tek-components-vuejs/src/form/TForm';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TForm, TCard, TContainer, TUpperEdit },

  data: function () {
    return {
      usuario: {
        usuario: '',
        senha: '',
      },
      senhaVisivel: false,
      controller: new AutenticacaoController(),
      processamento: '',
    };
  },

  methods: {
    async acessar() {
      this.processamento = 'Validado suas credenciais...';
      try {
        await this.controller.acessar(this.usuario);
        this.$emit('fechar');
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

const MenuFuncionario = processamentos => {
  return [
    {
      id: 'contato',
      text: 'Pessoas',
      icon: 'mdi-account-multiple-outline',
      subItems: [
        { id: 'novo_contato', text: 'Novo' },
        { id: 'consulta_contato', text: 'Consulta' },
      ],
    },
    {
      id: 'atendimento',
      text: 'Atendimentos',
      icon: 'mdi-phone-outline',
      subItems: [
        { id: 'novo_atendimento', text: 'Novo' },
        { id: 'consulta_atendimento', text: 'Consulta' },
      ],
    },
    {
      id: 'relatorio_atendimento_presencial',
      text: 'Atendimentos Presenciais',
      icon: 'mdi-alarm-light-outline',
      subItems: [
        { id: 'novo_atendimento_presencial', text: 'Novo' },
        {
          id: 'consulta_atendimento_presencial',
          text: 'Consulta',
        },
      ],
    },
    {
      id: 'relatorio_atividades_projeto',
      text: 'Atividades do Projeto',
      icon: 'mdi-walk',
      subItems: [
        { id: 'nova_atividade_projeto', text: 'Novo' },
        { id: 'consulta_atividade_projeto', text: 'Consulta' },
      ],
    },
    {
      id: 'consulta_evento',
      text: 'Eventos',
      icon: 'mdi-account-clock-outline',
    },
    {
      id: 'consulta_projeto',
      text: 'Projetos',
      icon: 'mdi-file-sign',
    },
    {
      id: 'consulta_proposta',
      text: 'Propostas',
      icon: 'mdi-card-text-outline',
    },
    {
      id: 'consulta_servico',
      text: 'Serviços',
      icon: 'mdi-account-hard-hat-outline',
    },
    {
      indentificador: 'Processamentos',
      text: 'Processamentos',
      icon: 'mdi-reload',
      subItems: processamentos,
    },
    {
      id: 'Acesso',
      text: 'Outros',
      icon: 'mdi-dots-horizontal',
      subItems: [
        {
          id: 'liberar_acesso',
          text: 'Liberar Acesso',
        },
        {
          id: 'ponto_eletronico',
          text: 'Ponto Eletrônico',
        },
      ],
    },
  ];
};

export { MenuFuncionario };

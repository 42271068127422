import { api } from '../../api';
import { Controller } from '../../bases/Controllers/Controller';
import { ValidarValoresUtil } from '@/proxy-api/src/utils/ValidarValoresUtil';

class AtividadesProjetoController extends Controller {
  constructor() {
    super('projeto_realizacao');
  }

  async validar(registro) {
    if (ValidarValoresUtil.maiorQueZero(registro.cliente.codigo))
      throw new Error('<b>Cliente</b> não preenchido');

    if (ValidarValoresUtil.maiorQueZero(registro.contato.codigo))
      throw new Error('<b>Contato</b> não preenchido');

    if (!registro.dataDaVisita)
      throw new Error('<b>Data da visita</b> não preenchida');

    if (!registro.horarioDeEntrada)
      throw new Error('<b>Horário de entrada</b> não preenchido');

    if (!registro.horarioDeAlmocoEntrada && registro.horarioDeAlmocoSaida)
      throw new Error('<b>Horário de início para o almoço</b> não preenchido');

    if (registro.horarioDeAlmocoEntrada && !registro.horarioDeAlmocoSaida)
      throw new Error('<b>Horário de retorno do almoço</b> não preenchido');

    if (registro.atividades.length === 0)
      throw new Error('<b>Nenhuma atividade</b> foi preenchida');

    registro.atividades.forEach(atividade => {
      if (!atividade.atividadeProjeto.percentualAtual)
        throw new Error(
          `<b>Atividade ${atividade.atividadeProjeto.codigo}</b>, percentual de realização atual não preenchido`,
        );

      if (!atividade.atividadeProjeto.duracao)
        throw new Error(
          `<b>Atividade ${atividade.atividadeProjeto.codigo}</b>, duração não preenchida`,
        );

      if (
        Number(atividade.atividadeProjeto.percentualAtual) <
        Number(atividade.atividadeProjeto.percentualRealizado)
      )
        throw new Error(
          `<b>Atividade ${atividade.atividadeProjeto.codigo}</b>, percentual de realização atual é menor do que o percentual de realizado`,
        );

      if (
        String(atividade.atividadeProjeto.observacao).trim().length === 0 ||
        !atividade.atividadeProjeto.observacao
      )
        throw new Error(
          `<b>Atividade ${atividade.atividadeProjeto.codigo}</b>, observação não preenchida`,
        );
    });
  }

  async relatorio(codigoDoArquivo) {
    try {
      if (!codigoDoArquivo) throw new Error('Arquivo indisponível');
      const url = `projeto_realizacao/relatorio?Codigo=${codigoDoArquivo}`;

      const { data } = await api.get(url, {
        responseType: 'arraybuffer',
      });

      const file = new Blob([data], { type: 'application/pdf' });
      const fileURL = URL.createObjectURL(file);

      return fileURL;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { AtividadesProjetoController };

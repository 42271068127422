import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

Sessao.json = Storage.getSessao();

class ConsultaServico extends ConsultaController {
  constructor() {
    super('servicos', 'Serviços', 'autoincSvr');

    this.ordenacao = 'autoincSvr';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('autoincSvr', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('dataentSvr', 'Data de entrada', TipoFiltro.Data);

    if (!Sessao.isAcessoCliente()) {
      this.addFiltro('clienteSvr', 'Codigo do cliente', TipoFiltro.Inteiro);
      this.addFiltro('razaoCli', 'Razão social', TipoFiltro.String);
    }
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.autoincSvr;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `<div class="mt-2">${registro.areaSvr}</div>
    		 <p class="text-caption font-weight-light">${registro.nomeSis} | ${
      registro.descricaoMod
    } | ${registro.descricaoStserv}</p>
         <p class="text-caption">${DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
           registro.dataentradaSvr,
         )} | ${registro.nomeAtnIni}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaServico };

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Contato } from '../Contato/Contato';
import { Atendente } from '../Atendente/Atendente';
import { Sessao } from '../Autenticacao/Sessao';
import { AtividadeRealizacao } from './AtividadeRealizacao';

import LocalAtendimento from '@/proxy-api/src/enumerations/LocalAtendimento';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';
import { HoraUtils } from '@/proxy-api/src/utils/HoraUtils';

class AtividadesProjeto extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Contato();
    this.atendente = new Atendente();

    this.dataDaVisita = new Date().toLocaleDateString();

    this.horarioDeEntrada = '';
    this.horarioDeSaida = '';
    this.horarioDeAlmocoEntrada = '';
    this.horarioDeAlmocoSaida = '';

    this.localDoAtendimento = LocalAtendimento.CLIENTE;

    this.atividades = [];
    this.atividadesExcluidas = [];

    this.observacoesGerais = '';

    if (Sessao.usuario) {
      this.atendente.codigo = Sessao.usuario.codigo;
      this.atendente.descricao = Sessao.usuario.descricao;
    }
  }

  set json(dados) {
    this.codigo = dados.codigoPrr;

    this.cliente.codigo = dados.clientePrr;
    this.cliente.descricao = dados.razaoCli;

    this.contato.codigo = dados.contatoPrr;
    this.contato.descricao = dados.nomeClc;

    this.dataDaVisita = DataUtils.formatarDataParaDiaMesAno(dados.dataPrr);

    this.horarioDeEntrada = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horainicioPrr,
    );
    this.horarioDeSaida = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horaterminoPrr,
    );
    this.horarioDeAlmocoEntrada = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horainicioalmocoPrr,
    );
    this.horarioDeAlmocoSaida = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.horaterminoalmocoPrr,
    );
    this.tempoDeslocamento = DataUtils.formatarTimeZoneParaHoraMinuto(
      dados.tempodeslocamento_prr,
    );

    this.observacoesGerais = dados.observacoesPrr;
    this.localDoAtendimento =
      Number(dados.localexecucaoPrr) === 0
        ? LocalAtendimento.CLIENTE
        : LocalAtendimento.TEKSYSTEM;

    this.atividades = dados.projetoTarefaRealizacao.map(registro => {
      const atividadeRealizacao = new AtividadeRealizacao();
      atividadeRealizacao.json = registro;

      return atividadeRealizacao;
    });
  }

  get json() {
    const atividades = this.atividades.map(atividade => {
      return atividade.json;
    });

    const atividadesExcluidas = this.atividadesExcluidas.map(atividade => ({
      ...atividade.json,
      objectState: 'DELETED',
    }));

    const dados = {
      codigoPrr: this.codigo,
      clientePrr: this.cliente.codigo,
      contatoPrr: this.contato.codigo,
      dataPrr: DataUtils.formatarDataParaAnoMesDia(this.dataDaVisita),
      horainicioPrr: this.horarioDeEntrada,
      horaterminoPrr: this.horarioDeSaida,
      horainicioalmocoPrr: this.horarioDeAlmocoEntrada,
      horaterminoalmocoPrr: this.horarioDeAlmocoSaida,
      tempodeslocamento_prr: this.tempoDeslocamento,
      localexecucaoPrr: this.localDoAtendimento,
      projetoTarefaRealizacao: [...atividades, ...atividadesExcluidas],
      observacoesPrr: this.observacoesGerais,
    };

    if (this.horarioDeSaida) {
      dados.funcionarioPrr = this.atendente.codigo;
    }

    return dados;
  }
}

export { AtividadesProjeto };

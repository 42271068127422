<template>
  <t-form navigationIcon="">
    <t-container align-items="center" class="px-2">
      <v-card class="elevation-4 my-6 mx-auto py-2 px-1" width="700">
        <v-card-title>
          <v-icon>mdi-close-circle</v-icon>
          <span class="ml-2">Error 404!</span>
        </v-card-title>
        <v-card-text>
          <p>Avaliação não encontrada no sistema.</p>
        </v-card-text>
      </v-card>
    </t-container>
  </t-form>
</template>

<script>
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';

export default {
  components: {
    TContainer,
    TForm,
  },
};
</script>

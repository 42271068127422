<template>
  <v-container>
    <v-data-table
      style="cursor: pointer"
      @click:row="abrirPendencia"
      :headers="cabecalhos"
      :items="model.pendencias"
      no-data-text="Sem registros no momento"
      no-results-text="Sem registros no momento"
      hide-default-footer
      :items-per-page="-1"
      :search="filtro"
      sort-by="dataEmDateJS"
      :sort-desc="true"
    >
      <template v-slot:[`item.tipo`]="{ item }">
        <v-tooltip top>
          <template v-slot:activator="{ on }">
            <v-chip :color="item.cor" dark v-on="on">{{ item.tipo }}</v-chip>
          </template>
          <span>{{ item.descricaoTipo }}</span>
        </v-tooltip>
      </template>

      <template v-slot:[`item.dataEmDateJS`]="{ item }">
        <span>{{ item.dataEmDateJS.toLocaleDateString('pt-BR') }}</span>
      </template>
    </v-data-table>
  </v-container>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import { ConsultaAtendimento } from '@/proxy-api/src/services/classes/Atendimento/ConsultaAtendimento';
import { ConsultaEvento } from '@/proxy-api/src/services/classes/Evento/ConsultaEvento';
import { ConsultaServico } from '@/proxy-api/src/services/classes/Servico/ConsultaServico';
import { ConsultaProposta } from '@/proxy-api/src/services/classes/Proposta/ConsultaProposta';
import { ConsultaProjeto } from '@/proxy-api/src/services/classes/Projeto/ConsultaProjeto';
import { ConsultaSolicitacao } from '@/proxy-api/src/services/classes/Solicitacao/ConsultaSolicitacao';

import FormAtendimento from '@/views/atendimento/form/FormAtendimento';
import FormEvento from '@/views/evento/form/FormEvento';
import FormServico from '@/views/servico/form/FormServico';
import FormProposta from '@/views/proposta/form/FormProposta';
import FormProjeto from '@/views/projeto/form/FormProjeto';
import FormSolicitacao from '@/views/solicitacao/form/FormSolicitacao';

import TLoading from 'tek-components-vuejs/src/loading/TLoading';

export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
    filtro: {
      type: String,
      required: true,
    },
  },

  computed: {
    cabecalhos: function () {
      return [
        { text: 'TIPO', sortable: true, value: 'tipo' },
        { text: 'CÓDIGO', sortable: true, filterable: false, value: 'codigo' },
        {
          text: 'DESCRIÇÃO',
          sortable: true,
          filterable: false,
          value: 'descricao',
        },
        { text: 'STATUS', sortable: true, filterable: false, value: 'status' },
        {
          text: 'DATA DE INCLUSÃO',
          sortable: true,
          filterable: false,
          value: 'dataEmDateJS',
        },
      ];
    },
  },

  methods: {
    async abrirPendencia(pendencia) {
      TLoading.show(`Carregando ${pendencia.descricaoTipo}...`);

      const { consulta, form } = this.ConsultaFomulario(pendencia.tipo);
      try {
        await consulta.buscarRegistro(pendencia.codigo);
        const instancia = TFormUtil.instanciar(form);
        instancia.model.json = consulta.registros[0];
        TFormUtil.abrir(instancia);
      } catch (error) {
        TMessageDialog.error().message(error.message).show();
      } finally {
        TLoading.hide();
      }
    },

    ConsultaFomulario(tipo) {
      switch (tipo) {
        case 'ATE':
          return {
            consulta: new ConsultaSolicitacao(),
            form: FormSolicitacao,
          };
        case 'ATI':
          return { consulta: new ConsultaAtendimento(), form: FormAtendimento };
        case 'EVE':
          return { consulta: new ConsultaEvento(), form: FormEvento };
        case 'SER':
          return { consulta: new ConsultaServico(), form: FormServico };
        case 'PRP':
          return { consulta: new ConsultaProposta(), form: FormProposta };
        case 'PRO':
          return { consulta: new ConsultaProjeto(), form: FormProjeto };
      }
    },
  },
};
</script>

<style scoped>
.row-pointer > .v-data-table__wrapper > table > tbody > tr:hover {
  cursor: pointer;
}
</style>

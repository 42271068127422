import { render, staticRenderFns } from "./Pendencias.vue?vue&type=template&id=61013c01&scoped=true&"
import script from "./Pendencias.vue?vue&type=script&lang=js&"
export * from "./Pendencias.vue?vue&type=script&lang=js&"
import style0 from "./Pendencias.vue?vue&type=style&index=0&id=61013c01&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "61013c01",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VChip } from 'vuetify/lib/components/VChip';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VChip,VContainer,VDataTable,VTooltip})

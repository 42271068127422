import { Cadastro } from '../../bases/Cadastros/Cadastro';

class TipoAtendimento extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.codigoTat;
    this.descricao = dados.descricaoTat;
    this.ativo = dados.ativoTat;
  }
}

export { TipoAtendimento };


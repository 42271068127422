import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

import { Sessao } from '../Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

Sessao.json = Storage.getSessao();

class ConsultaProposta extends ConsultaController {
  constructor() {
    super('proposta', 'Proposta', 'codigoPro');

    this.ordenacao = 'codigoPro';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoPro', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('dtaceitePro', 'Data aceite', TipoFiltro.Data);

    if (!Sessao.isAcessoCliente()) {
      this.addFiltro('clientePro', 'Codigo do cliente', TipoFiltro.Inteiro);
      this.addFiltro('nomeclientePro', 'Razão social', TipoFiltro.String);
    }
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoPro;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `
        <div class="mt-2">${registro.descricaoPro}</div> 
        <p class="text-caption font-weight-light">${DataUtils.formatarDataParaDiaMesAno(registro.dataPro)} | ${registro.descricaoStprop}
      `

  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) { }
}

export { ConsultaProposta };

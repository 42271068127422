<template>
  <div>
    <t-form
      :title="`Avaliação de ${
        model.tipo.charAt(0).toUpperCase() + model.tipo.slice(1)
      }`"
      navigationIcon=""
      :processamento="processamento"
    >
      <t-empty-state
        class="mt-12"
        v-if="avaliacaoExpirada"
        text="Avaliação expirada"
        :detail="`A data limite para avaliar este ${model.tipo} foi ${model.expiracao}`"
        icon="mdi-clock"
      />
      <t-container v-else class="text-center mt-6">
        <v-card class="elevation-4 mx-auto py-2" width="700">
          <div justify="center" align="center" class="fill-height mb-8">
            <v-img
              src="@/assets/teksystem.png"
              max-height="150"
              max-width="150"
            />
          </div>
          <v-card-text class="text-h6">
            Avalie o {{ model.tipo }} prestado pela Tek-System<br />
            Aproveite e deixe seu comentário.
          </v-card-text>

          <v-card-text>
            {{ model.descricao }}
          </v-card-text>

          <div class="text-center mt-4">
            <v-rating
              v-model="model.nota"
              hover
              large
              color="yellow darken-3"
            ></v-rating>
            <p class="text-caption">({{ model.nota || 0 }}/5)</p>
          </div>

          <t-memo-edit
            class="mt-7 mb-9 px-8"
            :placeholder="`Seu comentário em relação ao ${model.tipo}`"
            label="Comentário"
            v-model="model.comentario"
          ></t-memo-edit>

          <v-checkbox
            class="ml-6 mb-2"
            v-if="model.tipo === 'atendimento'"
            v-model="model.resolvido"
            label="O problema foi resolvido"
          ></v-checkbox>

          <v-btn
            color="primary"
            absolute
            bottom
            right
            @click="avaliar"
            ref="avaliarButton"
          >
            Avaliar
          </v-btn>
        </v-card>
      </t-container>
    </t-form>
  </div>
</template>

<script>
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit.vue';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TForm from 'tek-components-vuejs/src/form/TForm';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { Avaliacao } from '../../proxy-api/src/services/classes/Avaliacao/Avaliacao';
import { DataUtils } from '../../proxy-api/src/utils/DataUtils';
import { AvaliacaoController } from '../../proxy-api/src/services/classes/Avaliacao/AvaliacaoController';
import AvaliacaoRespondida from './AvaliacaoRespondida';
import AvaliacaoNaoEncontrada from './AvaliacaoNaoEncontrada';

export default {
  name: 'AvaliacaoAtendimento',
  components: {
    TMemoEdit,
    TContainer,
    TForm,
    TEmptyState,
  },

  data() {
    return {
      model: new Avaliacao(),
      avaliacaoExpirada: false,
      processamento: '',
      controller: new AvaliacaoController(),
    };
  },

  async mounted() {
    try {
      this.processamento = 'Carregando avaliação...';
      const credenciaisBase64 = this.$route.params.credenciais;
      this.model.json = credenciaisBase64;
      await this.buscarAvaliacao();
    } catch (error) {
      console.error(error);
      this.avaliacaoNaoEncontrada();
    } finally {
      this.verificaDataLimiteAvalicao();
      this.processamento = '';
    }
  },

  methods: {
    async buscarAvaliacao() {
      const avaliacao = await this.controller.buscarAvaliacao({
        codigo: this.model.codigo,
        tipoPessoa: this.model.tipoPessoa,
        rota: this.model.rota(),
        codigoPessoa: this.model.codigoPessoa,
      });

      if (avaliacao) this.model.setAvaliacao(avaliacao);
    },

    verificaDataLimiteAvalicao() {
      if (DataUtils.menorQueDataAtual(this.model.expiracao)) {
        this.avaliacaoExpirada = true;
      }
    },

    avaliacaoNaoEncontrada() {
      const instancia = TFormUtil.instanciar(AvaliacaoNaoEncontrada);
      instancia.tipo = this.model.tipo;
      TFormUtil.abrir(instancia);
    },

    avaliacaoRepondida() {
      const instancia = TFormUtil.instanciar(AvaliacaoRespondida);
      instancia.model = this.model;
      TFormUtil.abrir(instancia);
    },

    async avaliar() {
      this.processamento = 'Enviando avaliação...';
      try {
        const response = await this.controller.avaliar(
          this.model.json,
          this.model.tipo,
        );
        this.avaliacaoRepondida();
      } catch (error) {
        TMessageDialog.error().message(`${error.message}`).show();
      } finally {
        this.processamento = '';
      }
    },
  },
};
</script>

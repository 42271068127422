<template>
  <form-cadastro
    :title="`Serviço ${model.codigo ? model.codigo : ''}`"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Principal </v-tab>
        <v-tab> Cliente </v-tab>
        <v-tab> Descrição </v-tab>
        <v-tab> Justificativa </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-principal :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>

      <v-tab-item>
        <aba-cliente :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>

      <v-tab-item>
        <aba-descricao :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>

      <v-tab-item>
        <aba-justificativa
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { Servico } from '@/proxy-api/src/services/classes/Servico/Servico';
import { ServicoController } from '@/proxy-api/src/services/classes/Servico/ServicoController';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';

import AbaPrincipal from './components/AbaPrincipal';
import AbaCliente from './components/AbaCliente';
import AbaDescricao from './components/AbaDescricao';
import AbaJustificativa from './components/AbaJustificativa';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
    AbaPrincipal,
    AbaCliente,
    AbaDescricao,
    AbaJustificativa,
  },

  mounted() {
    this.removerAcoesDoUsuario();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new Servico(),
      controller: new ServicoController(),
      abaSelecionada: 0,
    };
  },

  methods: {
    removerAcoesDoUsuario() {
      this.$refs.form.actionItems = [];
    },
  },
};
</script>

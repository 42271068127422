<template>
  <div style="height: 100%; overflow: auto">
    <v-data-table
      class="px-8 py-4"
      :headers="headers"
      :items="model.atividades"
      no-data-text="Sem registros no momento"
      no-results-text="Sem registros no momento"
      hide-default-footer
      disable-pagination
      sort-by="nivel"
    >
    </v-data-table>
  </div>
</template>

<script>
export default {
  props: {
    model: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      headers: [
        {
          text: 'Nível',
          value: 'nivel',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Status',
          value: 'descricaoStatus',
        },
        {
          text: 'Realização',
          value: 'percentualRealizado',
        },
      ],
    };
  },
};
</script>

<style></style>

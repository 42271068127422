<template>
  <v-row justify="center">
    <v-dialog v-model="model" max-width="500">
      <v-card>
        <v-card-title class="text-h6">
          Revogar inscrição na lista de e-mail.
        </v-card-title>
        <t-memo-edit
          ref="motivo"
          class="mt-2 mx-1 px-4"
          placeholder="Informe por favor o motivo da revogação."
          label="Motivo"
          v-model="motivo"
        ></t-memo-edit>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="red" text @click="$emit('fecharModal')">
            Cancelar
          </v-btn>
          <v-btn
            color="green darken-1"
            text
            @click="$emit('enviarRevogacao', motivo)"
            :disabled="this.motivo < 2"
          >
            Enviar
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit.vue';

export default {
  components: { TMemoEdit },
  data: function () {
    return {
      model: {},
      motivo: '',
    };
  },
  methods: {
    enviar() {},
  },
};
</script>

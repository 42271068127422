<template>
  <t-form
    ref="form"
    :title="model.descricao"
    :actionItems="botaoDownload"
    @click-navigation="$emit('click-navigation')"
  >
    <v-row align="center" justify="center">
      <visualizador-imagem
        v-if="model.tipo === 'IMAGEM'"
        :src="model.arquivoEncoded"
      />
      <visualizador-pdf
        v-else-if="model.tipo === 'PDF'"
        :src="model.arquivoEncoded"
      />
      <visualizador-txt
        v-else-if="model.tipo === 'TEXTO'"
        :src="base64ToUtf8()"
      />
      <visualizador-video v-else-if="model.tipo === 'VIDEO'" :model="model" />

      <arquivo-nao-suportado
        v-else
        :model="model"
        @downloadArquivo="downloadArquivo"
      />
    </v-row>
  </t-form>
</template>

<script>
import TForm from 'tek-components-vuejs/src/form/TForm';
import VisualizadorImagem from './VisualizadorImagem.vue';
import VisualizadorPdf from './VisualizadorPdf.vue';
import VisualizadorTxt from './VisualizadorTxt.vue';
import VisualizadorVideo from './VisualizadorVideo.vue';
import ArquivoNaoSuportado from './ArquivoNaoSuportado.vue';

export default {
  components: {
    TForm,
    VisualizadorImagem,
    VisualizadorPdf,
    VisualizadorTxt,
    VisualizadorVideo,
    ArquivoNaoSuportado,
  },

  data() {
    return {
      model: {},
    };
  },

  methods: {
    downloadArquivo() {
      this.$emit('downloadArquivo', this.model);
    },

    base64ToUtf8() {
      return Buffer.from(this.model.arquivoEncoded, 'base64');
    },
  },

  computed: {
    botaoDownload() {
      return [
        {
          icon: 'mdi-download',
          text: 'Fazer download do arquivo',
          available: this.model.tipo !== 'ARQUIVO',
          callback: this.downloadArquivo,
        },
      ];
    },
  },
};
</script>

<style></style>

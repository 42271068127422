import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaAtendimentoPresencial extends ConsultaController {
  constructor() {
    super('atend_externo', 'Atendimentos Presenciais', 'autoincSai');

    this.ordenacao = 'autoincSai';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('autoincSai', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String);
    this.addFiltro('datadigSai', 'Data da Visita', TipoFiltro.Data);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.autoincSai;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return `
    		 <div class="mt-2">${registro.razaoCli}</div> 
    		 <p class="text-caption font-weight-light">${
           registro.nomeClc
         } |${DataUtils.formatarDataParaDiaMesAno(registro.datadigSai)}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaAtendimentoPresencial };

import VIM from 'vue-instance-manager';

import vuetify from '@/plugins/vuetify';

export class TFormUtil {
  static instanciar(componente) {
    return VIM.instance(componente, {
      vuetify,
    });
  }

  static abrir(instancia) {
    VIM.open(instancia, '#app .v-application--wrap', "aposFechar");
  }

  static fechar(instancia) {
    VIM.close(instancia)
  }
}

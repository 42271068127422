<template>
  <div style="height: 100%; overflow: auto">
    <v-data-table
      style="cursor: pointer"
      class="px-8 py-4"
      @click:row="abrirRelacionamento"
      :headers="headers"
      :loading="loading"
      :items="relacionamentos"
      no-data-text="Sem registros no momento"
      no-results-text="Sem registros no momento"
      hide-default-footer
      disable-pagination
    >
    </v-data-table>
  </div>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import ExibirMensagem from './ExibirMensagem';
import { Relacionamento } from '../proxy-api/src/services/classes/Relacionamentos/Relacionamento';
import { Operacao } from '../proxy-api/src/utils/Operacao';

export default {
  components: {
    TCard,
    TContainer,
  },

  props: {
    model: {
      type: Object,
      required: true,
    },
    controller: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      relacionamentos: [],
      loading: false,
      headers: [
        {
          text: 'Tipo',
          value: 'tipo',
        },
        {
          text: 'Código',
          sortable: true,
          value: 'codigo',
        },
        {
          text: 'Descrição',
          value: 'descricao',
        },
        {
          text: 'Data Inicial',
          value: 'data1',
        },
        {
          text: 'Data Final',
          value: 'data2',
        },
        {
          text: 'Observação',
          value: 'observacao',
        },
        {
          text: 'Nº Pessoas Envolvidas',
          value: 'envolvidos',
        },
        {
          text: 'Tempo',
          value: 'tempo',
        },
      ],
    };
  },

  methods: {
    abrirRelacionamento(item) {
      const operacao = new Operacao();

      operacao.abrir({
        codigo: item.codigo,
        tipo: item.tipo,
      });
    },
  },

  async mounted() {
    try {
      this.loading = true;
      const response = await this.controller.buscar(this.model.codigo);

      if (response) {
        response.map(registro => {
          const relacionamento = new Relacionamento();
          relacionamento.json = registro;
          this.relacionamentos.push(relacionamento);
        });
      }
    } catch (error) {
      ExibirMensagem.erroGenerico(error);
    } finally {
      this.loading = false;
    }
  },
};
</script>

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';
import { Sessao } from '../Autenticacao/Sessao';

class ConsultaAtendimento extends ConsultaController {
  constructor() {
    super('atendimento', 'Atendimentos', 'codigoAtd');

    this.ordenacao = 'codigoAtd';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoAtd', 'Código', TipoFiltro.Inteiro, true);
    this.addFiltro('razaoCli', 'Razão Social', TipoFiltro.String);
    this.addFiltro('dthrinclusaoAtd', 'Data do Atendimento', TipoFiltro.Data);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoAtd;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    if (!Sessao.isAcessoCliente()) {
      return `
    		 <div class="mt-2">${registro.razaoCli}</div> 
    		 <p class="text-caption font-weight-light">${registro.nomeSis} | ${
        registro.descricaoMod
      } | ${registro.datainicioAtd.split('-').reverse().join('/')}</p>
         <p class="text-caption font-weight-light mb-0">Situação: ${
           registro.descricaoSit
         }</p>
         <p class="text-caption font-weight-light mb-0">Contato: ${
           registro.nomeClc
         }</p>
        `;
    }

    return `
    			<div class="mt-2"> ${registro.nomeClc}  </div> 
    		 	<p class="text-caption font-weight-light">${registro.nomeSis} | ${registro.descricaoMod}</p>
          <p class="text-caption font-weight-light mb-0">Situação: ${registro.descricaoSit}</p>`;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaAtendimento };

<template>
  <t-container>
    <t-card title="Descrição do Atendimento">
      <v-textarea
        v-model="model.descricaoDoAtendimento"
        :readonly="modoVisualizacao"
        label="Descrição"
        persistent-placeholder
        outlined
        :rows="15"
        no-resize
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';

export default {
  components: { TCard, TContainer, TMemoEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {};
  },
};
</script>

import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

class TMenuItem {
  // --------------------------------------
  // Resolve import
  // --------------------------------------
  static async resolveImport() {
    const module = await Promise.resolve(this.import());
    return module.default;
  }

  // --------------------------------------
  // Get instancia
  // --------------------------------------
  static async getInstancia() {
    const form = await this.resolveImport();
    return TFormUtil.instanciar(form);
  }

  // --------------------------------------
  // Import
  // --------------------------------------
  static import() {}

  // --------------------------------------
  // Antes de abrir
  // --------------------------------------
  static async antesAbrir(instancia) {}

  // --------------------------------------
  // Abrir
  // --------------------------------------
  static async abrir() {
    const instancia = await this.getInstancia();

    await this.antesAbrir(instancia);
    TFormUtil.abrir(instancia);
    await this.aposAbrir(instancia);
  }

  // --------------------------------------
  // apos de abrir
  // --------------------------------------
  static async aposAbrir(instancia) {
    instancia.$on('fechar', () => {
      TFormUtil.fechar(instancia);
    });
  }
}

export { TMenuItem };

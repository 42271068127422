<template>
  <t-container vertical-scroll height="100%">
    <t-card>
      <v-textarea
        v-model="model.relatadoPeloCliente"
        label="Ocorrido"
        :readonly="modoVisualizacao"
        outlined
        :rows="15"
        persistent-placeholder
      />
      <v-textarea
        v-if="
          model.solucaoDoAtendimento !== null &&
          String(model.solucaoDoAtendimento)
        "
        v-model="model.solucaoDoAtendimento"
        label="Solução"
        readonly
        outlined
        persistent-placeholder
      />
    </t-card>
  </t-container>
</template>

<script>
import TCard from 'tek-components-vuejs/src/card/TCard';
import TMemoEdit from 'tek-components-vuejs/src/edit/TMemoEdit';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TMemoEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },
};
</script>

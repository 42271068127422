<template>
  <t-container>
    <t-card>
      <search-edit
        :controller="consultaCliente"
        :model="model.cliente"
        label="CLIENTE"
        :disabled="habilitaTrocarCliente()"
      ></search-edit>

      <t-upper-edit
        v-if="!sessao.isAcessoCliente()"
        :disabled="modoVisualizacao || sessao.isAcessoContato()"
        v-model="model.descricao"
        color="primary"
        label="NOME"
        outlined
      />

      <t-upper-edit
        v-else
        :disabled="model.codigo > 0"
        v-model="model.descricao"
        color="primary"
        label="NOME"
        outlined
      />

      <TCPFEdit
        v-model="model.cpf"
        label="CPF"
        :disabled="modoVisualizacao"
      ></TCPFEdit>

      <v-row dense>
        <v-col cols="12" sm="6">
          <t-email-edit
            :disabled="modoVisualizacao"
            v-model="model.email"
            label="E-MAIL"
            color="primary"
          />
        </v-col>

        <v-col cols="12" sm="6">
          <t-telefone-edit
            :disabled="modoVisualizacao"
            v-model="model.telefone"
            label="TELEFONE"
            color="primary"
          />
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import { ConsultaCliente } from '@/proxy-api/src/services/classes/Cliente/ConsultaCliente';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TUpperEdit from 'tek-components-vuejs/src/edit/TUpperEdit';
import TEmailEdit from 'tek-components-vuejs/src/edit/TEmailEdit';
import TTelefoneEdit from 'tek-components-vuejs/src/edit/TTelefoneEdit';
import TCPFEdit from 'tek-components-vuejs/src/edit/TCPFEdit';

export default {
  components: {
    TCard,
    TContainer,
    TUpperEdit,
    SearchEdit,
    TEmailEdit,
    TTelefoneEdit,
    TCPFEdit,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  data: function () {
    return {
      consultaCliente: new ConsultaCliente(),
      sessao: Sessao,
      desabilita: true,
    };
  },

  methods: {
    habilitaTrocarCliente() {
      return this.sessao.isAcessoCliente() || this.model.codigo ? true : false;
    },
  },
};
</script>

import { api } from '../../api';
import { Controller } from '../../bases/Controllers/Controller';

class RevogacaoController extends Controller {
  constructor() {
    super('revogacao');
  }

  async carregarListasDisponiveis(dados) {
    try {
      const { data } = await api.get('lista_publicacao/listasdisponiveis', {
        headers: {
          Codigo: dados.codigo,
          Tipo: dados.tipo
        }
      })
      return data
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async carregarListasPertencentes(dados) {
    try {
      const { data } = await api.get('lista_publicacao/listaspertencentes', {
        headers: {
          Codigo: dados.codigo,
          Tipo: dados.tipo
        }
      })
      return data
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async carregarListas(dados) {
    //Listas que o destinatário fez ou faz parte
    let listasPertencentes = await this.carregarListasPertencentes(dados)

    //Todas as listas disponíveis
    let listasDisponiveis = await this.carregarListasDisponiveis(dados)

    //Array com as listas localmente que vai ser enviada para o model
    let listas = [];

    /* Varre as listas disponíveis e pertencentes e monta o array 'listas' 
    com propriedade 'pertence' true ou false */
    listasDisponiveis = listasDisponiveis.map(listaDisponivel => {
      const listaPertencente = listasPertencentes.find(listaPertencente =>
        listaPertencente.codigo === listaDisponivel.codigo
      )

      if (listaPertencente) {
        if (listaPertencente.revogacao) {
          listas.push({ ...listaPertencente, pertence: false });
        }
        else
          listas.push({ ...listaPertencente, pertence: true });
      }
      else
        listas.push({ ...listaDisponivel, pertence: false });
    })

    return { listas }
  }


  async revogarInscricao(dados) {
    if (!dados.Lista) throw new Error('Lista inválida');
    if (!dados.Tipo) throw new Error('Tipo inválido');
    if (!dados.Codigo) throw new Error('Código inválido');
    if (!dados.Motivo) throw new Error('Motivo inválido');

    try {
      const { data } = await api.post('lista_publicacao/revogarlista', dados)
      return data
    }
    catch (error) {
      throw new Error(error.message)
    }
  }

  async inscrever(dados) {
    if (!dados.Lista) throw new Error('Lista inválida');
    if (!dados.Tipo) throw new Error('Tipo inválido');
    if (!dados.Codigo) throw new Error('Código inválido');
    const { Lista, Tipo, Codigo } = dados

    try {
      const { data } = await api.post('lista_publicacao/ingressarlista', { Lista, Tipo, Codigo })
      return data
    }
    catch (error) {
      throw new Error(error.message)
    }
  }
}
export { RevogacaoController }
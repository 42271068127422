import { api } from '../../api';
import { Controller } from '../../bases/Controllers/Controller';

class AvaliacaoController extends Controller {
  constructor() {
    super('avaliacaoController');
  }

  async avaliar(dados) {
    if (dados.Nota === 0) throw new Error('<b>Nota</b> precisa ser preenchida');

    const rota = dados.rota;
    delete dados.rota;

    try {
      const { data } = await api.post(`${rota}/avaliar`, dados);
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async buscarAvaliacao({ codigo, rota, tipoPessoa, codigoPessoa }) {
    if (!rota) throw new Error('Rota para buscar avaliação não informada');

    if (!codigo) throw new Error('Código para buscar avaliação não informado');

    try {
      const { data } = await api.get(`${rota}/avaliacao`, {
        headers: {
          Codigo: codigo,
          TipoPessoa: tipoPessoa,
          Pessoa: codigoPessoa,
        },
      });
      return data;
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { AvaliacaoController };

<template>
  <div>
    <t-container
      v-if="model.atividades.length > 0"
      vertical-scroll
      height="100%"
    >
      <t-card
        v-for="(atividade, index) in model.atividades"
        :key="index"
        class="relative"
      >
        <atividade
          @excluir="excluirAtividade(atividade)"
          :atividade="atividade.atividadeProjeto"
          :modo-visualizacao="modoVisualizacao"
        />
      </t-card>
    </t-container>

    <t-container v-else align-items="center" height="100%">
      <slot name="empty-state">
        <t-empty-state
          text="Nenhuma atividade foi encontrada"
          detail="Não foram preenchidas nenhuma atividade"
          icon="mdi-text-box-plus-outline"
          :buttonLabel="modoVisualizacao ? '' : 'ADICIONAR'"
          :buttonCallback="abrirConsulta"
        />
      </slot>
    </t-container>

    <v-tooltip left>
      <template v-slot:activator="{ on, attrs }">
        <v-btn
          @click="abrirConsulta"
          v-bind="attrs"
          v-on="on"
          :disabled="modoVisualizacao"
          color="accent"
          id="lateral"
          dark
          absolute
          bottom
          right
          fab
          fixed
        >
          <v-icon>mdi-plus</v-icon>
        </v-btn>
      </template>
      <span>Adicionar solicitação</span>
    </v-tooltip>
  </div>
</template>

<script>
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { ConsultaAtividadeProjeto } from '@/proxy-api/src/services/classes/Projeto/ConsultaAtividadeProjeto';
import { FiltroConsulta } from '@/proxy-api/src/services/bases/Consulta/FiltroConsulta';
import { AtividadeRealizacao } from '@/proxy-api/src/services/classes/Projeto/AtividadeRealizacao';

import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import Atividade from './Atividade';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';
import FormConsulta from '@/views/consultas/FormConsulta';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: {
    TCard,
    TContainer,
    Atividade,
    TEmptyState,
  },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  watch: {
    $props: {
      handler() {
        this.verificarFiltroCliente();
      },
      deep: true,
      immediate: true,
    },
  },

  async mounted() {
    this.inicializarFiltros();
  },

  data: function () {
    return {
      consultaAtividadeProjeto: new ConsultaAtividadeProjeto(),
      filtroCliente: new FiltroConsulta('clienteProj'),
      filtroCabecalho: new FiltroConsulta('cabecalhoPrt'),
    };
  },

  methods: {
    inicializarFiltros() {
      this.setFiltroCliente();
      this.setFiltroCabecalho();
    },

    setFiltroCliente() {
      this.filtroCliente.valor = this.model.cliente.codigo;

      this.consultaAtividadeProjeto.filtrosFixo.push(this.filtroCliente);
    },

    setFiltroCabecalho() {
      this.filtroCabecalho.valor = 'N';

      this.consultaAtividadeProjeto.filtrosFixo.push(this.filtroCabecalho);
    },

    abrirConsulta() {
      let instancia = TFormUtil.instanciar(FormConsulta);

      instancia.controller = this.consultaAtividadeProjeto;
      instancia.model = this.model;
      instancia.formCadastro = this.formCadastro;
      instancia.fechaAposSelecionar = true;

      TFormUtil.abrir(instancia);

      instancia.$on('selecionado', registro => {
        this.setRegistro(registro);

        TFormUtil.fechar(instancia);
      });
    },

    setRegistro(registro) {
      const atividadeRealizacao = new AtividadeRealizacao();

      atividadeRealizacao.atividadeProjeto.json = registro;

      const existeAtividade = this.model.atividades.some(
        atividade =>
          Number(atividade.atividadeProjeto.codigo) ===
          Number(atividadeRealizacao.atividadeProjeto.codigo),
      );

      if (existeAtividade) {
        TMessageDialog.error()
          .title('Atividade Duplicada')
          .message(
            'Atividade selecionada já está inserida no projeto, por favor escolha outra.',
          )
          .show();
        return;
      }

      this.model.atividades.push(atividadeRealizacao);
      this.codigoAtividadeSelecionada =
        atividadeRealizacao.atividadeProjeto.codigo;
    },

    verificarFiltroCliente() {
      if (this.model.cliente.codigo > 0) {
        this.filtroCliente.valor = this.model.cliente.codigo;
        return;
      }

      this.filtroCliente.valor = this.sessao.usuario.cliente.codigo;
    },

    excluirAtividade(atividade) {
      if (this.model.atividades.length === 1) {
        TMessageDialog.error()
          .title('Última Atividade vinculada')
          .message(
            'É necessário ter pelo menos uma atividade vinculada ao projeto.',
          )
          .show();
        return;
      }

      this.model.atividades = this.model.atividades.filter(
        registro =>
          Number(registro.atividadeProjeto.codigo) !==
          Number(atividade.atividadeProjeto.codigo),
      );

      this.model.atividadesExcluidas.push(atividade);
    },
  },
};
</script>

<style scoped>
.card-titulo {
  font-size: 14px;
}
</style>

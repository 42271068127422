import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { ListaPublicacao } from './ListaPublicacao'

class Revogacao extends Cadastro {
  constructor(persistente) {
    super();
    this.codigoDestinatario = ''
    this.tipoDestinatario = '';
    this.lista = '';
    this.listas = [];
    this.motivo = ''
  }

  set json(dados) {
    this.codigoDestinatario = dados.codigo;
    this.tipoDestinatario = dados.tipo;
    this.lista = dados.lista;

    this.listas = [].concat(dados.listas.map(lista => {
      let listaObjeto = new ListaPublicacao()
      listaObjeto.json = lista
      return listaObjeto
    }));
  }

  get json() {
    return {
      Lista: this.lista.toString(),
      Tipo: this.tipoDestinatario,
      Codigo: this.codigoDestinatario,
      Motivo: this.motivo
    }
  }

}

export { Revogacao }
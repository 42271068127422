import { Enumeration } from '@jslibrary/enumeration';
import TipoFiltro from './TipoFiltro';

class CondicaoFiltro extends Enumeration {
  constructor() {
    super();

    this.add('IGUAL', 'IgualA', 'Igual a');
    this.add('DIFERENTE', 'DiferenteDe', 'Diferente de');
    this.add('INICIADO', 'IniciadoCom', 'Iniciado com');
    this.add('CONTEM', 'QueContem', 'Que contém');
    this.add('TERMINADO', 'TerminadoCom', 'Terminado com');
    this.add('MENOR', 'MenorQue', 'Menor que');
    this.add('MAIOR', 'MaiorQue', 'Maior que');
    this.add('MENOR IGUAL', 'MenorOuIgualA', 'Menor ou igual a');
    this.add('MAIOR IGUAL', 'MaiorOuIgualA', 'Maior ou igual a');
    this.add('PREENCHIDO', 'Preenchido', 'Preenchido');
  }

  toJSON(tipoFiltro) {
    if (tipoFiltro === TipoFiltro.Inteiro) {
      return this.getCondicoes([
        this.IgualA,
        this.DiferenteDe,
        this.MenorQue,
        this.MaiorQue,
        this.MenorOuIgualA,
        this.MaiorOuIgualA,
        this.Preenchido,
      ]);
    }

    if (tipoFiltro === TipoFiltro.String) {
      return this.getCondicoes([
        this.QueContem,
        this.IniciadoCom,
        this.TerminadoCom,
        this.QueContem,
        this.IgualA,
        this.DiferenteDe,
        this.Preenchido,
      ]);
    }

    if (tipoFiltro === TipoFiltro.Data) {
      return this.getCondicoes([
        this.IgualA,
        this.DiferenteDe,
        this.MenorQue,
        this.MaiorQue,
        this.MenorOuIgualA,
        this.MaiorOuIgualA,
        this.Preenchido,
      ]);
    }

    if (tipoFiltro === TipoFiltro.CPF || tipoFiltro === TipoFiltro.CNPJ) {
      const condicoes = this.getCondicoes([this.IgualA]);

      condicoes[0].value = this.IgualA;

      return condicoes;
    }

    return [];
  }

  getCondicoes(values) {
    const condicoes = [];

    for (const value of values) {
      for (const registro of this.items) {
        if (registro.value === value) {
          condicoes.push(registro);
        }
      }
    }

    return JSON.parse(JSON.stringify(condicoes));
  }
}

export default new CondicaoFiltro();

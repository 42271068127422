<template>
  <form-cadastro
    :title="`Atendimento ${model.codigo ? model.codigo : ''}`"
    @stateChanged="onStateChanged"
    @aposGravar="aposGravar"
    @aposFechar="aposFechar"
    @aposExcluir="aposExcluir"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab :disabled="model.cliente.codigo === 0"> Sistema </v-tab>
        <v-tab :disabled="model.sistema.codigo === 0"> Origem </v-tab>
        <v-tab :disabled="model.origemAtendimento.codigo === 0">
          Ocorrido
        </v-tab>
        <v-tab v-if="model.codigo != 0"> Arquivos Anexados </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :modo-visualizacao="modoVisualizacao"
          :model="model"
          :sessao="sessao"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-sistema
          :modo-visualizacao="modoVisualizacao"
          :model="model"
          :sessao="sessao"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-origem
          :modo-visualizacao="modoVisualizacao"
          :model="model"
          :sessao="sessao"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-ocorrido
          :modo-visualizacao="modoVisualizacao"
          :model="model"
          :sessao="sessao"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-arquivos-anexados
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { Atendimento } from '@/proxy-api/src/services/classes/Atendimento/Atendimento';
import { AtendimentoController } from '@/proxy-api/src/services/classes/Atendimento/AtendimentoController';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaSistema from './components/AbaSistema';
import AbaOrigem from './components/AbaOrigem';
import AbaOcorrido from './components/AbaOcorrido';
import AbaArquivosAnexados from '../../../components/AbaArquivosAnexados/AbaArquivosAnexados.vue';

export default {
  components: {
    AbaIdentificacao,
    AbaSistema,
    AbaOrigem,
    AbaOcorrido,
    AbaArquivosAnexados,
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new Atendimento(true),
      controller: new AtendimentoController(),
      abaSelecionada: 0,
      sessao: new Sessao(),
      itensDeAcao: [
        {
          icon: 'mdi-thumbs-up-down',
          text: 'Avaliar atendimento',
          available: true,
          callback: this.avaliarAtendimento,
        },
      ],
    };
  },

  methods: {
    onStateChanged(emEdicao) {
      this.modoVisualizacao = !emEdicao;
    },

    incluir() {
      this.$refs.form.incluir();
    },

    aposGravar(registro) {
      this.$emit('aposGravar', registro);
      this.abaSelecionada = 0;
    },

    aposExcluir(registro) {
      this.$emit('aposExcluir', registro);
    },

    aposFechar() {
      this.$emit('aposFechar');
    },

    getSessao() {
      Sessao.json = Storage.getSessao();
      this.sessao = Sessao;
    },

    avaliarAtendimento() {
      const base64 = this.model.credenciaisParaAvaliar();
      window.open(`http://${location.host}/#/avaliacao/${base64}`, '_blank');
    },

    verificarPermissao() {
      if (!this.sessao.isAcessoCliente()) {
        return;
      }

      this.$refs.form.actionItems.forEach(action => {
        if (action.text === 'Editar') {
          action.available = false;
        }
        if (action.text === 'Excluir') {
          action.available = false;
        }
      });

      if (this.model.dataFinalAtendimento)
        this.$refs.form.actionItems = [
          ...this.itensDeAcao,
          ...this.$refs.form.actionItems,
        ];
    },

    travaContato() {
      this.model.contato.codigo = this.sessao.usuario.codigoPessoa;
      this.model.contato.descricao = this.sessao.usuario.descricao;
    },
  },

  mounted() {
    this.getSessao();
    this.verificarPermissao();
    if (this.sessao.isAcessoContato()) this.travaContato();
  },
};
</script>

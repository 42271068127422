import { Controller } from '../../bases/Controllers/Controller';
import { ValidarValoresUtil } from '@/proxy-api/src/utils/ValidarValoresUtil';

class AtendimentoController extends Controller {
  constructor() {
    super('atendimento');
  }

  // async validar(registro) {
  //   if (ValidarValoresUtil.maiorQueZero(registro.cliente.codigo))
  //     throw new Error('<b>Cliente</b> não preenchido');

  //   if (ValidarValoresUtil.maiorQueZero(registro.contato.codigo))
  //     throw new Error('<b>Contato</b> não preenchido');

  //   if (ValidarValoresUtil.maiorQueZero(registro.sistema.codigo))
  //     throw new Error('<b>Sistema</b> não preenchido');

  //   if (ValidarValoresUtil.maiorQueZero(registro.origemAtendimento))
  //     throw new Error('<b>Origem do atendimento</b> não preenchida');

  //   if (ValidarValoresUtil.maiorQueZero(registro.tipoAtendimento))
  //     throw new Error('<b>Tipo do atendimento</b> não preenchido');

  //   if (ValidarValoresUtil.maiorQueZero(registro.motivo))
  //     throw new Error('<b>Motivo</b> não preenchido');

  //   if (ValidarValoresUtil.maiorQueZero(registro.relatadoPeloCliente))
  //     throw new Error('<b>Ocorrido</b> não preenchido');
  // }
}

export { AtendimentoController };

import { Controller } from '../../bases/Controllers/Controller';
import { ValidarValoresUtil } from '@/proxy-api/src/utils/ValidarValoresUtil';

class ContatoController extends Controller {
  constructor() {
    super('clientes_contatos');
  }

  async validar(registro) {
    if (ValidarValoresUtil.maiorQueZero(registro.cliente.codigo))
      throw new Error('<b>Cliente</b> não preenchido');

    if (!registro.descricao) throw new Error('<b>Nome</b> não preenchido');

    if (!registro.email) throw new Error('<b>E-mail</b> não preenchido');

    if (
      String(registro.telefone).length > 0 &&
      String(registro.telefone).length < 11
    )
      throw new Error('<b>Telefone</b> não preenchido corretamente');

    if (ValidarValoresUtil.maiorQueZero(registro.setor.codigo))
      throw new Error('<b>Setor</b> não preenchido');
  }
}

export { ContatoController };

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';

class Usuario extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';

    this.cliente = new Cliente();
    this.telefone = '';
  }

  get json() {}

  set json(dados) {
    this.identificador = dados.id;
    this.codigo = dados.codigo;
    this.descricao = dados.nomeCompleto;
    this.email = dados.email;
    this.codigoPessoa = dados.pessoa;
    this.tipoPessoa = dados.tipoPessoa;
    this.cliente.codigo = dados.codigoCliente;
  }
}

export { Usuario };

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Status extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
    this.quantidade = 0;
    this.sigla = '';
    this.icone = '';
    this.cor = '';
  }

  set json(dados) {
    this.descricao = dados.descricao;
    this.quantidade = dados.quantidade;
    this.sigla = dados.sigla;
    this.icone = this.setCorEIcone(this.sigla).icone;
    this.cor = this.setCorEIcone(this.sigla).cor;
  }

  setCorEIcone(sigla) {
    switch (sigla) {
      case 'ATI':
        return { icone: 'mdi-phone-in-talk', cor: '#4BB991' };
      case 'ATE':
        return { icone: 'mdi-account-voice', cor: '#9BB3C7' };
      case 'SER':
        return { icone: 'mdi-account-wrench', cor: '#4B6AB9' };
      case 'PRO':
        return { icone: 'mdi-projector-screen', cor: '#B96C4B' };
      case 'PRP':
        return { icone: 'mdi-notebook', cor: '#B94B94' };
      case 'EVE':
        return { icone: 'mdi-calendar-clock', cor: '#B94B4B' };
    }
  }
}

export { Status };

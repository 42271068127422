<template>
  <v-row justify="center">
    <v-dialog
      v-model="model"
      max-width="450"
      @click:outside="$emit('fechar')"
      v-if="mostrarMenu"
    >
      <v-card>
        <v-col justify="center">
          <opcao-liberacao
            icon="mdi-home"
            title="Trabalho Remoto"
            description="Libera acesso para conectar-se a uma estação de trabalho na Tek-System"
            @click="liberarAcesso('HOME_OFFICE')"
          />
          <opcao-liberacao
            icon="mdi-book"
            title="Treinamentos e Cursos"
            description="Libera acesso ao servidor de estudos para assistir cursos e
              treinamentos da Tek-System"
            @click="liberarAcesso('TREINAMENTO')"
          />
          <opcao-liberacao
            icon="mdi-lan-pending"
            title="Acesso de Cliente"
            description="Libera acesso para cliente ao servidores da Tek-System"
            @click="liberarAcesso('CLIENTE')"
          />
        </v-col>
      </v-card>
    </v-dialog>
  </v-row>
</template>

<script>
import { Sessao } from '../../proxy-api/src/services/classes/Autenticacao/Sessao';

import TForm from 'tek-components-vuejs/src/form/TForm';
import TLoading from 'tek-components-vuejs/src/loading/TLoading';
import OpcaoLiberacao from './OpcaoLiberacao.vue';
import { api } from '../../proxy-api/src/services/api';
import ExibirMensagem from '../../components/ExibirMensagem';

export default {
  components: { TForm, OpcaoLiberacao },
  data() {
    return {
      model: {},
      mostrarMenu: true,
    };
  },

  methods: {
    async liberarAcesso(tipo) {
      this.mostrarMenu = false;
      TLoading.show('Liberando acesso, aguarde...');

      try {
        await Sessao.liberarAcesso(tipo);

        const { data } = await api.get('/remote');

        if (tipo === 'HOME_OFFICE') {
          this.$emit('atualizar');
          ExibirMensagem.sucesso(
            'Trabalho remoto iniciado',
            `Acesso liberado para seu IP externo: ${data.ip}`,
          );
        } else
          ExibirMensagem.sucesso(
            'Acesso liberado',
            `Seu IP externo é ${data.ip}`,
          );
      } catch (error) {
        ExibirMensagem.erroGenerico(error);
      } finally {
        TLoading.hide();
      }
    },
  },
};
</script>

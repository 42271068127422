import { Cadastro } from '../../bases/Cadastros/Cadastro';

class TipoServico extends Cadastro {
  constructor(persistente) {
    super();
    this.descricao = '';
  }

  set json(dados) {
    this.codigo = Number(dados.tipoStp);
    this.descricao = dados.nomeTsv;
  }

  get json() {}
}

export { TipoServico };

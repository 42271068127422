import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { Cliente } from '../Cliente/Cliente';
import { Contato } from '../Contato/Contato';
import { Situacao } from '../Atendimento/Situacao';

import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class Proposta extends Cadastro {
  constructor(persistente) {
    super();

    this.cliente = new Cliente();
    this.contato = new Contato();
    this.status = new Situacao();
  }

  set json(dados) {
    this.codigo = Number(dados.codigoPro);
    this.descricao = dados.descricaoPro;

    this.cliente.codigo = dados.clientePro;
    this.cliente.descricao = dados.nomeclientePro;

    this.status.codigo = dados.statusPro;
    this.status.descricao = dados.descricaoStprop;

    this.contato.codigo = dados.codcontatoPro;
    this.contato.descricao = dados.contatoPro;
    this.contato.email = dados.emailPro;
    this.tipo = dados.descricaoPrt;

    this.dataEntrada = DataUtils.formatarDataParaDiaMesAno(dados.dataPro);
    this.dataFinalizacao = DataUtils.formatarDataParaDiaMesAno(
      dados.dtfinalizacaoPro,
    );

    this.escopo = dados.escopoPro;
  }

  get json() {}
}

export { Proposta };

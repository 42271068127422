import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import { Arquivo } from './Arquivo';
import { api } from '../../api';

class ArquivoController extends ConsultaController {
  constructor(codigoDocumentoOrigemArquivo, origemArquivo) {
    super('arquivo', 'Arquivo', 'codigoArq');

    this.addFiltroFixo('docorigemArq', 'IGUAL', codigoDocumentoOrigemArquivo);
    this.addFiltroFixo('origemArq', 'IGUAL', origemArquivo);
    this.origemArquivo = origemArquivo;
    this.codigoDocumentoOrigemArquivo = codigoDocumentoOrigemArquivo;
  }

  async enviarArquivo(arquivo) {
    if (!arquivo) throw new Error('Arquivo não reconhecido');
    if (arquivo.size > 5000000)
      throw new Error('Arquivo excede o tamanho máximo de 5MB');

    const payload = await this.criaArquivo(arquivo);
    try {
      const { data } = await api.post('arquivo', payload);
      if (data) {
        const arquivo = new Arquivo();
        arquivo.json = data;
        return arquivo;
      }

      throw new Error('Erro ao enviar arquivo');
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async removerArquivo(codigo) {
    try {
      await api.delete(`arquivo/${codigo}`);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  async criaArquivo(arquivo) {
    const arquivoBase64 = await this.converterArquivoParaBase64(arquivo);
    const [formato, base64] = arquivoBase64.split(',');
    try {
      const arquivoObjeto = new Arquivo();
      arquivoObjeto.arquivoEncoded = base64;
      arquivoObjeto.nome = arquivo.name;
      arquivoObjeto.descricao = arquivo.name;
      arquivoObjeto.tipo = this.converteTipoArquivo(arquivo.type);
      arquivoObjeto.origemArquivo = this.origemArquivo;
      arquivoObjeto.codigoDocumentoOrigemArquivo =
        this.codigoDocumentoOrigemArquivo;
      return arquivoObjeto.json;
    } catch {
      throw new Error('Erro ao criar arquivo para upload');
    }
  }

  downloadArquivo(arquivo) {
    let a = document.createElement('a');
    a.href = 'data:application/octet-stream;base64,' + arquivo.arquivoEncoded;
    a.download = arquivo.nome; //File name Here
    a.click(); //Downloaded file
  }

  lerArquivoAssincrono(arquivo) {
    return new Promise((resolve, reject) => {
      let reader = new FileReader();

      reader.onload = () => {
        resolve(reader.result);
      };

      reader.onerror = reject;

      reader.readAsDataURL(arquivo);
    });
  }

  async converterArquivoParaBase64(arquivo) {
    const buffer = await this.lerArquivoAssincrono(arquivo);
    return buffer;
  }

  converteTipoArquivo(type) {
    if (type === 'text/plain') return 'TEXTO';
    if (type.includes('image')) return 'IMAGEM';
    if (type.includes('video')) return 'VIDEO';
    if (type.includes('pdf')) return 'PDF';
    else return 'ARQUIVO';
  }
}

export { ArquivoController };

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { AtividadeProjeto } from './AtividadeProjeto';

import { HoraUtils } from '@/proxy-api/src/utils/HoraUtils';

class AtividadeRealizacao extends Cadastro {
  constructor(persistente) {
    super();

    this.atividadeProjeto = new AtividadeProjeto();
  }

  set json(dados) {
    this.codigo = dados.codigoPtr;

    this.atividadeProjeto.codigo = dados.tarefaPtr;
    this.atividadeProjeto.descricao = dados.descricaoPrt;
    this.atividadeProjeto.percentualRealizado = dados.percrealizadoPtr;
    this.atividadeProjeto.percentualAtual = dados.percrealizadoPtr;
    this.atividadeProjeto.realizacao = dados.realizacaoPtr;
    this.atividadeProjeto.duracao = HoraUtils.formatarNumeroParaHora(
      dados.duracaoPtr,
    );
    this.atividadeProjeto.observacao = dados.observacoesPtr;

    this.atividadeProjeto.projeto.codigo = dados.projetoPrt;
  }

  get json() {
    return {
      codigoPtr: this.codigo,
      tarefaPtr: this.atividadeProjeto.codigo,
      realizacaoPtr: this.atividadeProjeto.realizacao,
      percrealizadoPtr: this.atividadeProjeto.percentualAtual,

      duracaoPtr: HoraUtils.formatarHoraParaInteiro(
        this.atividadeProjeto.duracao,
      ),
      descricaoPrt: this.atividadeProjeto.descricao,
      observacoesPtr: this.atividadeProjeto.observacao,
    };
  }
}

export { AtividadeRealizacao };

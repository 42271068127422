<template>
  <area-de-trabalho
    @deslogar="deslogar"
    :menu="menu()"
    @menuClick="menuClick"
    :sessao="sessao"
    :updateComponent="updateComponent"
    ref="areaDeTrabalho"
  />
</template>

<script>
import { Storage } from '@/proxy-api/src/services/classes/Storage/Storage';
import { Sessao } from '@/proxy-api/src/services/classes/Autenticacao/Sessao';

import { NovoAtendimento } from '@/components/menu/menu-items/NovoAtendimento';
import { ConsultaAtendimento } from '@/components/menu/menu-items/ConsultaAtendimento';

import { NovoAtendimentoPresencial } from '@/components/menu/menu-items/NovoAtendimentoPresencial';
import { ConsultaAtendimentoPresencial } from '@/components/menu/menu-items/ConsultaAtendimentoPresencial';

import { NovaAtividadeProjeto } from '@/components/menu/menu-items/NovaAtividadeProjeto';
import { ConsultaAtividadeProjeto } from '@/components/menu/menu-items/ConsultaAtividadeProjeto';

import { NovaPessoa } from '@/components/menu/menu-items/NovaPessoa';
import { ConsultaContato } from '@/components/menu/menu-items/ConsultaContato';

import { ConsultaEvento } from '@/components/menu/menu-items/ConsultaEvento';
import { ConsultaProjeto } from '@/components/menu/menu-items/ConsultaProjeto';
import { ConsultaProposta } from '@/components/menu/menu-items/ConsultaProposta';
import { ConsultaServico } from '@/components/menu/menu-items/ConsultaServico';

import { ConsultaSolicitacao } from '@/components/menu/menu-items/ConsultaSolicitacao';

import { Produtos } from '@/components/menu/menu-items/Produtos';

import { MenuFuncionario } from './config/MenuFuncionario';
import { MenuCliente } from './config/MenuCliente';
import { MenuContato } from './config/MenuContato';

import AreaDeTrabalho from './components/AreaDeTrabalho/AreaDeTrabalho';
import TLoading from 'tek-components-vuejs/src/loading/TLoading';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';
import { api } from '../../proxy-api/src/services/api';
import { Processamento } from '../../proxy-api/src/services/classes/Processamento/Processamento';
import { ProcessamentoController } from '../../proxy-api/src/services/classes/Processamento/ProcessamentoController';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import ModalLiberacaoAcesso from '@/views/liberacaoAcesso/ModalLiberacaoAcesso.vue';
import { Operacao } from '../../proxy-api/src/utils/Operacao';

export default {
  components: { AreaDeTrabalho },

  data: function () {
    return {
      sessao: Sessao,
      processamentos: [],
      notificacoes: [],
      updateComponent: 0,
    };
  },

  mounted: async function () {
    this.validarCredenciais();
    if (this.sessao.id) {
      if (this.sessao.isAcessoFuncionario())
        await this.preencheMenuProcessamentos();
      await this.abrirOperacaoViaParametro();
    }
  },

  methods: {
    fecharMenu() {
      this.$refs.areaDeTrabalho.fecharMenu();
    },

    menu() {
      if (this.sessao.isAcessoFuncionario()) {
        return MenuFuncionario(this.processamentos);
      }
      if (this.sessao.isAcessoCliente()) {
        return MenuCliente();
      }
      return MenuContato();
    },

    async abrirOperacaoViaParametro() {
      if (this.$route.params.operacao) {
        const parametroOperacaoBase64 = this.$route.params.operacao;
        const parametroOperacao = JSON.parse(
          Buffer.from(parametroOperacaoBase64, 'base64'),
        );
        const operacao = new Operacao();
        operacao.abrir(parametroOperacao);
      }
    },

    deslogar() {
      this.sessao.limpar();
      this.$router.push({ name: 'Login' });
    },

    validarCredenciais() {
      const credenciais = Storage.getCredenciais();

      if (!credenciais) {
        if (this.$route.params.operacao) {
          this.$router.push({
            name: 'LoginOperacao',
            params: { operacao: this.$route.params.operacao },
          });
        } else {
          this.$router.push({ name: 'Login' });
        }
      }

      this.setSessao();
    },

    async preencheMenuProcessamentos() {
      const { data } = await api.get('/interpreter');
      this.processamentos = data.map(registro => {
        const processamento = new Processamento();
        processamento.json = registro;
        return processamento;
      });
    },

    setSessao() {
      this.sessao.json = Storage.getSessao();
    },

    antesCarregarForm() {
      TLoading.show('Carregando conteúdo ...');
    },

    aposCarregarForm() {
      TLoading.hide();
    },

    menuClick(menu) {
      switch (menu.id) {
        case 'novo_atendimento':
          this.novoAtendimento();
          break;

        case 'consulta_atendimento':
          this.consultaAtendimento();
          break;

        case 'novo_atendimento_presencial':
          this.novoAtendimentoPresencial();
          break;

        case 'consulta_atendimento_presencial':
          this.consultaAtendimentoPresencial();
          break;

        case 'consulta_solicitacao':
          this.consultaSolicitacao();
          break;

        case 'nova_atividade_projeto':
          this.novaAtividadeProjeto();
          break;

        case 'consulta_atividade_projeto':
          this.consultaAtividadeProjeto();
          break;

        case 'novo_contato':
          this.novaPessoa();
          break;

        case 'consulta_contato':
          this.consultaContato();
          break;

        case 'consulta_evento':
          this.consultaEvento();
          break;

        case 'consulta_projeto':
          this.consultaProjeto();
          break;
        case 'consulta_proposta':
          this.consultaProposta();
          break;

        case 'consulta_servico':
          this.consultaServico();
          break;

        case 'liberar_acesso':
          this.liberarAcesso();
          break;
        case 'processamento':
          this.rodarProcessamento(menu);
          break;
        case 'ponto_eletronico':
          this.pontoEletronico();
          break;
        case 'produtos':
          this.produtos();
          break;
      }
    },

    async novoAtendimento() {
      this.antesCarregarForm();
      await NovoAtendimento.abrir();
      this.aposCarregarForm();
    },

    async consultaAtendimento() {
      this.antesCarregarForm();
      await ConsultaAtendimento.abrir();
      this.aposCarregarForm();
    },

    async novoAtendimentoPresencial() {
      this.antesCarregarForm();
      await NovoAtendimentoPresencial.abrir();
      this.aposCarregarForm();
    },

    async consultaAtendimentoPresencial() {
      this.antesCarregarForm();
      await ConsultaAtendimentoPresencial.abrir();
      this.aposCarregarForm();
    },

    async novaAtividadeProjeto() {
      this.antesCarregarForm();
      await NovaAtividadeProjeto.abrir();
      this.aposCarregarForm();
    },

    async consultaAtividadeProjeto() {
      this.antesCarregarForm();
      await ConsultaAtividadeProjeto.abrir();
      this.aposCarregarForm();
    },

    async novaPessoa() {
      this.antesCarregarForm();
      await NovaPessoa.abrir();
      this.aposCarregarForm();
    },

    async consultaContato() {
      this.antesCarregarForm();
      await ConsultaContato.abrir();
      this.aposCarregarForm();
    },

    async consultaEvento() {
      this.antesCarregarForm();
      await ConsultaEvento.abrir();
      this.aposCarregarForm();
    },

    async consultaProjeto() {
      this.antesCarregarForm();
      await ConsultaProjeto.abrir();
      this.aposCarregarForm();
    },

    async consultaProposta() {
      this.antesCarregarForm();
      await ConsultaProposta.abrir();
      this.aposCarregarForm();
    },

    async consultaServico() {
      this.antesCarregarForm();
      await ConsultaServico.abrir();
      this.aposCarregarForm();
    },

    async consultaSolicitacao() {
      this.antesCarregarForm();
      await ConsultaSolicitacao.abrir();
      this.aposCarregarForm();
    },

    async liberarAcesso() {
      const instancia = TFormUtil.instanciar(ModalLiberacaoAcesso);

      TFormUtil.abrir(instancia);

      instancia.$on('atualizar', () => {
        this.atualizarAreaDeTrabalho();
      });

      instancia.$on('fechar', () => {
        TFormUtil.fechar(instancia);
      });
    },

    async rodarProcessamento(processamento) {
      this.fecharMenu();
      TLoading.show('Executando processamento...');
      const processamentoController = new ProcessamentoController();
      try {
        const { data } = await processamentoController.rodarProcessamento(
          processamento.nomeDaUnit,
        );
        this.atualizarAreaDeTrabalho();
        TMessageDialog.success()
          .title(`Processamento: ${processamento.text}`)
          .message('Executado corretamente')
          .show();
      } catch (error) {
        TMessageDialog.error().message(error.message).show();
      } finally {
        TLoading.hide();
      }
    },

    async produtos() {
      this.antesCarregarForm();
      await Produtos.abrir();
      this.aposCarregarForm();
    },

    pontoEletronico() {
      this.fecharMenu();
      this.rodarProcessamento({
        nomeDaUnit: 'FUNCOES_PONTO_ELETRONICO',
        text: 'Ponto Eletrônico',
      });
    },

    atualizarAreaDeTrabalho() {
      this.updateComponent += 1;
    },
  },
};
</script>

<template>
  <form-consulta :title="controller.titulo">
    <t-container
      v-if="quebras.length > 0"
      vertical-scroll
      height="100%"
      @scroll-end="$emit('scroll-end')"
    >
      <template v-for="(quebra, index) in quebras">
        <t-card
          :title="quebra.quebra"
          title-color="primary"
          :key="'card' + index"
        >
          <template v-for="(registro, indexReg) in quebra.registros">
            <v-list-item
              @click="select(registro)"
              class="py-2"
              :key="index + '-' + indexReg"
            >
              <v-list-item-icon v-if="controller.getIcon(registro) !== ''">
                <v-icon>{{ controller.getIcon(registro) }}</v-icon>
              </v-list-item-icon>

              <v-list-item-avatar v-if="controller.getAvatar(registro) !== ''">
                <v-img
                  :src="
                    'data:image/png;base64,' + controller.getAvatar(registro)
                  "
                ></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title
                  v-html="controller.getDescricao(registro)"
                ></v-list-item-title>
                <v-list-item-subtitle
                  v-html="controller.getDetalhe(registro)"
                ></v-list-item-subtitle>
              </v-list-item-content>

              <v-list-item-icon
                v-html="'<span>' + controller.getCodigo(registro) + '</span>'"
              >
              </v-list-item-icon>
            </v-list-item>

            <v-divider
              v-if="indexReg + 1 < quebra.registros.length"
              class="ma-0"
              :key="`divider-${quebra.quebra}-${indexReg}`"
            ></v-divider>
          </template>
        </t-card>
      </template>
    </t-container>
    <t-container v-else align-items="center" height="100%">
      <slot v-if="!processando" name="empty-state">
        <t-empty-state
          v-if="!processando"
          text="Nenhum registro encontrado"
          detail="Nenhum registro atendeu ao seu critério de pesquisa"
          icon="mdi-magnify"
          buttonLabel="REDEFINIR FILTRO"
          :buttonCallback="redefinir"
        />
      </slot>
    </t-container>
  </form-consulta>
</template>

<script>
import FormConsulta from '@/components/form/FormConsulta';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TEmptyState from 'tek-components-vuejs/src/empty-state/TEmptyState';

export default {
  components: {
    FormConsulta,
    TEmptyState,
    TContainer,
    TCard,
  },

  props: {
    controller: {
      default: null,
    },

    processando: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      ultimaQuebra: '',
    };
  },

  computed: {
    quebras() {
      const retorno = [];
      let quebra;

      for (const registro of this.controller.registros) {
        quebra = this.controller.getQuebra(registro);

        if (retorno.findIndex(res => res.quebra === quebra) === -1) {
          retorno.push({
            quebra: quebra,
            registros: [],
          });
        }

        retorno[retorno.length - 1].registros.push(registro);
      }

      return retorno;
    },
  },

  methods: {
    select(registro) {
      this.$emit('select', registro);
    },

    redefinir() {
      this.$emit('redefinir');
    },

    getKey(obj, index) {
      return `${obj.quebra}-${index}`;
    },
  },
};
</script>

<style scoped>
@media (max-width: 575px) {
  .v-list-item__title {
    white-space: pre-wrap;
  }
}
</style>

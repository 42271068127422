import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Status extends Cadastro {
  constructor(persistente) {
    super();
    this.descricao = '';
  }
}

export { Status };

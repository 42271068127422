import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Area extends Cadastro {
  constructor() {
    super();
    this.descricao = '';
  }

  get json() {
    return {
      areaAtd: this.codigo,
      descricaoSare: this.descricao,
    };
  }

  set json(dados) {
    this.codigo = dados.areaAtd | dados.codigoSare;
    this.descricao = dados.descricaoSare;
  }
}

export { Area };

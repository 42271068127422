<template>
  <v-menu rounded="b-xl">
    <template v-slot:activator="{ on, attrs }">
      <v-btn v-bind="attrs" v-on="on" icon plain>
        <v-icon>mdi-account-circle</v-icon>
      </v-btn>
    </template>
    <v-list class="pa-0">
      <v-list-item class="pa-0">
        <v-alert class="informacoes-do-usuario" color="accent" dark>
          <p class="nome-empresa text-caption font-weight-bold mb-n1">
            {{ sessao.usuario.descricao }}
          </p>
          <span class="nome-empresa text-caption">{{
            sessao.usuario.email
          }}</span>
        </v-alert>
      </v-list-item>

      <v-list-item
        @click="acao.callback()"
        v-for="(acao, indice) in acoes()"
        :key="indice"
        link
      >
        <v-list-item-icon>
          <v-icon :color="acao.corIcone">{{ acao.icone }}</v-icon>
        </v-list-item-icon>

        <v-list-item-content>
          <v-list-item-title>{{ acao.titulo }}</v-list-item-title>
        </v-list-item-content>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController.js';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';

import TLoading from 'tek-components-vuejs/src/loading/TLoading';
import Perfil from './Perfil';
import AlterarSenha from './AlterarSenha';
import RevogacaoEmail from '../../../../revogacaoEmail/RevogacaoEmail';

export default {
  components: { Perfil, AlterarSenha },

  props: {
    sessao: {
      type: Function,
    },
  },

  data: function () {
    return {
      autenticacaoController: new AutenticacaoController(),
    };
  },

  methods: {
    acoes() {
      return this.sessao.usuario.tipoPessoa === 'Cliente'
        ? [
            {
              titulo: 'Perfil',
              icone: 'mdi-face-man-outline',
              corIcone: 'success',
              callback: this.abrirPerfil,
            },
            {
              titulo: 'Alterar Senha',
              icone: 'mdi-key',
              corIcone: 'error',
              callback: this.abrirAlterarSenha,
            },
            {
              titulo: 'Sair',
              icone: 'mdi-close',
              corIcone: '',
              callback: this.deslogar,
            },
          ]
        : [
            {
              titulo: 'Perfil',
              icone: 'mdi-face-man-outline',
              corIcone: 'success',
              callback: this.abrirPerfil,
            },
            {
              titulo: 'Alterar Senha',
              icone: 'mdi-key',
              corIcone: 'error',
              callback: this.abrirAlterarSenha,
            },
            {
              titulo: 'Listas de Publicação',
              icone: 'mdi-email-arrow-right',
              corIcone: 'primary',
              callback: this.abrirListaPublicacao,
            },
            {
              titulo: 'Sair',
              icone: 'mdi-close',
              corIcone: '',
              callback: this.deslogar,
            },
          ];
    },

    async deslogar() {
      TLoading.show('Saindo...');
      try {
        await this.autenticacaoController.deslogar(this.sessao);
      } catch (error) {
      } finally {
        this.$emit('deslogar');
        TLoading.hide();
      }
    },

    abrirListaPublicacao() {
      const instancia = TFormUtil.instanciar(RevogacaoEmail);
      instancia.iconeVoltar = true;
      instancia.sessao = this.sessao;
      TFormUtil.abrir(instancia);
      instancia.$on('fechar', () => {
        TFormUtil.fechar(instancia);
      });
    },

    abrirPerfil() {
      const instancia = TFormUtil.instanciar(Perfil);

      instancia.usuario = this.sessao.usuario;

      TFormUtil.abrir(instancia);
    },

    abrirAlterarSenha() {
      const instancia = TFormUtil.instanciar(AlterarSenha);
      instancia.usuario = this.sessao.usuario;
      TFormUtil.abrir(instancia);
      instancia.$on('fechar', () => {
        TFormUtil.fechar(instancia);
      });
    },
  },
};
</script>

<style scoped>
.informacoes-do-usuario {
  width: 300px;
  padding: 1rem;
}

.nome-empresa {
  word-wrap: break-word;
}
</style>

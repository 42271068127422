<template>
  <t-container vertical-scroll height="100%">
    <t-card title="Identificação">
      <v-row v-if="model.codigo > 0" class="mb-2">
        <v-col cols="12" class="d-flex justify-end">
          <v-chip label :color="corStatus" class="mr-2">{{
            model.situacao.descricao
          }}</v-chip>

          <div v-if="!model.dataRealizacaoInicio" class="mr-2">
            <v-chip
              :color="corStatus"
              label
              outlined
              v-if="model.permiteInscricao && !model.inscrito"
              @click="$emit('inscrever')"
            >
              INSCREVER-SE
            </v-chip>
            <v-chip v-if="model.inscrito" label :color="corStatus" outlined>
              INSCRITO
            </v-chip>
          </div>

          <v-chip
            v-if="model.link && model.inscrito && !model.dataRealizacaoTermino"
            label
            color="success"
            @click="$emit('acessarEvento')"
          >
            <v-icon small class="mr-2">mdi-open-in-new</v-icon>
            ACESSAR EVENTO
          </v-chip>
        </v-col>
      </v-row>

      <search-edit
        :disabled="modoVisualizacao"
        :model="model.cliente"
        label="CLIENTE"
      />

      <t-edit
        v-model="model.descricao"
        :disabled="modoVisualizacao"
        label="Descrição"
      />

      <t-edit v-model="model.tipo" :disabled="modoVisualizacao" label="Tipo" />

      <t-edit
        v-model="model.atendente.descricao"
        :disabled="modoVisualizacao"
        label="Responsável pelo evento"
      />
    </t-card>

    <t-card title="Previsão">
      <v-row>
        <v-col cols="6">
          <t-edit
            v-model="model.dataPrevisaoInicio"
            :disabled="modoVisualizacao"
            label="Início"
          />
        </v-col>
        <v-col cols="6">
          <t-edit
            v-model="model.dataPrevisaoFim"
            :disabled="modoVisualizacao"
            label="Término"
          />
        </v-col>
      </v-row>
    </t-card>

    <t-card title="Realização">
      <v-row>
        <v-col cols="6">
          <t-edit
            v-model="model.dataRealizacaoInicio"
            :disabled="modoVisualizacao"
            label="Início"
          />
        </v-col>
        <v-col cols="6">
          <t-edit
            v-model="model.dataRealizacaoTermino"
            :disabled="modoVisualizacao"
            label="Termino"
          />
        </v-col>
      </v-row>
    </t-card>
  </t-container>
</template>

<script>
import FormContato from '@/views/contato/form/FormContato';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import SearchEdit from '@/components/search/SearchEdit';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';

export default {
  components: { TCard, TContainer, SearchEdit, TEdit },

  props: {
    model: {
      type: Object,
    },

    modoVisualizacao: {
      type: Boolean,
      default: true,
    },
  },

  computed: {
    corStatus() {
      switch (this.model.situacao.codigo) {
        case 2:
          return 'error';
        case 4:
          return 'success';
        case 5:
          return 'error';
        case 6:
          return 'primary';
        case 7:
          return 'success';
        default:
          return 'primary';
      }
    },
  },
};
</script>

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { ModuloSistema } from './ModuloSistema';

class Sistema extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';

    this.modulos = [];
  }

  setModulos(dados) {
    this.modulos = [];
    dados.map(registro => {
      const modulo = new ModuloSistema();
      modulo.json = registro;
      this.modulos.push(modulo);
    });
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.sistemaSsc;
    this.descricao = dados.nomeSis;
    this.ativo = dados.ativoSis === 'S' ? true : false;

    // Carrega os modulos do sistema
    if (dados?.sistemaModulo) {
      this.modulos = [];
      dados.sistemaModulo.map(registro => {
        const modulo = new ModuloSistema();
        modulo.json = registro;
        this.modulos.push(modulo);
      });
    }
  }
}

export { Sistema };

<template>
  <v-row align="center" justify="center" class="pa-7 ma-0" dense>
    <v-card class="elevation-12" width="25rem">
      <v-toolbar color="primary" dark flat>
        <v-toolbar-title>Atendimento Web</v-toolbar-title>
        <v-spacer></v-spacer>
        <p class="my-auto text-caption">v{{ version }}</p>
      </v-toolbar>
      <v-card-text>
        <t-edit
          v-model="autenticacao.usuario"
          @click-append="acessar"
          label="USUÁRIO"
          ref="usuario"
        />

        <v-text-field
          v-model="autenticacao.senha"
          label="SENHA"
          outlined
          required
          :append-icon="senhaVisivel ? 'mdi-eye-off' : 'mdi-eye'"
          :type="senhaVisivel ? 'text' : 'password'"
          @click:append="senhaVisivel = !senhaVisivel"
          @keypress.enter="acessar"
          persistent-placeholder=""
          hide-details
          class="mb-3"
          ref="senha"
        />
        <div class="d-flex justify-end">
          <v-checkbox
            v-model="autenticacao.permanecerConectado"
            label="Permanecer conectado"
            class="ma-0 pa-0 mb-1"
          />
        </div>

        <v-row>
          <v-col>
            <v-btn
              @click="abrirRecuperarAcesso"
              color="primary"
              outlined
              block
              min-height="56"
              >Esqueci a senha</v-btn
            >
          </v-col>
          <v-col>
            <v-btn
              class="px-12"
              block
              color="primary"
              min-height="56"
              @click="acessar"
              >Acessar</v-btn
            >
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-row>
</template>

<script>
import { Autenticacao } from '@/proxy-api/src/services/classes/Autenticacao/Autenticacao';
import { AutenticacaoController } from '@/proxy-api/src/services/classes/Autenticacao/AutenticacaoController';
import { TFormUtil } from '@/proxy-api/src/utils/TFormUtil';
import { version } from '../../../package.json';
import { Storage } from '../../proxy-api/src/services/classes/Storage/Storage';

import RecuperarAcesso from './components/RecuperarAcesso';
import TEdit from 'tek-components-vuejs/src/edit/TEdit';
import TLoading from 'tek-components-vuejs/src/loading/TLoading';
import TMessageDialog from 'tek-components-vuejs/src/dialog/TMessageDialog';

export default {
  components: { TEdit },

  data: function () {
    return {
      version: version,
      autenticacao: new Autenticacao(),
      autenticacaoController: new AutenticacaoController(),
      senhaVisivel: false,
      permanecerConectado: false,
    };
  },

  methods: {
    antesAcessar() {
      TLoading.show('Carregando conteúdo ...');
    },

    aposAcessar() {
      TLoading.hide();
    },

    getUltimoUsuario() {
      const ultimoUsuario = Storage.getUltimoUsuario();
      this.autenticacao.usuario = ultimoUsuario ? ultimoUsuario : '';
    },

    async acessar() {
      this.antesAcessar();

      try {
        await this.autenticacaoController.acessar(this.autenticacao);
        Storage.setUltimoUsuario(this.autenticacao.usuario);
        this.direcionarParaPrincipal();
      } catch (error) {
        let mensagem = '';
        if (error.message.search('Your user name') > -1)
          mensagem = 'Usuário ou senha inválidos';
        else mensagem = error.message;
        TMessageDialog.error().message(`${mensagem}`).show();
      } finally {
        this.aposAcessar();
      }
    },

    abrirRecuperarAcesso() {
      const instancia = TFormUtil.instanciar(RecuperarAcesso);

      instancia.controller = this.autenticacaoController;
      TFormUtil.abrir(instancia);
    },

    direcionarParaPrincipal() {
      if (this.$route.params.operacao)
        this.$router.push(`/principal/${this.$route.params.operacao}`);
      else this.$router.push('/principal');
    },

    setFocus() {
      if (this.autenticacao.usuario) this.$refs.senha.focus();
      else this.$refs.usuario.focus();
    },
  },
  mounted: async function () {
    const sessao = await this.autenticacaoController.verificaSessao();
    this.getUltimoUsuario();
    this.setFocus();
    if (sessao) {
      this.direcionarParaPrincipal();
    }
  },
};
</script>

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

class ConsultaClassificacao extends ConsultaController {
  constructor() {
    super('classificacao', 'Classificações', 'codigoClassificacao');

    this.ordenacao = 'codigoClassificacao';
    this.sentidoDaOrdenacao = 'DESC';

    this.addFiltro('codigoClassificacao', 'Código', TipoFiltro.Inteiro);
    this.addFiltro(
      'descricaoClassificacao',
      'Descrição',
      TipoFiltro.String,
      true,
    );
    this.addFiltroFixo('disponivelclienteClassificacao', 'IGUAL', 'S');
    this.addFiltroFixo('ativoClassificacao', 'IGUAL', 'S');
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoClassificacao;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.descricaoClassificacao;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {}
}

export { ConsultaClassificacao };

import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { DataUtils } from '@/proxy-api/src/utils/DataUtils';

class Relacionamento extends Cadastro {
  constructor() {
    super();
  }

  set json(dados) {
    this.tipo = dados.tipo || '-';
    this.codigo = Number(dados.codigo) || '-';
    this.descricao = dados.descricao || '-';
    this.data1 = DataUtils.formatarDataParaDiaMesAno(dados.data1) || '-';
    this.data2 = DataUtils.formatarDataParaDiaMesAno(dados.data2) || '-';
    this.observacao = dados.obs || '-';
    this.envolvidos = dados.envolvidos || '-';
    this.tempo = String(dados.tempo).substring(11, 19);
  }

  get json() {}
}

export { Relacionamento };

import { ConsultaController } from '@/proxy-api/src/services/bases/Consulta/ConsultaController';
import TipoFiltro from '@/proxy-api/src/enumerations/TipoFiltro';

export class ConsultaOrigemAtendimento extends ConsultaController {
  constructor() {
    super('origem_atendimento', 'Origem do Atendimento', 'codigoOtd');

    this.ordenacao = 'descricaoOtd';

    this.addFiltroFixo('ativoOtd', 'IGUAL', 'S');
    this.addFiltro('codigoOtd', 'Código', TipoFiltro.Inteiro);
    this.addFiltro('descricaoOtd', 'Descrição', TipoFiltro.String, true);
  }

  // --------------------------------------
  // getCodigo
  // --------------------------------------
  getCodigo(registro) {
    return registro.codigoOtd;
  }

  // --------------------------------------
  // getDescricao
  // --------------------------------------
  getDescricao(registro) {
    return registro.descricaoOtd;
  }

  // --------------------------------------
  // getQuebra
  // --------------------------------------
  getQuebra(registro) {
    return '<h1>' + registro.descricaoOtd.substr(0, 1) + '</h1>';
  }
}

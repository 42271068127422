import { Controller } from '../../bases/Controllers/Controller';
import { api } from '../../api';

class OperacoesAbertasController extends Controller {
  constructor() {
    super('');
  }

  async carregarPendencias(rota) {
    try {
      const { data } = await api.get(`${rota}/operacoes_abertas`);
      return {
        pendencias: data,
        status: [
          {
            sigla: 'ATI',
            descricao: 'ATENDIMENTOS',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'ATI'),
          },
          {
            sigla: 'ATE',
            descricao: 'SOLICITAÇÕES',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'ATE'),
          },
          {
            sigla: 'EVE',
            descricao: 'EVENTOS',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'EVE'),
          },
          {
            sigla: 'PRO',
            descricao: 'PROJETOS',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'PRO'),
          },
          {
            sigla: 'PRP',
            descricao: 'PROPOSTAS',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'PRP'),
          },
          {
            sigla: 'SER',
            descricao: 'SERVIÇOS',
            quantidade: this.filtrarQuantidadePorPendencia(data, 'SER'),
          },
        ],
      };
    } catch (error) {
      throw new Error(error.message);
    }
  }

  filtrarPendencia(pendencias, tipoPendencia) {
    return pendencias.filter(pendencia => pendencia.tipo === tipoPendencia);
  }

  filtrarQuantidadePorPendencia(pendencias, tipoPendencia) {
    const pendenciasFiltradas = pendencias.filter(
      pendencia => pendencia.tipo === tipoPendencia,
    );
    return pendenciasFiltradas.length;
  }
}

export { OperacoesAbertasController };

import { Cadastro } from '../../bases/Cadastros/Cadastro';

class Motivo extends Cadastro {
  constructor(persistente) {
    super();

    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() { }

  set json(dados) {
    this.codigo = dados.codigoPro;
    this.descricao = dados.name ? dados.name : dados.nomePro;
    this.ativo = dados.ativoPro === 'S' ? true : false;
  }
}

export { Motivo };

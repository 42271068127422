import { api } from '../../api';

class Controller {
  constructor(rota) {
    this.rota = rota;
  }

  // --------------------------------------
  // Gravar
  // --------------------------------------
  async gravar(dados) {
    await this.validar(dados);

    if (dados.codigo === 0) return this.incluir(dados);
    return this.alterar(dados);
  }

  // --------------------------------------
  // Incluir
  // --------------------------------------
  async incluir(dados) {
    try {
      return await api.post(this.rota, { ...dados.json });
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // --------------------------------------
  // Alterar
  // --------------------------------------
  async buscar(Codigo) {
    try {
      const { data } = await api.get(`${this.rota}`, {
        headers: { Codigo },
      });
      return data
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // --------------------------------------
  // Alterar
  // --------------------------------------
  async alterar(dados) {
    try {
      return await api.put(`${this.rota}/${dados.codigo}`, dados.json);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // --------------------------------------
  // Excluir
  // --------------------------------------
  async excluir(codigo) {
    try {
      return await api.delete(`${this.rota}/${codigo}`);
    } catch (error) {
      throw new Error(error.message);
    }
  }

  // --------------------------------------
  // Valida os campos a serem enviados
  // --------------------------------------
  async validar(dados) {}

  // --------------------------------------
  // Buscar permissões
  // --------------------------------------
  async buscarPermissoes() {
    try {
      const { data } = await api.get(`${this.rota}/permissions`);

      return {
        data,
      };
    } catch (error) {
      throw new Error(error.message);
    }
  }
}

export { Controller };

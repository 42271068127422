<template>
  <v-app id="inspire">
    <v-navigation-drawer v-model="drawer" app clipped width="330">
      <t-colapsable-menu v-model="menu" @menuClick="menuClick" />
    </v-navigation-drawer>

    <v-app-bar app color="primary" clipped-left dark>
      <v-app-bar-nav-icon @click.stop="drawer = !drawer"></v-app-bar-nav-icon>
      <v-toolbar-title id="title-aplication"
        >Sistema de Atendimento</v-toolbar-title
      >

      <v-spacer></v-spacer>

      <situacao-atendente
        v-if="sessao.isAcessoFuncionario() && !$vuetify.breakpoint.xs"
        :updateComponent="updateComponent"
      />
      <notificacoes :sessao="sessao" v-if="sessao.isAcessoFuncionario()" />
      <configuracoes-do-usuario @deslogar="deslogar" :sessao="sessao" />
    </v-app-bar>

    <v-main class="area-principal" style="background: #eeeeee">
      <dashboard :model="modelOperacoesAbertas" />
    </v-main>
  </v-app>
</template>

<script>
import { OperacoesAbertas } from '@/proxy-api/src/services/classes/OperacoesAbertas/OperacoesAbertas';
import { OperacoesAbertasController } from '@/proxy-api/src/services/classes/OperacoesAbertas/OperacoesAbertasController';

import ConfiguracoesDoUsuario from './components/ConfiguracoesDoUsuario.vue';
import Dashboard from './components/Dashboard/Dashboard.vue';
import Notificacoes from './components/Notificacoes.vue';
import SituacaoAtendente from './components/SituacaoAtendente.vue';
import TLoading from 'tek-components-vuejs/src/loading/TLoading';
import ExibirMensagem from '../../../../components/ExibirMensagem';
import TColapsableMenu from 'tek-components-vuejs/src/menu/TColapsableMenu.vue';

export default {
  components: {
    ConfiguracoesDoUsuario,
    Dashboard,
    Notificacoes,
    SituacaoAtendente,
    TColapsableMenu,
    TColapsableMenu,
  },

  props: {
    menu: {
      type: Array,
      default: () => [],
    },

    sessao: {
      type: Function,
    },

    updateComponent: {
      type: Number,
    },
  },

  data: () => {
    return {
      drawer: null,
      left: false,
      modelOperacoesAbertas: new OperacoesAbertas(),
    };
  },

  methods: {
    fecharMenu() {
      this.drawer = false;
    },

    menuClick(menu) {
      this.$emit('menuClick', menu);
    },

    deslogar() {
      this.$emit('deslogar');
    },

    async carregarPendencias() {
      TLoading.show('Carregando pendências...');
      try {
        const operacoesAbertasController = new OperacoesAbertasController();
        if (this.sessao.codigoTipoPessoa() === 1)
          this.modelOperacoesAbertas.json =
            await operacoesAbertasController.carregarPendencias('atendente');
        else
          this.modelOperacoesAbertas.json =
            await operacoesAbertasController.carregarPendencias('clientes');
      } catch (erro) {
        ExibirMensagem.erroGenerico(erro);
      } finally {
        TLoading.hide();
      }
    },
  },

  async mounted() {
    if (this.sessao.id) await this.carregarPendencias();
  },
};
</script>

<template>
  <form-cadastro
    :title="`Proposta ${model.codigo ? model.codigo : ''}`"
    :controller="controller"
    :model="model"
    ref="form"
    extended
    @click-navigation="$emit('click-navigation')"
  >
    <template v-slot:extension>
      <v-tabs
        v-model="abaSelecionada"
        :align-with-title="$vuetify.breakpoint.mdAndUp"
        :fixed-tabs="$vuetify.breakpoint.smAndDown"
        background-color="transparent"
      >
        <v-tab> Identificação </v-tab>
        <v-tab> Relacionamentos </v-tab>
        <v-tab> Escopo </v-tab>
        <v-tab v-if="model.codigo !== 0"> Arquivos Anexados </v-tab>
      </v-tabs>
    </template>

    <v-tabs-items v-model="abaSelecionada">
      <v-tab-item>
        <aba-identificacao
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-relacionamentos
          :model="model"
          :controller="relacionamentoController"
        />
      </v-tab-item>

      <v-tab-item>
        <aba-escopo :modo-visualizacao="modoVisualizacao" :model="model" />
      </v-tab-item>

      <v-tab-item>
        <aba-arquivos-anexados
          :modo-visualizacao="modoVisualizacao"
          :model="model"
        />
      </v-tab-item>
    </v-tabs-items>
  </form-cadastro>
</template>

<script>
import { Proposta } from '@/proxy-api/src/services/classes/Proposta/Proposta';
import { PropostaController } from '@/proxy-api/src/services/classes/Proposta/PropostaController';

import FormCadastro from '@/components/form/FormCadastro';
import SearchEdit from '@/components/search/SearchEdit';
import TCard from 'tek-components-vuejs/src/card/TCard';
import TContainer from 'tek-components-vuejs/src/layout/TContainer';
import TIntegerEdit from 'tek-components-vuejs/src/edit/TIntegerEdit';
import AbaIdentificacao from './components/AbaIdentificacao';
import AbaRelacionamentos from '../../../components/AbaRelacionamentos.vue';
import AbaEscopo from './components/AbaEscopo.vue';
import AbaArquivosAnexados from '../../../components/AbaArquivosAnexados/AbaArquivosAnexados.vue';
import { RelacionamentoController } from '../../../proxy-api/src/services/classes/Relacionamentos/RelacionamentoController';

export default {
  components: {
    FormCadastro,
    SearchEdit,
    TCard,
    TContainer,
    TIntegerEdit,
    AbaIdentificacao,
    AbaEscopo,
    AbaArquivosAnexados,
    AbaRelacionamentos,
  },

  mounted() {
    this.removerAcoesDoUsuario();
  },

  data: function () {
    return {
      modoVisualizacao: true,
      model: new Proposta(),
      controller: new PropostaController(),
      relacionamentoController: new RelacionamentoController('proposta'),
      abaSelecionada: 0,
    };
  },

  methods: {
    removerAcoesDoUsuario() {
      this.$refs.form.actionItems = [];
    },
  },
};
</script>

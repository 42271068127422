class SessaoController {
  static get authorization() {
    const sessao = window.sessionStorage.getItem('vue-session-key') || '';
    const token = JSON.parse(sessao);
    return token.token;
  }

  static get idToken() {
    const sessao = window.sessionStorage.getItem('vue-session-key') || '';
    const { id } = JSON.parse(sessao);

    return id;
  }
}

export { SessaoController }

<template>
  <div>
    <div v-if="$vuetify.breakpoint.smAndUp">
      <v-card
        :hover="selecionado"
        :flat="!selecionado"
        width="210"
        :color="cor"
        class="mx-2 mb-4"
        @click="$emit('selecionado')"
      >
        <v-col>
          <v-row align="center" justify="center" class="mt-2">
            <v-icon class="white--text mr-2">{{ icone }}</v-icon>
            <span class="white--text">{{ capitalize(descricao) }}</span>
          </v-row>
          <v-row
            align="center"
            justify="center"
            class="white--text text-h5 mb-1"
          >
            {{ quantidade }}
          </v-row>
        </v-col>
      </v-card>
    </div>
    <div v-else>
      <v-chip
        :hover="selecionado"
        :flat="!selecionado"
        :color="cor"
        class="mx-1 mb-3 pa-4"
        @click="$emit('selecionado')"
      >
        <span class="white--text"
          >{{ capitalize(descricao) }}: {{ quantidade }}</span
        >
      </v-chip>
    </div>
  </div>
</template>

<script>
export default {
  props: {
    icone: {
      type: String,
      required: true,
    },
    descricao: {
      type: String,
      required: true,
    },
    quantidade: {
      type: Number,
      required: true,
    },
    cor: {
      type: String,
      required: true,
    },
    selecionado: {
      type: Boolean,
      required: true,
    },
  },

  methods: {
    capitalize(texto) {
      return texto[0].toUpperCase() + texto.substring(1).toLowerCase();
    },
  },
};
</script>

<style></style>

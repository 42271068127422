import { Cadastro } from '../../bases/Cadastros/Cadastro';
import { DataUtils } from '../../../utils/DataUtils';
import { siglaParaDescricaoAtendimento } from '../../../utils/siglaAtendimento';

class Notificacao extends Cadastro {
  constructor(persistente) {
    super();
    this.tipo = '';
    this.dataHora = '';
    this.descricao = persistente ? '' : 'INDEFINIDO';
  }

  get json() {}

  set json(dados) {
    this.codigo = dados.autoincNtf;
    this.descricao = dados.descricaoNtf;
    this.dataHora = DataUtils.formatarDataParaDiaMesAnoHoraMinuto(
      dados.dthrinclusaoNtf,
    );
    this.tipo = siglaParaDescricaoAtendimento(dados.refTipoNtf);
  }
}

export { Notificacao };
